import PageHeader from "../../shared/header/PageHeader";

import {
  PlusOutlined,
  BuildOutlined,
  EditOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  DesktopOutlined,
  SyncOutlined,
  CheckCircleOutlined,
  ReloadOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

import { Button, Select, Space, Table, Tabs, Tag } from "antd";

import { useEffect, useState } from "react";

import styles from "../management/Buildings.module.css";
import BuildingDataTab from "./components/BuildingData";
import Floorplan from "./components/Floorplan";
import PresentationData from "./components/PresentationData";
import { ROUTES } from "../../../../config/constants";
import BuildingsService from "../../../../services/buildings/BuildingsService";
import BuildingTypesService from "../../../../services/buildingtypes/BuildingTypesService";
import { floatButtonPrefixCls } from "antd/es/float-button/FloatButton";
import ScanResults from "./components/ScanResults";
import { IUpdateBuildingRequest } from "../../../../models/buildings/UpdateBuildingRequest";
import { useNavigate, useParams } from "react-router-dom";
import ScansService from "../../../../services/scans/ScansService";
import { IScanResponse } from "../../../../models/scans/ScanResponse";
import { sortByDateTimeProperty } from "../../../../utils/sort";

const Buildings = () => {
  const [showAddNewForm, setShowAddNewForm] = useState(false);
  const [buildings, setBuildings] = useState<IUpdateBuildingRequest[]>([]);
  const [buildingScans, setBuildingScans] = useState<IScanResponse[]>([]);
  const [buildingTypes, setBuildingTypes] = useState<any>([]);
  const [editItem, setEditItem] = useState<any>(null);
  const [refreshTriggered, setRefreshTriggered] = useState<any>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [building, setBuilding] = useState<IUpdateBuildingRequest>();
  const [loadingButton, setLoadingButton] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const route = {
    path: ROUTES.general.managementBuildings.route,
    section: "Scan",
    name: ROUTES.general.managementBuildings.name,
  };

  useEffect(() => {
    resetItem();
  }, []);

  const resetItem = () => {
    const model: IUpdateBuildingRequest = {
      name: "",
      address: "",
      productivityRate: 0,
      countryId: 1,
      buildingTypeId: 0,
      currencyId: 1,
      measurementSystemId: 1,
      overheadTemplate: {
        id: 0,
        tenantId: 1,
        name: "",
        cleaningMaterialsPercentage: 0,
        holidayBonusPercentage: 0,
        insurancePercentage: 0,
        profitsPercentage: 0,
        employerNIPercentage: 0,
        hourlyPercentage: 0,
        managementPercentage: 0,
        totalPercentage: 0,
        equipmentPercentage: 0,
        hourlyWagePercentage: 0,
        pensionPercentage: 0,
      },
      standardCleaningTemplate: {
        id: 0,
        tenantId: 1,
        name: "",
        setupTimeSeconds: 0,
        finishTimeSeconds: 0,
        walkingSpeedPerSecond: 0,
      },
      eveningCleaningTemplate: {
        id: 0,
        tenantId: 1,
        name: "",
        totalTimeSeconds: 0,
        secondsPerCleaner: 0,
        hourlyWage: 0,
        cleanersPerSupervisor: 0,
        auditPassRatePercentage: 0,
        secondsPerSupervisor: 0,
        supervisorHourlyWage: 0,
      },
      periodicCleaningTemplate: {
        id: 0,
        tenantId: 1,
        name: "",
        profitMarginPercentage: 0,
        hourlyWage: 0,
      },
      roboticVacuumingTemplate: {
        id: 0,
        tenantId: 1,
        name: "",
      },
      executiveSummaryTemplate: {
        id: 0,
        tenantId: 1,
        name: "",
        executiveSummaryTemplateItems: [
          {
            id: 0,
            tenantId: 1,
            title: "",
            text: "",
            order: 0,
            executiveSummaryTemplateId: 0,
          },
        ],
      },
      daytimeJanitorTasks: [
        {
          name: "",
          buildingId: 1,
        },
      ],
    };

    setBuilding({ ...model });
  };

  useEffect(() => {
    setIsLoading(true);
    BuildingTypesService.getAll().then((typesResponse: any) => {
      setBuildingTypes(typesResponse.data);

      BuildingsService.getAll().then((buildingsResponse: any) => {
        // console.log(buildingsResponse.data);
        // const responseData = buildingsResponse.data;
        // var buildingsWithSortedScans: IBuildingResponse[] = responseData.map(({scans, ...rest}: {scans: IScanResponse[]})=>{scans = scans.sort((a: IScanResponse,b: IScanResponse)=> Date.parse(b.createTime ?? '') - Date.parse(a.createTime ?? '')); return {scans, ...rest}});
        // var buildingsSortedByLatestScan = buildingsWithSortedScans.sort((a,b)=> Date.parse(b.scans ? b.scans[0].createTime ?? '': '') - Date.parse(a.scans ? a.scans[0]?.createTime ?? '' : ''));

        setBuildings(
          buildingsResponse.data
            .sort(function (
              buildingA: IUpdateBuildingRequest,
              buildingB: IUpdateBuildingRequest
            ) {
              return sortByDateTimeProperty(
                buildingB.createTime,
                buildingA.createTime
              );
            })
            .map((building: IUpdateBuildingRequest) => {
              return {
                ...building,
                floorCount: building.floors?.length,
                type: typesResponse.data.find(
                  (y: any) => y.id === building.buildingTypeId
                ).name,
                scan: building.name,
                scanStatus: building.scans
                  ? building.scans[0]?.status
                  : "Not scanned",
                createTime: building.createTime?.split(".")[0],
              };
            })
        );
        setIsLoading(false);
        // ScansService.getAll().then((scansResponse: any) => {
        //   setData(
        //     scansResponse.data
        //       .sort(function (scanA: any, scanB: any) {
        //         const scannedBuildingA = scanA.buildings
        //           ? scanA.buildings[0]
        //           : null;
        //         const scannedBuildingB = scanB.buildings
        //           ? scanB.buildings[0]
        //           : null;

        //         return scannedBuildingB.id - scannedBuildingA.id;
        //       })
        //       .map((scan: any) => {
        //         const scannedBuilding = scan.buildings ? scan.buildings[0] : null; // TODO - handle multiple buildings
        //         return {
        //           ...scannedBuilding,
        //           eveningCleaningTemplate: {
        //             ...scannedBuilding.eveningCleaningTemplate,
        //             secondsPerCleaner:
        //               scannedBuilding.eveningCleaningTemplate.secondsPerCleaner /
        //               3600,
        //             secondsPerSupervisor:
        //               scannedBuilding.eveningCleaningTemplate
        //                 .secondsPerSupervisor / 3600,
        //           },
        //           floorCount: scannedBuilding.floors.length,
        //           type: typesResponse.data.find(
        //             (y: any) => y.id === scannedBuilding.buildingTypeId
        //           ).name,
        //           scan: scan.name,
        //           scanStatus: scan.status,
        //           createTime: scannedBuilding.createTime.split(".")[0],
        //         };
        //       })
        //   );
        //   setIsLoading(false);
        // });
      });
    });
  }, [refreshTriggered]);

  useEffect(() => {
    if (buildings && buildings.length > 0 && id) {
      const building = buildings.find((x: any) => x.id === parseInt(id));
      if (building) {
        setShowAddNewForm(true);
        setBuilding(building);
      }
    } else {
      setShowAddNewForm(false);
    }
  }, [buildings, id]);

  const onSaveBuilding = () => {
    setLoadingButton(true);

    if (building?.id) {
      BuildingsService.update({
        ...building,
        eveningCleaningTemplate: {
          ...building.eveningCleaningTemplate,
          secondsPerSupervisor:
            building.eveningCleaningTemplate?.secondsPerSupervisor ?? 0 * 3600,
          secondsPerCleaner:
            building.eveningCleaningTemplate?.secondsPerCleaner ?? 0 * 3600,
        },
      })
        .then((response: any) => {
          // setShowAddNewForm(false);
          setRefreshTriggered(!refreshTriggered);
          setBuilding(building);
          // resetItem();
          setLoadingButton(false);
        })
        .catch((error: any) => {
          // TODO: DISPLAY NOTIFICATION
          setLoadingButton(false);
          console.log(error);
        });
    }
  };

  // const handleAddNewBuilding = () => {
  //   setShowAddNewForm(true);
  // };

  const onDoubleClickRow = (record: any) => {
    setBuilding(record);
    navigate(`${ROUTES.general.managementBuildings.route}/${record.id}`);
  };

  const handleCancelAdd = () => {
    resetItem();
    setShowAddNewForm(false);
    setLoadingButton(false);
    navigate(ROUTES.general.managementBuildings.route);
  };

  const handleEditBuilding = (record: any) => {
    setBuilding(record);
    setShowAddNewForm(true);
    navigate(`${ROUTES.general.managementBuildings.route}/${record.id}`);
  };

  const fetchBuildings = (record: any) => {
    setRefreshTriggered(!refreshTriggered);
  };

  // const headerButton = (
  //   <Button
  //     type="primary"
  //     icon={<PlusOutlined />}
  //     onClick={handleAddNewBuilding}
  //   >
  //     Add
  //   </Button>
  // );

  const refreshButton = (
    <Button type="primary" icon={<ReloadOutlined />} onClick={fetchBuildings}>
      Refresh
    </Button>
  );

  const actionsButtons = (
    <Space size="middle">
      <Select
        options={[
          { value: "GBP", label: "GBP" },
          { value: "euro", label: "€" },
        ]}
        defaultValue="GBP"
      />
      <Select
        options={[{ value: "squaremeter", label: "m2" }]}
        defaultValue="squaremeter"
      />
      <Button
        type="default"
        href={ROUTES.benchmark.welcome.getRoute(building?.id ?? 0)}
        target="_blank"
        // onClick={handleAddNewRegion}
      >
        Presentation
      </Button>
      <Button
        type="default"
        // onClick={handleAddNewRegion}
      >
        Audit
      </Button>
      <Button
        type="primary"
        danger
        icon={<CloseOutlined />}
        onClick={handleCancelAdd}
      >
        Cancel
      </Button>
      <Button
        type="primary"
        icon={<CheckOutlined />}
        onClick={onSaveBuilding}
        loading={loadingButton}
      >
        Save
      </Button>
    </Space>
  );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      elipsis: true,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      elipsis: true,
    },
    // {
    //   title: "Scan",
    //   dataIndex: "scan",
    //   key: "scan",
    //   elipsis: true,
    // },
    {
      title: "Scan status",
      dataIndex: "scanStatus",
      key: "scanStatus",
      elipsis: true,
      render: (text: any, record: any) => (
        <div>
          {text === "Success" ||
            (text === "Completed" && (
              <Tag icon={<CheckCircleOutlined />} color="success">
                completed
              </Tag>
            ))}
          {text === "Processing" && (
            <Tag icon={<SyncOutlined spin />} color="processing">
              processing
            </Tag>
          )}
          {text === "NotStarted" && (
            <Tag
              icon={<ClockCircleOutlined />}
              color="waiting"
              style={{ background: "rgba(0, 0, 0, 0.40)" }}
            >
              in queue
            </Tag>
          )}
          {text === "Failed" && (
            <Tag icon={<CloseCircleOutlined />} color="error">
              error
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: "Floors",
      dataIndex: "floorCount",
      key: "floorCount",
      elipsis: true,
    },
    {
      title: "Date",
      dataIndex: "createTime",
      key: "createTime",
      elipsis: true,
    },
    {
      title: "Action",
      key: "action",
      width: "80px",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Button
            type="text"
            icon={<DesktopOutlined />}
            href={ROUTES.benchmark.welcome.getRoute(record.id)}
            target="_blank"
            style={{ color: "#313AB8" }}
          />
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => handleEditBuilding(record)}
            style={{ color: "#313AB8" }}
          />
          <Button
            type="text"
            icon={<DeleteOutlined />}
            style={{ color: "#313AB8" }}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      {/* <PageHeader
        title={"Buidings"}
        icon={<BuildOutlined />}
        mainAction={showAddNewForm ? actionsButtons : [refreshButton]}
        setMainActionDisabled={false}
        additionalBreadcrumbs={
          !showAddNewForm && !item?.id ? null : [item?.name ?? ""]
        }
        route={route}
      /> */}
      <div className={styles.bodyContent}>
        {!showAddNewForm ? (
          <Table
            loading={isLoading}
            size="middle"
            dataSource={buildings}
            columns={columns}
            rowKey="id"
            onRow={(record, rowIndex) => {
              return {
                onDoubleClick: (event) => {
                  setShowAddNewForm(true);
                  onDoubleClickRow(record);
                },
              };
            }}
          />
        ) : (
          <Tabs
            tabPosition="left"
            defaultActiveKey="1"
            // onChange={onChange}
            className={styles.tabs}
            items={[
              {
                label: `Building Data`,
                key: "1",
                children: (
                  <BuildingDataTab item={building} setItem={setBuilding} />
                ),
              },
              {
                label: `Scan Results`,
                key: "2",
                children: <ScanResults item={building} setItem={setBuilding} />,
              },
              {
                label: `Floorplan`,
                key: "3",
                children: <Floorplan building={building} />,
              },
              {
                label: `Presentation Data`,
                key: "4",
                children: (
                  <PresentationData item={building} setItem={setBuilding} />
                ),
              },
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default Buildings;
