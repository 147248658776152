import styles from "../SidebarLayout.module.scss";
import "../SidebarLayout.css";

import { Divider, Menu, MenuProps } from "antd";
import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ROUTES } from "../../../../../config/constants";
import {
  ImportOutlined,
  RobotOutlined,
  TableOutlined,
  ScheduleOutlined,
  ClearOutlined,
  CloudOutlined,
  FileTextOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { actions, getBenchmark } from "../../../../../store/benchmark";

type MenuItem = Required<MenuProps>["items"][number];

function getMenuItem(routeValues: any, buildingId?: number | string): MenuItem {
  return getItem(
    <div>
      <Link to={routeValues.getRoute(buildingId)}>
        {getIcon(routeValues.name)}
        <span>{routeValues.name}</span>
      </Link>
    </div>,
    routeValues.route,
    null
  );
}

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

function getIcon(routeName: string) {
  switch (routeName) {
    // case ROUTES.benchmark.homepage.name:
    //     return <HomeOutlined />
    case ROUTES.benchmark.welcome.name:
      return <ImportOutlined />;
    case ROUTES.benchmark.executiveSummary.name:
      return <FileTextOutlined />;
    // case ROUTES.benchmark.scanningBenefits.name:
    //     return <ScanOutlined />
    case ROUTES.benchmark.benchmarkResults.name:
      return <UnorderedListOutlined />;
    case ROUTES.benchmark.eveningCleaning.name:
      return <CloudOutlined />;
    case ROUTES.benchmark.daytimeJanitor.name:
      return <ClearOutlined />;
    case ROUTES.benchmark.operationalManagement.name:
      return <ClearOutlined />;
    case ROUTES.benchmark.periodicCleaning.name:
      return <ScheduleOutlined />;
    case ROUTES.benchmark.roboticVacuuming.name:
      return <RobotOutlined />;
    case ROUTES.benchmark.floorMapping.name:
      return <TableOutlined />;
    default:
      break;
  }
}

const SidebarMenu = ({ sidebarCollapsed }: { sidebarCollapsed: boolean }) => {
  const [dispatched, setDispatched] = useState<boolean>(false);

  let location = useLocation();
  const [current, setCurrent] = useState(
    location.pathname.split("/").pop() ?? ""
  );
  let { buildingId } = useParams();
  const [currentOpenKey, setCurrentOpenKey] = useState<any>([]);

  const dispatch = useAppDispatch();
  const { benchmark, pending } = useAppSelector((state) => state.benchmark);
  const [screens, setScreens] = useState<string[]>([]);
  const [generalItems, setGeneralItems] = useState<MenuItem[]>([]);
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(actions.resetBenchmark());

    return () => {
      dispatch(actions.resetBenchmark());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!dispatched && buildingId && !benchmark && searchParams) {
      setDispatched(true);
      dispatch(
        getBenchmark({
          buildingId: buildingId,
          isDemo: searchParams.get("demo"),
        })
      );
    }
  }, [dispatch, dispatched, buildingId, benchmark, searchParams]);

  useEffect(() => {
    if (dispatched && benchmark && buildingId) {
      let screenNames = ["Floor Mapping"];
      if (benchmark.welcome) screenNames.push("Welcome");
      if (benchmark.executiveSummary && buildingId !== "251") screenNames.push("Executive Summary");

      benchmark.screens?.map((screen) => {
        if (buildingId !== "251") {
          screenNames.push(screen.title ?? "-");
          return; 
        }
        if (screen.title !== "Daily Cleaning") {
          screenNames.push(screen.title ?? "-");
          return; 
        }
      });
      const genItms: MenuItem[] = Object.entries(ROUTES.benchmark)
        .filter((routeObj) => screenNames.includes(routeObj[1].name))
        .map(([routeKey, routeValues]) => {
          return getMenuItem(routeValues, buildingId);
        });
      setGeneralItems(genItms);
    }
  }, [dispatched, benchmark, buildingId]);

  useEffect(() => {
    if (location) {
      if (current !== location.pathname.split("/").pop() ?? "") {
        setCurrent(location.pathname.split("/").pop() ?? "" + location.search);
      }
    }
  }, [location, current]);

  function handleClick(e: any) {
    setCurrent(e.key);
  }

  const rootSubmenuKeys: any = [];

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    const latestOpenKey = keys.find(
      (key) => currentOpenKey.indexOf(key) === -1
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setCurrentOpenKey(keys);
    } else {
      setCurrentOpenKey(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return generalItems && generalItems.length > 0 ? (
    <div id="benchmark-sidebar" className={styles.sidebarMenuWrap}>
      {sidebarCollapsed ? (
        <div className={styles.sectionTitle}>
          <Divider
            style={{
              color: "var(--font-purple)",
              borderTop: "1px solid var(--font-purple",
              margin: "0",
            }}
          />
        </div>
      ) : (
        <div className={styles.sectionTitle}>
          <span>SLIDES</span>
        </div>
      )}
      <Menu
        defaultSelectedKeys={[""]}
        defaultOpenKeys={[""]}
        onClick={handleClick}
        selectedKeys={[current]}
        openKeys={currentOpenKey}
        onOpenChange={onOpenChange}
        mode="inline"
        theme="dark"
        items={generalItems}
        className={styles.sidebarMenu}
      />
    </div>
  ) : (
    <></>
  );
};

export default SidebarMenu;
