import styles from "./RoomMapping.module.scss";
import "./local-antd-override.scss";
import "../../../../styles/leaflet-override.scss";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import FloorPlanBackground from "../../../../img/demo-floor-mapping/floorplan-v2.png";
import { ReactComponent as WalkingSvgIcon } from "../../../../img/icons/walking.svg";
import {
  MapContainer,
  ImageOverlay,
  Tooltip,
  Marker,
  useMapEvents,
  ZoomControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Geoman from "./components/Geoman";
import { CRS, latLngBounds } from "leaflet";
import classNames from "classnames";

import {
  DownOutlined,
  UnorderedListOutlined,
  DeleteOutlined,
  BorderHorizontalOutlined,
  CloseOutlined,
  EditOutlined,
  PlusOutlined,
  MinusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Drawer,
  DrawerProps,
  Input,
  Modal,
  Popconfirm,
  Select,
  Space,
  Switch,
  Table,
} from "antd";
import Icon from "@ant-design/icons";
import { HexColorPicker } from "react-colorful";
import {
  calculateActiveFeatureTooltipPosition,
  randomHexColor,
} from "../../../../utils/miscellaneous";
import L from "leaflet";

import TitlePlaceholder from "../modal/TitlePlaceholder";
import {
  IAsset,
  IAssetCleaningTask,
  IFloor,
  IRoom,
  IRoomAsset,
  IRoomAssetCleaningTask,
  IRoomAssetCleaningTaskExtended,
} from "../../../../models/floorplan/Floorplan";
import { ISelectListItem } from "../../../../models/benchmark/shared/SelectListItem";
import {
  sortByNumberProperty,
  sortByRoomsAndDepartment,
} from "../../../../utils/sort";
import RoomsService from "../../../../services/rooms/RoomsService";
import { UpdateRoomRequest } from "../../../../models/rooms/UpdateRoomRequest";
import {
  revertTransformGeometry,
  transformGeometry,
} from "../../../../utils/geometry";

interface IConfig {
  editable?: boolean | null;
  minified?: boolean | null;
  minZoom?: number | null;
  enableVirtualWalk?: boolean | null;
  floorSelect?: boolean | null;
}

type ImageSize = {
  width: number;
  height: number;
};

interface ILightningTask {
  key?: number | null;
  typeFloorFitting?: string | null,
  description?: string | null,
  qty?: number | null,
  powerWatts?: number | null,
  runHoursPerWeek?: number | null,
  costYear1?: number | null;
  costYear2?: number | null;
  costYear3?: number | null;
  costYear4?: number | null;
  costYear5?: number | null;
  total5Years?: number | null;
  co2PerYearTonnes?: number | null;
  [key: string]: string | number | boolean | null | undefined; // This is an override to allow for dynamic properties
}

interface IManualCleaningTask {
  key?: number | null,
  audit?: boolean | null,
  asset?: string | null,
  description?: string | null,
  basic?: number | null,
  optimal?: number | null,
  premium?: number | null,
  qty?: number | null,
  totalFloorSpace?: number | null,
  floorSurfaceType: string | null,
  [key: string]: string | number | boolean | null | undefined; // This is an override to allow for dynamic properties
}

export const RoomMapping = ({
  buildingId,
  floors,
  rooms,
  assets,
  assetCleaningTasks,
  selectedFloorId,
  selectedFloor,
  floorplanImgUrl,
  matterportLink,
  config = {
    editable: false,
    minified: false,
    minZoom: 1,
    enableVirtualWalk: false,
    floorSelect: false,
  },
  handleActiveFloorChange,
}: {
  buildingId?: number | string | null;
  floors?: IFloor[] | null;
  rooms?: IRoom[] | null;
  assets?: IAsset[] | null;
  assetCleaningTasks?: IAssetCleaningTask[] | null;
  selectedFloorId?: number | null;
  selectedFloor?: IFloor | null;
  floorplanImgUrl?: string | null;
  matterportLink?: string | null;
  config?: IConfig;
  handleActiveFloorChange: (floorId: number) => void;
}): JSX.Element => {
  const [floorsData, setFloorsData] = useState<IFloor[]>();
  const [activeFloor, setActiveFloor] = useState<IFloor>();
  const [roomsData, setRoomsData] = useState<IRoom[]>();
  useState<IAssetCleaningTask[]>();
  const [containerBounds, setContainerBounds] = useState<any>(null);
  const [bounds, setBounds] = useState<any>(null);
  const [showDepartmentsOnly, setShowDepartmentsOnly] = useState<boolean>(
    (buildingId == 73 || buildingId == 217) ? true : false
  );

  const roomsDataRef = useRef(roomsData);
  roomsDataRef.current = roomsData;

  const [frRatings, setFrRatings] = useState<any[]>([
    {
      id: "FR1",
      name: "FR1",
    },
    {
      id: "FR2",
      name: "FR2",
    },
    {
      id: "FR3",
      name: "FR3",
    },
    {
      id: "FR4",
      name: "FR4",
    },
    {
      id: "FR5",
      name: "FR5",
    },
  ]);

  const [frRatingsValues, setFrRatingsValues] = useState<any[]>([
    {
      id: "FR1",
      name: "2.2",
    },
    {
      id: "FR2",
      name: "1.6",
    },
    {
      id: "FR3",
      name: "1.0",
    },
    {
      id: "FR4",
      name: "0.6",
    },
    {
      id: "FR5",
      name: "0.6",
    },
  ]);

  const [assetTaskData, setAssetTaskData] = useState<any[]>([
    {
      id: "1",
      name: "Wiping",
    },
    {
      id: "2",
      name: "Mopping",
    },
    {
      id: "3",
      name: "Vacuuming",
    },
  ]);
  const [roomTypeData, setRoomTypeData] = useState<ISelectListItem[]>();
  const [assetSizeData, setAssetSizeData] = useState<ISelectListItem[]>();
  const [assetMaterialData, setAssetMaterialData] =
    useState<ISelectListItem[]>();
  const [openDropdowns, setOpenDropdowns] = useState<string[]>([]);

  const [roomListOpen, setRoomListOpen] = useState(false);
  const [oZonesOpen] = useState(false);
  const [placement] = useState<DrawerProps["placement"]>("left");

  const [roomTooltipActive, setRoomTooltipActive] = useState<boolean>(false);
  const [assetCleaningTasksModalOpen, setAssetCleaningTasksModalOpen] =
    useState(false);

  const [isWalkModalOpen, setIsWalkModalOpen] = useState(false);

  const [virtualWalkModalOpen, setVirtualWalkModalOpen] = useState(false);

  const [isEditRoomModalOpen, setIsEditRoomModalOpen] = useState(false);
  const [activeRoomData, setActiveRoomData] = useState<IRoom | null>({
    id: null,
    name: "",
    color: "",
    roomType: "",
  });

  const [lightningType, setLightningType] = useState<any>("")

  const convertFRRating = (value: string) => {
    if (value == "0.6") {
      return "FR4";
    }
    if (value == "1" || value == "1.0") {
      return "FR3";
    }
    if (value == "1.6") {
      return "FR2";
    }
    if (value == "2.2") {
      
    }
  }

  const NHSRoomAssetCleaningTaskColumns = [
    // {
    //   title: "ServiceAction",
    //   dataIndex: "serviceAction",
    //   key: "serviceAction",
    //   width: "3%",
    //   render: (text: any, record: any, index: number) => {
    //     return record.hasOwnProperty("serviceAction") ? (
    //       <div>
    //         <Checkbox
    //           checked={record.serviceAction}
    //           onChange={(e: any) =>
    //             onCheckboxChange("serviceAction", index, record, e)
    //           }
    //         />
    //       </div>
    //     ) : (
    //       <></>
    //     );
    //   },
    // },
    // {
    //   title: "Room Type",
    //   dataIndex: "roomTypeId",
    //   key: "roomTypeId",
    //   width: "9%",
    //   render: (text: any, record: any, index: number) => {
    //     return record.hasOwnProperty("roomTypeId") ? (
    //       <Select
    //         allowClear
    //         open={openDropdowns.includes(`${index}-roomTypeId`)}
    //         onClick={() =>
    //           openDropdowns.includes(`${index}-roomTypeId`)
    //             ? setOpenDropdowns(
    //                 openDropdowns.filter((x) => x !== `${index}-roomTypeId`)
    //               )
    //             : setOpenDropdowns([`${index}-roomTypeId`])
    //         }
    //         onBlur={() =>
    //           setOpenDropdowns(
    //             openDropdowns.filter((x) => x !== `${index}-roomTypeId`)
    //           )
    //         }
    //         style={{ width: "100%", boxShadow: "none" }}
    //         value={
    //           roomTypeData?.find(
    //             (roomType) => roomType?.id === record.roomTypeId
    //           )?.name
    //         }
    //         options={dynamicDropdownFiltering(
    //           "roomTypeId",
    //           record,
    //           assetCleaningTasks
    //         )?.filter(
    //           (option, index, self) =>
    //             self.findIndex((t) => t.value === option.value) === index
    //         )}
    //         placement="bottomLeft"
    //         onChange={(e: any) => {
    //           handleSelectChange("roomTypeId", index, record, e);
    //         }}
    //       >
    //         <Button style={{ width: "100%" }}>
    //           <Space
    //             style={{
    //               width: "100%",
    //               justifyContent: "space-between",
    //               alignItems: "initial",
    //             }}
    //           >
    //             <span>
    //               {
    //                 roomTypeData?.find(
    //                   (roomType) => roomType?.id === record.roomTypeId
    //                 )?.name
    //               }
    //             </span>
    //             <DownOutlined />
    //           </Space>
    //         </Button>
    //       </Select>
    //     ) : (
    //       <></>
    //     );
    //   },
    // },
    {
      title: "Task",
      dataIndex: "cleaningTaskId",
      key: "cleaningTaskId",
      width: "36%",
      render: (text: any, record: any, index: number) => {
        return record.hasOwnProperty("cleaningTaskId") ? (
          <Select
            allowClear
            open={openDropdowns.includes(`${index}-cleaningTaskId`)}
            onClick={() =>
              openDropdowns.includes(`${index}-cleaningTaskId`)
                ? setOpenDropdowns(
                    openDropdowns.filter((x) => x !== `${index}-cleaningTaskId`)
                  )
                : setOpenDropdowns([`${index}-cleaningTaskId`])
            }
            onBlur={() =>
              setOpenDropdowns(
                openDropdowns.filter((x) => x !== `${index}-cleaningTaskId`)
              )
            }
            style={{ width: "100%", boxShadow: "none" }}
            value={
              assetTaskData?.find((task) => task?.id === record.cleaningTaskId)
                ?.name
            }
            options={dynamicDropdownFiltering(
              "cleaningTaskId",
              record,
              assetCleaningTasks
            )?.filter(
              (option, index, self) =>
                self.findIndex((t) => t.value === option.value) === index
            )}
            placement="bottomLeft"
            onChange={(e: any) =>
              handleSelectChange("cleaningTaskId", index, record, e)
            }
          >
            <Button style={{ width: "100%" }}>
              <Space
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "initial",
                }}
              >
                <span>
                  {
                    assetTaskData?.find(
                      (task) => task?.id === record.cleaningTaskId
                    )?.name
                  }
                </span>
                <DownOutlined />
              </Space>
            </Button>
          </Select>
        ) : (
          <></>
        );
      },
    },
    // {
    //   title: "Size",
    //   dataIndex: "assetSize",
    //   key: "assetSize",
    //   width: "9%",
    //   render: (text: any, record: any, index: number) => {
    //     return record.hasOwnProperty("assetSize") ? (
    //       <Select
    //         allowClear
    //         open={openDropdowns.includes(`${index}-assetSize`)}
    //         onClick={() =>
    //           openDropdowns.includes(`${index}-assetSize`)
    //             ? setOpenDropdowns(
    //                 openDropdowns.filter((x) => x !== `${index}-assetSize`)
    //               )
    //             : setOpenDropdowns([`${index}-assetSize`])
    //         }
    //         onBlur={() =>
    //           setOpenDropdowns(
    //             openDropdowns.filter((x) => x !== `${index}-assetSize`)
    //           )
    //         }
    //         style={{ width: "100%", boxShadow: "none" }}
    //         value={
    //           assetSizeData?.find((size) => size?.id === record.assetSize)?.name
    //         }
    //         options={dynamicDropdownFiltering(
    //           "assetSize",
    //           record,
    //           assetCleaningTasks
    //         )?.filter(
    //           (option, index, self) =>
    //             self.findIndex((t) => t.value === option.value) === index
    //         )}
    //         placement="bottomLeft"
    //         onChange={(e: any) =>
    //           handleSelectChange("assetSize", index, record, e)
    //         }
    //       >
    //         <Button style={{ width: "100%" }}>
    //           <Space
    //             style={{
    //               width: "100%",
    //               justifyContent: "space-between",
    //               alignItems: "initial",
    //             }}
    //           >
    //             <span>
    //               {
    //                 assetSizeData?.find((size) => size?.id === record.assetSize)
    //                   ?.name
    //               }
    //             </span>
    //             <DownOutlined />
    //           </Space>
    //         </Button>
    //       </Select>
    //     ) : (
    //       <></>
    //     );
    //   },
    // },
    // {
    //   title: "Material",
    //   dataIndex: "assetMaterial",
    //   key: "assetMaterial",
    //   width: "9%",
    //   render: (text: any, record: any, index: number) => {
    //     return record.hasOwnProperty("assetMaterial") ? (
    //       <Select
    //         allowClear
    //         open={openDropdowns.includes(`${index}-assetMaterial`)}
    //         onClick={() =>
    //           openDropdowns.includes(`${index}-assetMaterial`)
    //             ? setOpenDropdowns(
    //                 openDropdowns.filter((x) => x !== `${index}-assetMaterial`)
    //               )
    //             : setOpenDropdowns([`${index}-assetMaterial`])
    //         }
    //         onBlur={() =>
    //           setOpenDropdowns(
    //             openDropdowns.filter((x) => x !== `${index}-assetMaterial`)
    //           )
    //         }
    //         style={{ width: "100%", boxShadow: "none" }}
    //         value={
    //           assetMaterialData?.find(
    //             (material) => material?.id === record.assetMaterial
    //           )?.name
    //         }
    //         options={dynamicDropdownFiltering(
    //           "assetMaterial",
    //           record,
    //           assetCleaningTasks
    //         )?.filter(
    //           (option, index, self) =>
    //             self.findIndex((t) => t.value === option.value) === index
    //         )}
    //         placement="bottomLeft"
    //         onChange={(e: any) => {
    //           handleSelectChange("assetMaterial", index, record, e);
    //         }}
    //       >
    //         <Button style={{ width: "100%" }}>
    //           <Space
    //             style={{
    //               width: "100%",
    //               justifyContent: "space-between",
    //               alignItems: "initial",
    //             }}
    //           >
    //             <span>
    //               {
    //                 assetMaterialData?.find(
    //                   (material) => material?.id === record.assetMaterial
    //                 )?.name
    //               }
    //             </span>
    //             <DownOutlined />
    //           </Space>
    //         </Button>
    //       </Select>
    //     ) : (
    //       <></>
    //     );
    //   },
    // },
    // {
    //   title: "Periodic",
    //   dataIndex: "isPeriodic",
    //   key: "isPeriodic",
    //   width: "3%",
    //   render: (text: any, record: any, index: number) =>
    //     record.hasOwnProperty("isPeriodic") ? (
    //       <Space
    //         style={{ width: "100%", justifyContent: "center" }}
    //         size="small"
    //       >
    //         <Checkbox
    //           checked={record.isPeriodic}
    //           onChange={(e: any) =>
    //             onCheckboxChange("isPeriodic", index, record, e)
    //           }
    //         />
    //       </Space>
    //     ) : (
    //       <></>
    //     ),
    // },
    {
      title: "NHS",
      dataIndex: "nhs",
      key: "nhs",
      width: "15%",
      render: (text: any, record: any, index: number) => (
        <Input
          // disabled
          value={findFrequencyValue(assetCleaningTasks ?? [], record, "nhs")}
          onChange={(e: any) => onInputChange("nhs", index, record, e)}
        />
      ),
    },
    {
      title: "2007",
      dataIndex: "2007",
      key: "2007",
      width: "15%",
      render: (text: any, record: any, index: number) => (
        <Input
          // disabled
          value={findFrequencyValue(assetCleaningTasks ?? [], record, "2007")}
          onChange={(e: any) => onInputChange("2007", index, record, e)}
        />
      ),
    },
    {
      title: "2021",
      dataIndex: "2021",
      key: "2021",
      width: "15%",
      render: (text: any, record: any, index: number) => (
        <Input
          // disabled
          value={findFrequencyValue(assetCleaningTasks ?? [], record, "2021")}
          onChange={(e: any) => onInputChange("2021", index, record, e)}
        />
      ),
    },
    // {
    //   title: "Custom",
    //   dataIndex: "custom",
    //   key: "custom",
    //   width: "9%",
    //   render: (text: any, record: any, index: number) =>
    //     record.hasOwnProperty("custom") ? (
    //       <Input
    //         value={text}
    //         onChange={(e: any) => onInputChange("custom", index, record, e)}
    //       />
    //     ) : (
    //       <></>
    //     ),
    // },
    {
      title: "Actions",
      key: "actions",
      width: "15%",
      render: (text: any, record: any, index: number) => (
        <Space
          align="center"
          style={{ justifyContent: "space-between", width: "100%" }}
        >
          <Space
            size="small"
            style={{ width: "100%", justifyContent: "flex-end", gap: "16px" }}
          >
            qty:{" "}
            {calculateAssetCleaningTaskQuantity(assetCleaningTasksData, record)}
          </Space>
          <Space
            size="small"
            style={{ width: "100%", justifyContent: "flex-end", gap: "16px" }}
          >
            <Button
              type="primary"
              size="small"
              onClick={() => handleChangeQuantity(record, true)}
            >
              <PlusOutlined />
            </Button>
            {record.quantity === 1 ? (
              <Popconfirm
                title="Are you sure you want to delete?"
                onConfirm={() => handleChangeQuantity(record, false)}
              >
                <Button size="small">
                  <MinusOutlined />
                </Button>
              </Popconfirm>
            ) : (
              <Button
                size="small"
                onClick={() => handleChangeQuantity(record, false)}
              >
                <MinusOutlined />
              </Button>
            )}
          </Space>
        </Space>
      ),
    },
  ];

  const AIGNHSRoomAssetCleaningTaskColumns = [
    // {
    //   title: "ServiceAction",
    //   dataIndex: "serviceAction",
    //   key: "serviceAction",
    //   width: "3%",
    //   render: (text: any, record: any, index: number) => {
    //     return record.hasOwnProperty("serviceAction") ? (
    //       <div>
    //         <Checkbox
    //           checked={record.serviceAction}
    //           onChange={(e: any) =>
    //             onCheckboxChange("serviceAction", index, record, e)
    //           }
    //         />
    //       </div>
    //     ) : (
    //       <></>
    //     );
    //   },
    // },
    // {
    //   title: "Room Type",
    //   dataIndex: "roomTypeId",
    //   key: "roomTypeId",
    //   width: "9%",
    //   render: (text: any, record: any, index: number) => {
    //     return record.hasOwnProperty("roomTypeId") ? (
    //       <Select
    //         allowClear
    //         open={openDropdowns.includes(`${index}-roomTypeId`)}
    //         onClick={() =>
    //           openDropdowns.includes(`${index}-roomTypeId`)
    //             ? setOpenDropdowns(
    //                 openDropdowns.filter((x) => x !== `${index}-roomTypeId`)
    //               )
    //             : setOpenDropdowns([`${index}-roomTypeId`])
    //         }
    //         onBlur={() =>
    //           setOpenDropdowns(
    //             openDropdowns.filter((x) => x !== `${index}-roomTypeId`)
    //           )
    //         }
    //         style={{ width: "100%", boxShadow: "none" }}
    //         value={
    //           roomTypeData?.find(
    //             (roomType) => roomType?.id === record.roomTypeId
    //           )?.name
    //         }
    //         options={dynamicDropdownFiltering(
    //           "roomTypeId",
    //           record,
    //           assetCleaningTasks
    //         )?.filter(
    //           (option, index, self) =>
    //             self.findIndex((t) => t.value === option.value) === index
    //         )}
    //         placement="bottomLeft"
    //         onChange={(e: any) => {
    //           handleSelectChange("roomTypeId", index, record, e);
    //         }}
    //       >
    //         <Button style={{ width: "100%" }}>
    //           <Space
    //             style={{
    //               width: "100%",
    //               justifyContent: "space-between",
    //               alignItems: "initial",
    //             }}
    //           >
    //             <span>
    //               {
    //                 roomTypeData?.find(
    //                   (roomType) => roomType?.id === record.roomTypeId
    //                 )?.name
    //               }
    //             </span>
    //             <DownOutlined />
    //           </Space>
    //         </Button>
    //       </Select>
    //     ) : (
    //       <></>
    //     );
    //   },
    // },
    {
      title: "Audit",
      dataIndex: "auditCheck",
      key: "auditCheck",
      width: "4%",
      render: (text: any, record: any, index: number) => {
        return (
            <Checkbox></Checkbox>
        )
      },
    },
    {
      title: "Task",
      dataIndex: "cleaningTaskId",
      key: "cleaningTaskId",
      width: "36%",
      render: (text: any, record: any, index: number) => {
        return record.hasOwnProperty("cleaningTaskId") ? (
            <Select
                allowClear
                open={openDropdowns.includes(`${index}-cleaningTaskId`)}
                onClick={() =>
                    openDropdowns.includes(`${index}-cleaningTaskId`)
                        ? setOpenDropdowns(
                            openDropdowns.filter((x) => x !== `${index}-cleaningTaskId`)
                        )
                        : setOpenDropdowns([`${index}-cleaningTaskId`])
                }
                onBlur={() =>
                    setOpenDropdowns(
                        openDropdowns.filter((x) => x !== `${index}-cleaningTaskId`)
                    )
                }
                style={{ width: "100%", boxShadow: "none" }}
                value={
                  assetTaskData?.find((task) => task?.id === record.cleaningTaskId)
                      ?.name
                }
                options={dynamicDropdownFiltering(
                    "cleaningTaskId",
                    record,
                    assetCleaningTasks
                )?.filter(
                    (option, index, self) =>
                        self.findIndex((t) => t.value === option.value) === index
                )}
                placement="bottomLeft"
                onChange={(e: any) =>
                    handleSelectChange("cleaningTaskId", index, record, e)
                }
            >
              <Button style={{ width: "100%" }}>
                <Space
                    style={{
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "initial",
                    }}
                >
                <span>
                  {
                    assetTaskData?.find(
                        (task) => task?.id === record.cleaningTaskId
                    )?.name
                  }
                </span>
                  <DownOutlined />
                </Space>
              </Button>
            </Select>
        ) : (
            <></>
        );
      },
    },
    // {
    //   title: "Size",
    //   dataIndex: "assetSize",
    //   key: "assetSize",
    //   width: "9%",
    //   render: (text: any, record: any, index: number) => {
    //     return record.hasOwnProperty("assetSize") ? (
    //       <Select
    //         allowClear
    //         open={openDropdowns.includes(`${index}-assetSize`)}
    //         onClick={() =>
    //           openDropdowns.includes(`${index}-assetSize`)
    //             ? setOpenDropdowns(
    //                 openDropdowns.filter((x) => x !== `${index}-assetSize`)
    //               )
    //             : setOpenDropdowns([`${index}-assetSize`])
    //         }
    //         onBlur={() =>
    //           setOpenDropdowns(
    //             openDropdowns.filter((x) => x !== `${index}-assetSize`)
    //           )
    //         }
    //         style={{ width: "100%", boxShadow: "none" }}
    //         value={
    //           assetSizeData?.find((size) => size?.id === record.assetSize)?.name
    //         }
    //         options={dynamicDropdownFiltering(
    //           "assetSize",
    //           record,
    //           assetCleaningTasks
    //         )?.filter(
    //           (option, index, self) =>
    //             self.findIndex((t) => t.value === option.value) === index
    //         )}
    //         placement="bottomLeft"
    //         onChange={(e: any) =>
    //           handleSelectChange("assetSize", index, record, e)
    //         }
    //       >
    //         <Button style={{ width: "100%" }}>
    //           <Space
    //             style={{
    //               width: "100%",
    //               justifyContent: "space-between",
    //               alignItems: "initial",
    //             }}
    //           >
    //             <span>
    //               {
    //                 assetSizeData?.find((size) => size?.id === record.assetSize)
    //                   ?.name
    //               }
    //             </span>
    //             <DownOutlined />
    //           </Space>
    //         </Button>
    //       </Select>
    //     ) : (
    //       <></>
    //     );
    //   },
    // },
    // {
    //   title: "Material",
    //   dataIndex: "assetMaterial",
    //   key: "assetMaterial",
    //   width: "9%",
    //   render: (text: any, record: any, index: number) => {
    //     return record.hasOwnProperty("assetMaterial") ? (
    //       <Select
    //         allowClear
    //         open={openDropdowns.includes(`${index}-assetMaterial`)}
    //         onClick={() =>
    //           openDropdowns.includes(`${index}-assetMaterial`)
    //             ? setOpenDropdowns(
    //                 openDropdowns.filter((x) => x !== `${index}-assetMaterial`)
    //               )
    //             : setOpenDropdowns([`${index}-assetMaterial`])
    //         }
    //         onBlur={() =>
    //           setOpenDropdowns(
    //             openDropdowns.filter((x) => x !== `${index}-assetMaterial`)
    //           )
    //         }
    //         style={{ width: "100%", boxShadow: "none" }}
    //         value={
    //           assetMaterialData?.find(
    //             (material) => material?.id === record.assetMaterial
    //           )?.name
    //         }
    //         options={dynamicDropdownFiltering(
    //           "assetMaterial",
    //           record,
    //           assetCleaningTasks
    //         )?.filter(
    //           (option, index, self) =>
    //             self.findIndex((t) => t.value === option.value) === index
    //         )}
    //         placement="bottomLeft"
    //         onChange={(e: any) => {
    //           handleSelectChange("assetMaterial", index, record, e);
    //         }}
    //       >
    //         <Button style={{ width: "100%" }}>
    //           <Space
    //             style={{
    //               width: "100%",
    //               justifyContent: "space-between",
    //               alignItems: "initial",
    //             }}
    //           >
    //             <span>
    //               {
    //                 assetMaterialData?.find(
    //                   (material) => material?.id === record.assetMaterial
    //                 )?.name
    //               }
    //             </span>
    //             <DownOutlined />
    //           </Space>
    //         </Button>
    //       </Select>
    //     ) : (
    //       <></>
    //     );
    //   },
    // },
    // {
    //   title: "Periodic",
    //   dataIndex: "isPeriodic",
    //   key: "isPeriodic",
    //   width: "3%",
    //   render: (text: any, record: any, index: number) =>
    //     record.hasOwnProperty("isPeriodic") ? (
    //       <Space
    //         style={{ width: "100%", justifyContent: "center" }}
    //         size="small"
    //       >
    //         <Checkbox
    //           checked={record.isPeriodic}
    //           onChange={(e: any) =>
    //             onCheckboxChange("isPeriodic", index, record, e)
    //           }
    //         />
    //       </Space>
    //     ) : (
    //       <></>
    //     ),
    // },
    {
      title: "NHS",
      dataIndex: "nhs",
      key: "nhs",
      width: "15%",
      render: (text: any, record: any, index: number) => (
          <Input
              // disabled
              value={findFrequencyValue(assetCleaningTasks ?? [], record, "nhs")}
              onChange={(e: any) => onInputChange("nhs", index, record, e)}
          />
      ),
    },
    {
      title: "2007",
      dataIndex: "2007",
      key: "2007",
      width: "15%",
      render: (text: any, record: any, index: number) => (
          <Input
              // disabled
              value={findFrequencyValue(assetCleaningTasks ?? [], record, "2007")}
              onChange={(e: any) => onInputChange("2007", index, record, e)}
          />
      ),
    },
    {
      title: "2021",
      dataIndex: "2021",
      key: "2021",
      width: "15%",
      render: (text: any, record: any, index: number) => (
          <Input
              // disabled
              value={findFrequencyValue(assetCleaningTasks ?? [], record, "2021")}
              onChange={(e: any) => onInputChange("2021", index, record, e)}
          />
      ),
    },
    // {
    //   title: "Custom",
    //   dataIndex: "custom",
    //   key: "custom",
    //   width: "9%",
    //   render: (text: any, record: any, index: number) =>
    //     record.hasOwnProperty("custom") ? (
    //       <Input
    //         value={text}
    //         onChange={(e: any) => onInputChange("custom", index, record, e)}
    //       />
    //     ) : (
    //       <></>
    //     ),
    // },
    {
      title: "Actions",
      key: "actions",
      width: "15%",
      render: (text: any, record: any, index: number) => (
          <Space
              align="center"
              style={{ justifyContent: "space-between", width: "100%" }}
          >
            <Space
                size="small"
                style={{ width: "100%", justifyContent: "flex-end", gap: "16px" }}
            >
              qty:{" "}
              {calculateAssetCleaningTaskQuantity(assetCleaningTasksData, record)}
            </Space>
            <Space
                size="small"
                style={{ width: "100%", justifyContent: "flex-end", gap: "16px" }}
            >
              <Button
                  type="primary"
                  size="small"
                  onClick={() => handleChangeQuantity(record, true)}
              >
                <PlusOutlined />
              </Button>
              {record.quantity === 1 ? (
                  <Popconfirm
                      title="Are you sure you want to delete?"
                      onConfirm={() => handleChangeQuantity(record, false)}
                  >
                    <Button size="small">
                      <MinusOutlined />
                    </Button>
                  </Popconfirm>
              ) : (
                  <Button
                      size="small"
                      onClick={() => handleChangeQuantity(record, false)}
                  >
                    <MinusOutlined />
                  </Button>
              )}
            </Space>
          </Space>
      ),
    },
  ];

  const DewsburyRoomAssetCleaningTaskColumns = [
    {
      title: "Audit",
      dataIndex: "auditCheck",
      key: "auditCheck",
      width: "4%",
      visible: buildingId == 102 ? true : false,
      render: (text: any, record: any, index: number) => {
        return (
            <Checkbox></Checkbox>
        )
      },

    },
    {
      title: "Asset",
      dataIndex: "assetId",
      key: "assetId",
      width: "12%",
      visible: true,
      render: (text: any, record: any, index: number) => {
        return record.hasOwnProperty("assetId") ? (
            <Select
                allowClear
                open={openDropdowns.includes(`${index}-assetId`)}
                onClick={() =>
                    openDropdowns.includes(`${index}-assetId`)
                        ? setOpenDropdowns(
                            openDropdowns.filter((x) => x !== `${index}-assetId`)
                        )
                        : setOpenDropdowns([`${index}-assetId`])
                }
                onBlur={() =>
                    setOpenDropdowns(
                        openDropdowns.filter((x) => x !== `${index}-assetId`)
                    )
                }
                style={{ width: "100%", boxShadow: "none" }}
                value={assets?.find((task) => task?.id === record.assetId)?.name}
                options={assets?.map((task) => {
                  return {
                    value: task.id,
                    label: task.name,
                  };
                })}
                placement="bottomLeft"
                onChange={(e: any) => {
                  handleSelectChange("assetId", index, record, e);
                }}
            >
              <Button style={{ width: "100%" }}>
                <Space
                    style={{
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "initial",
                    }}
                >
                <span>
                  {
                    assetTaskData?.find((task) => task?.key === record.assetId)
                        ?.name
                  }
                </span>
                  <DownOutlined />
                </Space>
              </Button>
            </Select>
        ) : (
            <></>
        );
      },
    },
    {
      title: "Task",
      dataIndex: "cleaningTaskId",
      key: "cleaningTaskId",
      width: "36%",
      visible: true,
      render: (text: any, record: any, index: number) => {
        return record.hasOwnProperty("cleaningTaskId") ? (
            <Select
                allowClear
                open={openDropdowns.includes(`${index}-cleaningTaskId`)}
                onClick={() =>
                    openDropdowns.includes(`${index}-cleaningTaskId`)
                        ? setOpenDropdowns(
                            openDropdowns.filter((x) => x !== `${index}-cleaningTaskId`)
                        )
                        : setOpenDropdowns([`${index}-cleaningTaskId`])
                }
                onBlur={() =>
                    setOpenDropdowns(
                        openDropdowns.filter((x) => x !== `${index}-cleaningTaskId`)
                    )
                }
                style={{ width: "100%", boxShadow: "none" }}
                value={
                  assetTaskData?.find((task) => task?.id === record.cleaningTaskId)
                      ?.name
                }
                options={dynamicDropdownFiltering(
                    "cleaningTaskId",
                    record,
                    assetCleaningTasks
                )?.filter(
                    (option, index, self) =>
                        self.findIndex((t) => t.value === option.value) === index
                )}
                placement="bottomLeft"
                onChange={(e: any) =>
                    handleSelectChange("cleaningTaskId", index, record, e)
                }
            >
              <Button style={{ width: "100%" }}>
                <Space
                    style={{
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "initial",
                    }}
                >
                <span>
                  {
                    assetTaskData?.find(
                        (task) => task?.id === record.cleaningTaskId
                    )?.name
                  }
                </span>
                  <DownOutlined />
                </Space>
              </Button>
            </Select>
        ) : (
            <></>
        );
      },
    },
    {
      title: "Daily frequency",
      dataIndex: "nhs",
      key: "nhs",
      width: "15%",
      render: (text: any, record: any, index: number) => (
          <Input
              // disabled
              value={findFrequencyValue(assetCleaningTasks ?? [], record, "nhs")}
              onChange={(e: any) => onInputChange("nhs", index, record, e)}
          />
      ),
    },
    {
      title: "FR Rating",
      dataIndex: "2007",
      key: "2007",
      width: "15%",
      render: (text: any, record: any, index: number) => (
          <Select
              allowClear
              style={{ width: "100%", boxShadow: "none" }}
              // value={findFrequencyValue(assetCleaningTasks ?? [], record, "2007")}
              value={convertFRRating(record.frRating)}
              options={frRatings?.map((rating) => {
                return {
                  value: rating.id,
                  label: rating.name,
                };
              })}
              placement="bottomLeft"
              onChange={(e: any) => {
                handleSelectChange("2007", index, record, e);
              }}
          >
          </Select>
          // <Input
          //     // disabled
          //     value={findFrequencyValue(assetCleaningTasks ?? [], record, "2007")}
          //     onChange={(e: any) => onInputChange("2007", index, record, e)}
          // />
      ),
    },
    {
      title: "FR Frequency",
      dataIndex: "2021",
      key: "2021",
      width: "15%",
      render: (text: any, record: any, index: number) => (
          <span>
            {record.frRating}
          </span>
          // <Input
          //     // disabled
          //     value={findFrequencyValue(assetCleaningTasks ?? [], record, "2021")}
          //     onChange={(e: any) => onInputChange("2021", index, record, e)}
          // />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      width: "15%",
      visible: true,
      render: (text: any, record: any, index: number) => (
          <Space
              align="center"
              style={{ justifyContent: "space-between", width: "100%" }}
          >
            <Space
                size="small"
                style={{ width: "100%", justifyContent: "flex-end", gap: "16px" }}
            >
              qty:{" "}
              {calculateAssetCleaningTaskQuantity(assetCleaningTasksData, record)}
            </Space>
            <Space
                size="small"
                style={{ width: "100%", justifyContent: "flex-end", gap: "16px" }}
            >
              <Button
                  type="primary"
                  size="small"
                  onClick={() => handleChangeQuantity(record, true)}
              >
                <PlusOutlined />
              </Button>
              {record.quantity === 1 ? (
                  <Popconfirm
                      title="Are you sure you want to delete?"
                      onConfirm={() => handleChangeQuantity(record, false)}
                  >
                    <Button size="small">
                      <MinusOutlined />
                    </Button>
                  </Popconfirm>
              ) : (
                  <Button
                      size="small"
                      onClick={() => handleChangeQuantity(record, false)}
                  >
                    <MinusOutlined />
                  </Button>
              )}
            </Space>
          </Space>
      ),
    },
  ];

  const roomAssetCleaningTaskColumns = [
    // {
    //   title: "ServiceAction",
    //   dataIndex: "serviceAction",
    //   key: "serviceAction",
    //   width: "3%",
    //   render: (text: any, record: any, index: number) => {
    //     return record.hasOwnProperty("serviceAction") ? (
    //       <div>
    //         <Checkbox
    //           checked={record.serviceAction}
    //           onChange={(e: any) =>
    //             onCheckboxChange("serviceAction", index, record, e)
    //           }
    //         />
    //       </div>
    //     ) : (
    //       <></>
    //     );
    //   },
    // },
    {
      title: "Audit",
      dataIndex: "auditCheck",
      key: "auditCheck",
      width: "4%",
      visible: buildingId == 102 ? true : false,
      render: (text: any, record: any, index: number) => {
        return (
            <Checkbox></Checkbox>
        )
      },
      
    },
    {
      title: "Asset",
      dataIndex: "assetId",
      key: "assetId",
      width: "12%",
      visible: true,
      render: (text: any, record: any, index: number) => {
        return record.hasOwnProperty("assetId") ? (
          <Select
            allowClear
            open={openDropdowns.includes(`${index}-assetId`)}
            onClick={() =>
              openDropdowns.includes(`${index}-assetId`)
                ? setOpenDropdowns(
                    openDropdowns.filter((x) => x !== `${index}-assetId`)
                  )
                : setOpenDropdowns([`${index}-assetId`])
            }
            onBlur={() =>
              setOpenDropdowns(
                openDropdowns.filter((x) => x !== `${index}-assetId`)
              )
            }
            style={{ width: "100%", boxShadow: "none" }}
            value={assets?.find((task) => task?.id === record.assetId)?.name}
            options={assets?.map((task) => {
              return {
                value: task.id,
                label: task.name,
              };
            })}
            placement="bottomLeft"
            onChange={(e: any) => {
              handleSelectChange("assetId", index, record, e);
            }}
          >
            <Button style={{ width: "100%" }}>
              <Space
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "initial",
                }}
              >
                <span>
                  {
                    assetTaskData?.find((task) => task?.key === record.assetId)
                      ?.name
                  }
                </span>
                <DownOutlined />
              </Space>
            </Button>
          </Select>
        ) : (
          <></>
        );
      },
    },
    // {
    //   title: "Room Type",
    //   dataIndex: "roomTypeId",
    //   key: "roomTypeId",
    //   width: "9%",
    //   render: (text: any, record: any, index: number) => {
    //     return record.hasOwnProperty("roomTypeId") ? (
    //       <Select
    //         allowClear
    //         open={openDropdowns.includes(`${index}-roomTypeId`)}
    //         onClick={() =>
    //           openDropdowns.includes(`${index}-roomTypeId`)
    //             ? setOpenDropdowns(
    //                 openDropdowns.filter((x) => x !== `${index}-roomTypeId`)
    //               )
    //             : setOpenDropdowns([`${index}-roomTypeId`])
    //         }
    //         onBlur={() =>
    //           setOpenDropdowns(
    //             openDropdowns.filter((x) => x !== `${index}-roomTypeId`)
    //           )
    //         }
    //         style={{ width: "100%", boxShadow: "none" }}
    //         value={
    //           roomTypeData?.find(
    //             (roomType) => roomType?.id === record.roomTypeId
    //           )?.name
    //         }
    //         options={dynamicDropdownFiltering(
    //           "roomTypeId",
    //           record,
    //           assetCleaningTasks
    //         )?.filter(
    //           (option, index, self) =>
    //             self.findIndex((t) => t.value === option.value) === index
    //         )}
    //         placement="bottomLeft"
    //         onChange={(e: any) => {
    //           handleSelectChange("roomTypeId", index, record, e);
    //         }}
    //       >
    //         <Button style={{ width: "100%" }}>
    //           <Space
    //             style={{
    //               width: "100%",
    //               justifyContent: "space-between",
    //               alignItems: "initial",
    //             }}
    //           >
    //             <span>
    //               {
    //                 roomTypeData?.find(
    //                   (roomType) => roomType?.id === record.roomTypeId
    //                 )?.name
    //               }
    //             </span>
    //             <DownOutlined />
    //           </Space>
    //         </Button>
    //       </Select>
    //     ) : (
    //       <></>
    //     );
    //   },
    // },
    {
      title: "Task",
      dataIndex: "cleaningTaskId",
      key: "cleaningTaskId",
      width: "36%",
      visible: true,
      render: (text: any, record: any, index: number) => {
        return record.hasOwnProperty("cleaningTaskId") ? (
          <Select
            allowClear
            open={openDropdowns.includes(`${index}-cleaningTaskId`)}
            onClick={() =>
              openDropdowns.includes(`${index}-cleaningTaskId`)
                ? setOpenDropdowns(
                    openDropdowns.filter((x) => x !== `${index}-cleaningTaskId`)
                  )
                : setOpenDropdowns([`${index}-cleaningTaskId`])
            }
            onBlur={() =>
              setOpenDropdowns(
                openDropdowns.filter((x) => x !== `${index}-cleaningTaskId`)
              )
            }
            style={{ width: "100%", boxShadow: "none" }}
            value={
              assetTaskData?.find((task) => task?.id === record.cleaningTaskId)
                ?.name
            }
            options={dynamicDropdownFiltering(
              "cleaningTaskId",
              record,
              assetCleaningTasks
            )?.filter(
              (option, index, self) =>
                self.findIndex((t) => t.value === option.value) === index
            )}
            placement="bottomLeft"
            onChange={(e: any) =>
              handleSelectChange("cleaningTaskId", index, record, e)
            }
          >
            <Button style={{ width: "100%" }}>
              <Space
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "initial",
                }}
              >
                <span>
                  {
                    assetTaskData?.find(
                      (task) => task?.id === record.cleaningTaskId
                    )?.name
                  }
                </span>
                <DownOutlined />
              </Space>
            </Button>
          </Select>
        ) : (
          <></>
        );
      },
    },
    // {
    //   title: "Size",
    //   dataIndex: "assetSize",
    //   key: "assetSize",
    //   width: "9%",
    //   render: (text: any, record: any, index: number) => {
    //     return record.hasOwnProperty("assetSize") ? (
    //       <Select
    //         allowClear
    //         open={openDropdowns.includes(`${index}-assetSize`)}
    //         onClick={() =>
    //           openDropdowns.includes(`${index}-assetSize`)
    //             ? setOpenDropdowns(
    //                 openDropdowns.filter((x) => x !== `${index}-assetSize`)
    //               )
    //             : setOpenDropdowns([`${index}-assetSize`])
    //         }
    //         onBlur={() =>
    //           setOpenDropdowns(
    //             openDropdowns.filter((x) => x !== `${index}-assetSize`)
    //           )
    //         }
    //         style={{ width: "100%", boxShadow: "none" }}
    //         value={
    //           assetSizeData?.find((size) => size?.id === record.assetSize)?.name
    //         }
    //         options={dynamicDropdownFiltering(
    //           "assetSize",
    //           record,
    //           assetCleaningTasks
    //         )?.filter(
    //           (option, index, self) =>
    //             self.findIndex((t) => t.value === option.value) === index
    //         )}
    //         placement="bottomLeft"
    //         onChange={(e: any) =>
    //           handleSelectChange("assetSize", index, record, e)
    //         }
    //       >
    //         <Button style={{ width: "100%" }}>
    //           <Space
    //             style={{
    //               width: "100%",
    //               justifyContent: "space-between",
    //               alignItems: "initial",
    //             }}
    //           >
    //             <span>
    //               {
    //                 assetSizeData?.find((size) => size?.id === record.assetSize)
    //                   ?.name
    //               }
    //             </span>
    //             <DownOutlined />
    //           </Space>
    //         </Button>
    //       </Select>
    //     ) : (
    //       <></>
    //     );
    //   },
    // },
    // {
    //   title: "Material",
    //   dataIndex: "assetMaterial",
    //   key: "assetMaterial",
    //   width: "9%",
    //   render: (text: any, record: any, index: number) => {
    //     return record.hasOwnProperty("assetMaterial") ? (
    //       <Select
    //         allowClear
    //         open={openDropdowns.includes(`${index}-assetMaterial`)}
    //         onClick={() =>
    //           openDropdowns.includes(`${index}-assetMaterial`)
    //             ? setOpenDropdowns(
    //                 openDropdowns.filter((x) => x !== `${index}-assetMaterial`)
    //               )
    //             : setOpenDropdowns([`${index}-assetMaterial`])
    //         }
    //         onBlur={() =>
    //           setOpenDropdowns(
    //             openDropdowns.filter((x) => x !== `${index}-assetMaterial`)
    //           )
    //         }
    //         style={{ width: "100%", boxShadow: "none" }}
    //         value={
    //           assetMaterialData?.find(
    //             (material) => material?.id === record.assetMaterial
    //           )?.name
    //         }
    //         options={dynamicDropdownFiltering(
    //           "assetMaterial",
    //           record,
    //           assetCleaningTasks
    //         )?.filter(
    //           (option, index, self) =>
    //             self.findIndex((t) => t.value === option.value) === index
    //         )}
    //         placement="bottomLeft"
    //         onChange={(e: any) => {
    //           handleSelectChange("assetMaterial", index, record, e);
    //         }}
    //       >
    //         <Button style={{ width: "100%" }}>
    //           <Space
    //             style={{
    //               width: "100%",
    //               justifyContent: "space-between",
    //               alignItems: "initial",
    //             }}
    //           >
    //             <span>
    //               {
    //                 assetMaterialData?.find(
    //                   (material) => material?.id === record.assetMaterial
    //                 )?.name
    //               }
    //             </span>
    //             <DownOutlined />
    //           </Space>
    //         </Button>
    //       </Select>
    //     ) : (
    //       <></>
    //     );
    //   },
    // },
    // {
    //   title: "Periodic",
    //   dataIndex: "isPeriodic",
    //   key: "isPeriodic",
    //   width: "3%",
    //   render: (text: any, record: any, index: number) =>
    //     record.hasOwnProperty("isPeriodic") ? (
    //       <Space
    //         style={{ width: "100%", justifyContent: "center" }}
    //         size="small"
    //       >
    //         <Checkbox
    //           checked={record.isPeriodic}
    //           onChange={(e: any) =>
    //             onCheckboxChange("isPeriodic", index, record, e)
    //           }
    //         />
    //       </Space>
    //     ) : (
    //       <></>
    //     ),
    // },
    {
      title: "Basic",
      dataIndex: "basic",
      key: "basic",
      width: "15%",
      visible: true,
      render: (text: any, record: any, index: number) => (
        <Input
          // disabled
          value={findFrequencyValue(
            assetCleaningTasks ?? [],
            record,
            "basic",
            "10"
          )}
          onChange={(e: any) => onInputChange("basic", index, record, e)}
        />
      ),
    },
    {
      title: "Optimal",
      dataIndex: "optimal",
      key: "optimal",
      width: "15%",
      visible: true,
      render: (text: any, record: any, index: number) => (
        <Input
          // disabled
          value={findFrequencyValue(
            assetCleaningTasks ?? [],
            record,
            "optimal",
            "11"
          )}
          onChange={(e: any) => onInputChange("optimal", index, record, e)}
        />
      ),
    },
    {
      title: "Premium",
      dataIndex: "premium",
      key: "premium",
      width: "15%",
      visible: true,
      render: (text: any, record: any, index: number) => (
        <Input
          // disabled
          value={findFrequencyValue(
            assetCleaningTasks ?? [],
            record,
            "premium",
            "12"
          )}
          onChange={(e: any) => onInputChange("premium", index, record, e)}
        />
      ),
    },
    // {
    //   title: "Custom",
    //   dataIndex: "custom",
    //   key: "custom",
    //   width: "9%",
    //   render: (text: any, record: any, index: number) =>
    //     record.hasOwnProperty("custom") ? (
    //       <Input
    //         value={text}
    //         onChange={(e: any) => onInputChange("custom", index, record, e)}
    //       />
    //     ) : (
    //       <></>
    //     ),
    // },
    {
      title: "Actions",
      key: "actions",
      width: "15%",
      visible: true,
      render: (text: any, record: any, index: number) => (
        <Space
          align="center"
          style={{ justifyContent: "space-between", width: "100%" }}
        >
          <Space
            size="small"
            style={{ width: "100%", justifyContent: "flex-end", gap: "16px" }}
          >
            qty:{" "}
            {calculateAssetCleaningTaskQuantity(assetCleaningTasksData, record)}
          </Space>
          <Space
            size="small"
            style={{ width: "100%", justifyContent: "flex-end", gap: "16px" }}
          >
            <Button
              type="primary"
              size="small"
              onClick={() => handleChangeQuantity(record, true)}
            >
              <PlusOutlined />
            </Button>
            {record.quantity === 1 ? (
              <Popconfirm
                title="Are you sure you want to delete?"
                onConfirm={() => handleChangeQuantity(record, false)}
              >
                <Button size="small">
                  <MinusOutlined />
                </Button>
              </Popconfirm>
            ) : (
              <Button
                size="small"
                onClick={() => handleChangeQuantity(record, false)}
              >
                <MinusOutlined />
              </Button>
            )}
          </Space>
        </Space>
      ),
    },
  ];

  const lightningRoomAssetCleaningTaskColumns = [
    {
      title: "Type",
      dataIndex: "typeFloorFitting",
      key: "typeFloorFitting",
      width: "7%",
      render: (text: any, record: any, index: number) => 
        (
          <Input
            value={record.typeFloorFitting}
            onChange={(e: any) => onLightningInputChange("typeFloorFitting", index, record, e)}
          />
        ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "7%",
      render: (text: any, record: any, index: number) =>
        (
          <Input
            value={record.description}
            onChange={(e: any) => onLightningInputChange("description", index, record, e)}
          />
        ),
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      width: "7%",
      render: (text: any, record: any, index: number) =>
        (
          <Input
            value={record.qty}
            onChange={(e: any) => onLightningInputChange("qty", index, record, e)}
          />
        ),
    },
    {
      title: "Power (W)",
      dataIndex: "powerWatts",
      key: "powerWatts",
      width: "7%",
      render: (text: any, record: any, index: number) =>
        (
          <Input
            value={record.powerWatts}
            onChange={(e: any) => onLightningInputChange("powerWatts", index, record, e)}
          />
        ),
    },
    {
      title: "Run Hours Per Week",
      dataIndex: "runHoursPerWeek",
      key: "runHoursPerWeek",
      width: "7%",
      render: (text: any, record: any, index: number) =>
        (
          <Input
            value={record.runHoursPerWeek}
            onChange={(e: any) => onLightningInputChange("runHoursPerWeek", index, record, e)}
          />
        ),
    },
    {
      title: "KWh per Year",
      dataIndex: "kWhPerYear",
      key: "kWhPerYear",
      width: "7%",
      render: (text: any, record: any, index: number) =>
        (
          <Input
            value={record.kWhPerYear}
            onChange={(e: any) => onLightningInputChange("kWhPerYear", index, record, e)}
          />
        ),
    },
    {
      title: "Cost Year 1",
      dataIndex: "costYear1",
      key: "costYear1",
      width: "7%",
      render: (text: any, record: any, index: number) =>
        (
          <Input
            value={record.costYear1}
            onChange={(e: any) => onLightningInputChange("costYear1", index, record, e)}
          />
        ),
    },
    {
      title: "Cost Year 2",
      dataIndex: "costYear2",
      key: "costYear2",
      width: "7%",
      render: (text: any, record: any, index: number) =>
        (
          <Input
            value={record.costYear2}
            onChange={(e: any) => onLightningInputChange("costYear2", index, record, e)}
          />
        ),
    },
    {
      title: "Cost Year 3",
      dataIndex: "costYear3",
      key: "costYear3",
      width: "7%",
      render: (text: any, record: any, index: number) =>
        (
          <Input
            value={record.costYear3}
            onChange={(e: any) => onLightningInputChange("costYear3", index, record, e)}
          />
        ),
    },
    {
      title: "Cost Year 4",
      dataIndex: "costYear4",
      key: "costYear4",
      width: "7%",
      render: (text: any, record: any, index: number) =>
        (
          <Input
            value={record.costYear4}
            onChange={(e: any) => onLightningInputChange("costYear4", index, record, e)}
          />
        ),
    },
    {
      title: "Cost Year 5",
      dataIndex: "costYear5",
      key: "costYear5",
      width: "7%",
      render: (text: any, record: any, index: number) =>
        (
          <Input
            value={record.costYear5}
            onChange={(e: any) => onLightningInputChange("costYear5", index, record, e)}
          />
        ),
    },
    {
      title: "Total 5 Years",
      dataIndex: "total5Years",
      key: "total5Years",
      width: "7%",
      render: (text: any, record: any, index: number) =>
        (
          <Input
            value={record.total5Years}
            onChange={(e: any) => onLightningInputChange("total5Years", index, record, e)}
          />
        ),
    },
    {
      title: "CO2 per Year tonnes",
      dataIndex: "co2PerYearTonnes",
      key: "co2PerYearTonnes",
      width: "7%",
      render: (text: any, record: any, index: number) =>
        (
          <Input
            value={record.co2PerYearTonnes}
            onChange={(e: any) => onLightningInputChange("co2PerYearTonnes", index, record, e)}
          />
        ),
    },
    {
      title: "Actions",
      key: "actions",
      width: "7%",
      render: (text: any, record: any, index: number) => (
        <Space
          align="center"
          style={{ justifyContent: "space-between", width: "100%" }}
        >
          {/* <Space
            size="small"
            style={{ width: "100%", justifyContent: "flex-end", gap: "16px" }}
          >
            Total:{" "}
            {calculateLightningRecordTotalCost(record)} {" £"}
          </Space> */}
          <Space
            size="small"
            style={{ width: "100%", justifyContent: "flex-end", gap: "16px" }}
          >
            <Popconfirm
                title="Are you sure you want to delete?"
                onConfirm={() => onLightningRecordDelete(record)}
              >
              <Button size="small">
                <MinusOutlined />
              </Button>
            </Popconfirm>
          </Space>
        </Space>
      ),
    },
  ];

  const manualCleaningTaskColumns = [
    {
      title: "Audit",
      dataIndex: "auditCheck",
      key: "auditCheck",
      width: "2%",
      render: (text: any, record: any, index: number) => {
        return (
            <Checkbox
              checked={record.audit}
              onChange={(e: any) => onManualCleaningInputChange("audit", index, record, e)}
            ></Checkbox>
        )
      },
    },
    {
      title: "Asset",
      dataIndex: "asset",
      key: "asset",
      width: "7%",
      render: (text: any, record: any, index: number) => 
        (
          <Input
            value={record.asset}
            onChange={(e: any) => onManualCleaningInputChange("asset", index, record, e)}
          />
        ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "7%",
      render: (text: any, record: any, index: number) =>
        (
          <Input
            value={record.description}
            onChange={(e: any) => onManualCleaningInputChange("description", index, record, e)}
          />
        ),
    },
    {
      title: "Basic",
      dataIndex: "basic",
      key: "basic",
      width: "5%",
      render: (text: any, record: any, index: number) =>
        (
          <Input
            value={record.basic}
            onChange={(e: any) => onManualCleaningInputChange("basic", index, record, e)}
          />
        ),
    },
    {
      title: "Optimal",
      dataIndex: "optimal",
      key: "optimal",
      width: "5%",
      render: (text: any, record: any, index: number) =>
        (
          <Input
            value={record.optimal}
            onChange={(e: any) => onManualCleaningInputChange("optimal", index, record, e)}
          />
        ),
    },
    {
      title: "Premium",
      dataIndex: "premium",
      key: "premium",
      width: "5%",
      render: (text: any, record: any, index: number) =>
        (
          <Input
            value={record.premium}
            onChange={(e: any) => onManualCleaningInputChange("premium", index, record, e)}
          />
        ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: "5%",
      render: (text: any, record: any, index: number) =>
        (
          <Input
            value={record.quantity}
            onChange={(e: any) => onManualCleaningInputChange("quantity", index, record, e)}
          />
        ),
    },
    {
      title: "Total floor space",
      dataIndex: "totalFloorSpace",
      key: "totalFloorSpace",
      width: "5%",
      render: (text: any, record: any, index: number) =>
        (
          <Input
            value={record.totalFloorSpace}
            onChange={(e: any) => onManualCleaningInputChange("totalFloorSpace", index, record, e)}
          />
        ),
    },
    {
      title: "Floor surface type",
      dataIndex: "floorSurfaceType",
      key: "floorSurfaceType",
      width: "7%",
      render: (text: any, record: any, index: number) =>
        (
          <Input
            value={record.floorSurfaceType}
            onChange={(e: any) => onManualCleaningInputChange("floorSurfaceType", index, record, e)}
          />
        ),
    },
    {
      title: "Actions",
      key: "actions",
      width: "7%",
      render: (text: any, record: any, index: number) => (
        <Space
          align="center"
          style={{ justifyContent: "space-between", width: "100%" }}
        >
          {/* <Space
            size="small"
            style={{ width: "100%", justifyContent: "flex-end", gap: "16px" }}
          >
            Total:{" "}
            {calculateLightningRecordTotalCost(record)} {" £"}
          </Space> */}
          <Space
            size="small"
            style={{ width: "100%", justifyContent: "flex-end", gap: "16px" }}
          >
            <Popconfirm
                title="Are you sure you want to delete?"
                onConfirm={() => onManualCleaningRecordDelete(record)}
              >
              <Button size="small">
                <MinusOutlined />
              </Button>
            </Popconfirm>
          </Space>
        </Space>
      ),
    },
  ];

  const [assetCleaningTasksData, setAssetCleaningTasksData] = useState<
    IRoomAssetCleaningTaskExtended[]
  >([]);

  const [lightningTasks, setLightningTasks] = useState<ILightningTask[]>([]);
  const [manualCleaningTasks, setManualCleaningTasks] = useState<ILightningTask[]>([]);

  const containerRef = useRef<HTMLDivElement>(null);
  const mapContainerRef = useRef<L.Map>(null);

  const [floorplanImgSize, setFloorplanImgSize] = useState<ImageSize | null>();

  useLayoutEffect(() => {
    const width = containerRef?.current?.offsetWidth ?? 400;
    const height = config.minified
      ? 1000
      : containerRef?.current?.offsetHeight ?? 400;
    setContainerBounds([
      [-5 * height, -5 * width],
      [5 * height, 5 * width],
    ]);
  }, [config.minified]);

  useEffect(() => {
    if (assetCleaningTasks) {
      const filteredRoomTypes = assetCleaningTasks
        .filter(
          (item, index) =>
            assetCleaningTasks.findIndex(
              (s) => item.roomType?.id === s.roomType?.id
            ) === index && item.roomType?.name
        )
        .map((item) => {
          return {
            id: item.roomType?.id,
            name: item.roomType?.name,
          };
        });
      const filteredMaterials = assetCleaningTasks
        .filter(
          (item, index) =>
            assetCleaningTasks.findIndex(
              (s) => item.assetMaterial === s.assetMaterial
            ) === index
        )
        .map((item) => {
          return {
            id: item.assetMaterial,
            name: item.assetMaterial,
          };
        });
      const filteredSizes = assetCleaningTasks
        .filter(
          (item, index) =>
            assetCleaningTasks.findIndex(
              (s) => item.assetSize === s.assetSize
            ) === index
        )
        .map((item) => {
          return {
            id: item.assetSize,
            name: item.assetSize,
          };
        });
      const filteredTasks = assetCleaningTasks
        .filter(
          (item, index) =>
            assetCleaningTasks.findIndex(
              (s) => item.cleaningTask?.id === s.cleaningTask?.id
            ) === index
        )
        .map((item) => item.cleaningTask);
      setRoomTypeData(filteredRoomTypes);
      setAssetMaterialData(filteredMaterials);
      setAssetSizeData(filteredSizes);
      setAssetTaskData(filteredTasks);
    }
  }, [assetCleaningTasks]);

  useEffect(() => {
    if (floorplanImgUrl) {
      const tempImageElem = document.createElement("img");
      tempImageElem.src = floorplanImgUrl;
      tempImageElem.onload = () => {
        setFloorplanImgSize({
          width: tempImageElem.width,
          height: tempImageElem.height,
        });
      };
    }
  }, [floorplanImgUrl]);

  useEffect(() => {
    if (selectedFloor && floorplanImgUrl) {
      setActiveFloor({
        ...selectedFloor,
        imageComponent: floorplanImgUrl,
      });
    }
  }, [selectedFloor, floorplanImgUrl]);

  useEffect(() => {
    if (activeFloor && floorplanImgSize) {
      const roomsList = activeFloor?.rooms
        ?.filter((room) => room.geoJsonGeometry)
        .map((room: IRoom) => {
          return {
            ...room,
            transformedGeometry: transformGeometry(
              JSON.parse(room.geoJsonGeometry)
            ),
            color: randomHexColor(),
          };
        });
      const calculatedBounds = calculateMapBounds(
        roomsList
          ?.filter((room) => room.transformedGeometry?.coordinates.length > 0)
          .map((room) => room.transformedGeometry?.coordinates)
          .flatMap((c) => c[0]),
        floorplanImgSize,
        activeFloor
      );
      setRoomsData(roomsList);

      setBounds(calculatedBounds);
    }
  }, [activeFloor, floorplanImgSize]);

  useEffect(() => {
    if (!activeFloor?.rooms || !floorplanImgSize) {
      return;
    }

    const rooms = activeFloor.rooms;

    let transformedRooms = rooms
      ?.filter((room) => room.geoJsonGeometry)
      .map((room: IRoom) => {
        return {
          ...room,
          transformedGeometry: transformGeometry(
            JSON.parse(room.geoJsonGeometry)
          ),
          color: randomHexColor(),
        };
      });

    const calculatedBounds = calculateMapBounds(
      transformedRooms
        ?.filter((room) => room.transformedGeometry?.coordinates.length > 0)
        .map((room) => room.transformedGeometry?.coordinates)
        .flatMap((c) => c[0]),
      floorplanImgSize,
      activeFloor
    );

    if (showDepartmentsOnly) {
      transformedRooms = transformedRooms.filter((room) =>
        room.name?.toLowerCase()?.includes("dpt")
      );
    }

    let sortedByRoomAndDepartment = transformedRooms.sort((a, b) =>
      sortByRoomsAndDepartment(a.name, b.name)
    );
    setRoomsData(sortedByRoomAndDepartment);
    setBounds(calculatedBounds);
  }, [showDepartmentsOnly, activeFloor, floorplanImgSize]);

  const handleCreateRoom = (feature: any, roomLayers: any[]) => {
    const updatedRoomsData = roomLayers
      .filter((roomLayer) => roomLayer.feature)
      .map((roomLayer) => {
        const revertedGeometry = revertTransformGeometry(
          roomLayer.feature.geometry,
          roomLayer.feature.properties.ceilingHeight
        );
        const room = {
          id: roomLayer.feature.properties.id,
          name: roomLayer.feature.properties.name,
          color: roomLayer.feature.properties.color,
          floorId: roomLayer.feature.floorId,
          roomType: roomLayer.feature.properties.roomType,
          geoJsonGeometry: JSON.stringify(revertedGeometry),
          transformedGeometry: roomLayer.feature.geometry,
          ceilingHeight: roomLayer.feature.properties.ceilingHeight,
          grossArea: roomLayer.feature.properties.grossArea,
          voidArea: roomLayer.feature.properties.voidArea,
          fixedFurnitureArea: roomLayer.feature.properties.fixedFurnitureArea,
        };
        return room;
      });

    const newRoomRevertedGeometry = revertTransformGeometry(
      feature.geometry,
      feature.properties.ceilingHeight
    );
    const newRoom: IRoom = {
      id: feature.properties.id,
      name: feature.properties.name,
      color: feature.properties.color,
      floorId: feature.floorId,
      roomType: feature.properties.roomType,
      geoJsonGeometry: JSON.stringify(newRoomRevertedGeometry),
      transformedGeometry: feature.geometry,
      ceilingHeight: feature.properties.ceilingHeight,
      grossArea: feature.properties.grossArea,
      voidArea: feature.properties.voidArea,
      fixedFurnitureArea: feature.properties.fixedFurnitureArea,
    };
    setActiveRoomData(newRoom);
    setRoomsData([...updatedRoomsData, newRoom]);
    setIsEditRoomModalOpen(true);
  };

  const handleUpdateRoomGeometry = (roomLayer: any, rooms: IRoom[]) => {
    let updatedRoomData = roomsData?.find(
      (room) => room.id === roomLayer.properties?.id
    );
    if (updatedRoomData) {
      const layerGeoJson = roomLayer.toGeoJSON();
      const revertedGeometry = revertTransformGeometry(
        layerGeoJson.geometry,
        roomLayer.feature.properties.ceilingHeight ?? 0
      );
      const mappedRoomData: UpdateRoomRequest = {
        id: updatedRoomData?.id ?? 0,
        name: updatedRoomData?.name ?? "",
        grossArea: updatedRoomData?.grossArea ?? 0,
        voidArea: updatedRoomData?.voidArea ?? 0,
        fixedFurnitureArea: updatedRoomData?.fixedFurnitureArea ?? 0,
        ceilingHeight: updatedRoomData?.ceilingHeight ?? 0,
        floorId: updatedRoomData?.floorId ?? 0,
        geoJsonGeometry: JSON.stringify(revertedGeometry),
        roomAssetCleaningTasks: updatedRoomData.roomAssetCleaningTasks,
      };
      RoomsService.update(mappedRoomData!).then((response) => {
        if (response) {
          const updatedRoomsData = rooms.map((room) => {
            if (room.id === roomLayer.properties.id) {
              const revGeometry = revertedGeometry;
              return {
                ...room,
                transformedGeometry: layerGeoJson.geometry,
                geoJsonGeometry: JSON.stringify(revGeometry),
              };
            }
            return room;
          });
          setRoomsData(updatedRoomsData);
        }
      });
    }
  };

  const handleActiveRoom = useCallback(
    (feature: any) => {
      const selectedRoom = roomsDataRef.current?.find(
        (room) => room.id === feature?.properties.id
      );

      setRoomTooltipActive(true);
      if (selectedRoom) {
        // If room is found, it means that it is an existing room
        setActiveRoomData(selectedRoom);
      }
    },
    [roomsData]
  );

  function FeatureTooltip() {
    const map = useMapEvents({
      click(e) {
        setRoomTooltipActive(false);
        setActiveRoomData(null);
      },
      zoomend(e) {
        setRoomTooltipActive(false);
        setActiveRoomData(null);
      },
    });

    const latLng = useMemo(() => {
      return calculateActiveFeatureTooltipPosition(
        activeRoomData,
        mapContainerRef?.current?.getZoom() ?? config.minZoom
      );
    }, []);

    if (latLng) {
      return (
        <Marker
          position={[51.51, -0.09]}
          icon={
            new L.Icon({
              iconUrl: "../../../img/icons/walking.svg",
              className: "marker-placeholder",
            })
          }
        >
          <Tooltip
            direction="right"
            position={latLng}
            interactive={true}
            permanent
          >
            <div
              className={classNames(
                styles.actionsModal,
                config.enableVirtualWalk ? styles.virtualWalk : ""
              )}
            >
              <span>{activeRoomData?.name ? activeRoomData?.name : "-"}</span>
              <div>
                <div
                    onClick={(e: any) => {
                      L.DomEvent.stopPropagation(e);
                      setActiveRoomData(
                          roomsData?.find((r) => r.id === activeRoomData?.id) ??
                          null
                      );
                      openListOfItems(false);
                      setRoomTooltipActive(false);
                    }}
                >
                  <UnorderedListOutlined />
                  <span>Cleaning spec</span>
                </div>
                <div
                  onClick={(e: any) => {
                    L.DomEvent.stopPropagation(e);
                    setActiveRoomData(
                      roomsData?.find((r) => r.id === activeRoomData?.id) ??
                        null
                    );
                    setIsEditRoomModalOpen(true);
                    setRoomTooltipActive(false);
                  }}
                >
                  <EditOutlined />
                  <span>Edit room</span>
                </div>
                <div
                  onClick={(e: any) => {
                    handleRoomDelete(activeRoomData?.id);
                  }}
                >
                  <DeleteOutlined />
                  <span>Delete room</span>
                </div>
              </div>
            </div>
          </Tooltip>
        </Marker>
      );
    }

    return null;
  }

  const showRoomList = () => {
    setRoomListOpen(true);
  };

  const closeRoomList = () => {
    setRoomListOpen(false);
  };

  const openListOfItems = (showFloorItems: boolean, lightning: string | null = null) => {
    if (lightning) {
      setLightningType(lightning!);
    }
    let filteredRoomAssetCleaningTasks: IRoomAssetCleaningTask[] = [];
    if (showFloorItems) {
      var allRoomAssetCleaningTasks = activeFloor?.rooms?.map(
        (room) => room.roomAssetCleaningTasks
      );

      if (allRoomAssetCleaningTasks) {
        filteredRoomAssetCleaningTasks = allRoomAssetCleaningTasks.flatMap(
          (roomAssetCleaningTasks) => {
            if (roomAssetCleaningTasks) {
              return roomAssetCleaningTasks;
            }
            return [];
          }
        );
      }

      // const departments = activeFloor?.rooms?.filter((room) =>
      //   room.name?.includes("Dpt")
      // );
      // const departmentsRoomAssetCleaningTasks = departments?.map(
      //   (department) => department.roomAssetCleaningTasks
      // );
      // if (departmentsRoomAssetCleaningTasks)
      //   filteredRoomAssetCleaningTasks =
      //     departmentsRoomAssetCleaningTasks.flatMap(
      //       (roomAssetCleaningTasks) => {
      //         if (roomAssetCleaningTasks) {
      //           return roomAssetCleaningTasks;
      //         }
      //         return [];
      //       }
      //     );
    } else {
      filteredRoomAssetCleaningTasks =
        roomsData
          ?.filter((room: IRoom) => room.id === activeRoomData?.id)
          .flatMap((room: IRoom) => room.roomAssetCleaningTasks ?? {}) ?? [];
    }
    setActiveRoomData({
      ...activeRoomData,
      id: showFloorItems ? 0 : activeRoomData?.id,
      name: showFloorItems ? activeFloor?.name : activeRoomData?.name,
    });
    setAssetCleaningTasksData(
      filteredRoomAssetCleaningTasks
        .filter((item: IRoomAssetCleaningTask) => item.id)
        .map((item: IRoomAssetCleaningTask, index: number) => {
          const task = assetCleaningTasks?.find(
            (task) => task.id === item.assetCleaningTaskId
          );
          return {
            key: index + 1,
            floorId: activeFloor?.id,
            roomId: activeRoomData?.id,
            roomAssetCleaningTaskId: item.id,
            assetCleaningTaskId: item.assetCleaningTaskId,
            assetId: task?.asset?.id,
            roomTypeId: task?.roomType?.id,
            cleaningTaskId: task?.cleaningTask?.id,
            assetSize: task?.assetSize,
            assetMaterial: task?.assetMaterial,
            isPeriodic: task?.isPeriodic,
            frRating: item.frRating,
            custom:
              item.roomAssetCleaningTaskFrequencies?.find(
                (freq) => freq.frequency?.id === 4
              )?.value ?? 0,
          };
        })
    );
    setAssetCleaningTasksModalOpen(true);
  };

  const openVirtualWalk = (feature: any) => {
    setVirtualWalkModalOpen(true);
  };

  const handleChangeQuantity = (
    item: IRoomAssetCleaningTaskExtended,
    isAdd: boolean
  ) => {
    let updatedAssetCleaningTasksData: IRoomAssetCleaningTaskExtended[] = [];
    if (item.roomAssetCleaningTaskId) {
      // Update quantity of existing item (has roomAssetCleaningTaskId)
      const copyOfAssetCleaningTasksData = assetCleaningTasksData.filter(
        (roomAsset) =>
          roomAsset.assetCleaningTaskId === item.assetCleaningTaskId
      );
      if (isAdd) {
        const newAssetCleaningTask: IRoomAssetCleaningTaskExtended = {
          ...copyOfAssetCleaningTasksData[0],
          key: assetCleaningTasksData
            ? (assetCleaningTasksData
                .sort((a, b) => sortByNumberProperty(b.key, a.key))
                .map((item) => item.key)[0] ?? 0) + 1
            : 1,
          roomAssetCleaningTaskId: 0, // New item won't have roomAssetCleaningTaskId
        };
        updatedAssetCleaningTasksData = [
          ...assetCleaningTasksData,
          newAssetCleaningTask,
        ];
      } else {
        const assetToBeRemoved = copyOfAssetCleaningTasksData
          .sort((a, b) => sortByNumberProperty(b.key, a.key))
          .find(
            (roomAsset) =>
              roomAsset.assetCleaningTaskId === item.assetCleaningTaskId
          );
        updatedAssetCleaningTasksData = assetCleaningTasksData.filter(
          (roomAsset) => roomAsset.key !== assetToBeRemoved?.key
        ); // Remove the item from list with highest key value
      }
    } else {
      // Update quantity of new item (don't have roomAssetCleaningTaskId)
      const copyOfAssetCleaningTasksData = assetCleaningTasksData.filter(
        (roomAsset) =>
          roomAsset.assetId === item.assetId &&
          roomAsset.roomTypeId === item.roomTypeId &&
          roomAsset.cleaningTaskId === item.cleaningTaskId &&
          roomAsset.assetSize === item.assetSize &&
          roomAsset.assetMaterial === item.assetMaterial
      );
      if (isAdd) {
        const newAssetCleaningTask: IRoomAssetCleaningTaskExtended = {
          ...copyOfAssetCleaningTasksData[0],
          key: assetCleaningTasksData
            ? (assetCleaningTasksData
                .sort((a, b) => sortByNumberProperty(b.key, a.key))
                .map((item) => item.key)[0] ?? 0) + 1
            : 1,
          roomAssetCleaningTaskId: 0,
        };
        updatedAssetCleaningTasksData = [
          ...assetCleaningTasksData,
          newAssetCleaningTask,
        ];
      } else {
        const assetToBeRemoved = copyOfAssetCleaningTasksData
          .sort((a, b) => sortByNumberProperty(b.key, a.key))
          .find(
            (roomAsset) =>
              roomAsset.assetId === item.assetId &&
              roomAsset.roomTypeId === item.roomTypeId &&
              roomAsset.cleaningTaskId === item.cleaningTaskId &&
              roomAsset.assetSize === item.assetSize &&
              roomAsset.assetMaterial === item.assetMaterial
          );
        updatedAssetCleaningTasksData = assetCleaningTasksData.filter(
          (roomAsset) => roomAsset.key !== assetToBeRemoved?.key
        ); // Remove the item from list with highest key value
      }
    }
    setAssetCleaningTasksData(updatedAssetCleaningTasksData);
  };

  const handleAddNewLightningTask = () => {
    const newItem: ILightningTask = {
      key: lightningTasks
        ? (lightningTasks
            .sort((a, b) => sortByNumberProperty(b.key, a.key))
            .map((item) => item.key)[0] ?? 0) + 1
        : 1,
      typeFloorFitting: '',
      description: '',
      powerWatts: 0,
      qty: 1,
      runHoursPerWeek: 0,
    };
    const newItems = [...lightningTasks, newItem];
    setLightningTasks(newItems);
  };

  const handleAddNewManualCleaningTask = () => {
    const newItem: IManualCleaningTask = {
      key: manualCleaningTasks
        ? (manualCleaningTasks
            .sort((a, b) => sortByNumberProperty(b.key, a.key))
            .map((item) => item.key)[0] ?? 0) + 1
        : 1,
      audit: false,
      asset: '',
      description: '',
      quantity: 1,
      basic: 5,
      optimal: 5,
      premium: 5,
      totalFloorSpace: 0,
      floorSurfaceType: '',
    };
    const newItems = [...manualCleaningTasks, newItem];
    setManualCleaningTasks(newItems);
  };

  useEffect(() => {
    if (activeFloor?.scanSequence === 0) {
      switch (lightningType) {
        case "Existing":
          const lightingData: ILightningTask[] = [
            {
              typeFloorFitting: 'A2',
              description: 'Round Bulkheads',
              qty: 54.0,
              powerWatts: 32.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 7547.904,
              costYear1: 1660.54,
              costYear2: 1942.8304896,
              costYear3: 2273.111672832,
              costYear4: 2659.5406572134398,
              costYear5: 3111.6625689397247,
              total5Years: 11647.684268585164,
              co2PerYearTonnes: 4.075868160000001
          },
          {
              typeFloorFitting: 'A2E',
              description: 'Round Bulkheads EM',
              qty: 13.0,
              powerWatts: 32.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 1817.088,
              costYear1: 399.76,
              costYear2: 467.7184512,
              costYear3: 547.230587904,
              costYear4: 640.2597878476801,
              costYear5: 749.1039517817857,
              total5Years: 2804.0721387334656,
              co2PerYearTonnes: 0.98122752
          },
          {
              typeFloorFitting: 'A21',
              description: '230 Downlight',
              qty: 32.0,
              powerWatts: 52.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 7268.352,
              costYear1: 1599.04,
              costYear2: 1870.8738048,
              costYear3: 2188.922351616,
              costYear4: 2561.0391513907202,
              costYear5: 2996.4158071271427,
              total5Years: 11216.288554933863,
              co2PerYearTonnes: 3.92491008
          },
          {
              typeFloorFitting: 'A21E',
              description: '230 Downlight EM',
              qty: 24.0,
              powerWatts: 52.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 5451.264,
              costYear1: 1199.28,
              costYear2: 1403.1553536000001,
              costYear3: 1641.6917637120002,
              costYear4: 1920.7793635430403,
              costYear5: 2247.3118553453573,
              total5Years: 8412.216416200397,
              co2PerYearTonnes: 2.94368256
          },
          {
              typeFloorFitting: 'NO REF',
              description: '180 Downlight',
              qty: 6.0,
              powerWatts: 52.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 1362.816,
              costYear1: 299.82,
              costYear2: 350.78883840000003,
              costYear3: 410.42294092800006,
              costYear4: 480.1948408857601,
              costYear5: 561.8279638363393,
              total5Years: 2103.0541040500993,
              co2PerYearTonnes: 0.73592064
          },
          {
              typeFloorFitting: 'A19',
              description: '110 Downlight',
              qty: 3.0,
              powerWatts: 26.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 340.704,
              costYear1: 74.95,
              costYear2: 87.69720960000001,
              costYear3: 102.60573523200001,
              costYear4: 120.04871022144002,
              costYear5: 140.45699095908483,
              total5Years: 525.7635260125248,
              co2PerYearTonnes: 0.18398016
          },
          {
              typeFloorFitting: 'A11/A13/A17/A18',
              description: 'Twin 1500 Units',
              qty: 17.0,
              powerWatts: 116.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 8613.696,
              costYear1: 1895.01,
              costYear2: 2217.1653504,
              costYear3: 2594.083459968,
              costYear4: 3035.0776481625603,
              costYear5: 3551.0408483501956,
              total5Years: 13292.380426880756,
              co2PerYearTonnes: 4.65139584
          },
          {
              typeFloorFitting: 'A11E/A13E/A17E/A18',
              description: 'Twin 1500 Units EM',
              qty: 2.0,
              powerWatts: 116.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 1013.376,
              costYear1: 222.94,
              costYear2: 260.8429824,
              costYear3: 305.186289408,
              costYear4: 357.06795860735997,
              costYear5: 417.76951157061114,
              total5Years: 1563.8094619859712,
              co2PerYearTonnes: 0.54722304
          },
          {
              typeFloorFitting: 'A11 - UPS & GYM /A12',
              description: 'Non corrosive 1500',
              qty: 15.0,
              powerWatts: 116.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 7600.32,
              costYear1: 1672.07,
              costYear2: 1956.3223679999999,
              costYear3: 2288.8971705599997,
              costYear4: 2678.0096895551997,
              costYear5: 3133.2713367795836,
              total5Years: 11728.570964894783,
              co2PerYearTonnes: 4.104172800000001
          },
          {
              typeFloorFitting: 'A11 - UPS & GYM /A12E',
              description: 'Non corrosive 1500 EM',
              qty: 4.0,
              powerWatts: 116.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 2026.752,
              costYear1: 445.89,
              costYear2: 521.6859648,
              costYear3: 610.372578816,
              costYear4: 714.1359172147199,
              costYear5: 835.5390231412223,
              total5Years: 3127.6189239719424,
              co2PerYearTonnes: 1.09444608
          },
          {
              typeFloorFitting: 'E1',
              description: 'Surface EM',
              qty: 15.0,
              powerWatts: 8.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 524.16,
              costYear1: 115.32,
              costYear2: 134.918784,
              costYear3: 157.85497727999999,
              costYear4: 184.69032341759998,
              costYear5: 216.08767839859198,
              total5Years: 808.8669630961919,
              co2PerYearTonnes: 0.28304640000000003
          },
          {
              typeFloorFitting: 'E1- Accesorry',
              description: 'Surface EM kit',
              qty: 8.0,
              powerWatts: 0.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 0.0,
              costYear1: 0.0,
              costYear2: 0.0,
              costYear3: 0.0,
              costYear4: 0.0,
              costYear5: 0.0,
              total5Years: 0.0,
              co2PerYearTonnes: 0.0
          },
          {
              typeFloorFitting: 'E1',
              description: 'Surafce EM Box',
              qty: 6.0,
              powerWatts: 8.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 209.664,
              costYear1: 46.13,
              costYear2: 53.967513600000004,
              costYear3: 63.141990912000004,
              costYear4: 73.87612936704001,
              costYear5: 86.4350713594368,
              total5Years: 323.5467852384768,
              co2PerYearTonnes: 0.11321856
          },
          {
              typeFloorFitting: 'NO REF',
              description: 'Bulkhead EM',
              qty: 1.0,
              powerWatts: 8.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 34.944,
              costYear1: 7.69,
              costYear2: 8.9945856,
              costYear3: 10.523665152000001,
              costYear4: 12.312688227840002,
              costYear5: 14.405845226572803,
              total5Years: 53.9244642064128,
              co2PerYearTonnes: 0.018869760000000003
          },
          {
              typeFloorFitting: 'NO REF',
              description: 'Pin Spot EM',
              qty: 3.0,
              powerWatts: 5.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 65.52,
              costYear1: 14.41,
              costYear2: 16.864848,
              costYear3: 19.731872159999998,
              costYear4: 23.086290427199998,
              costYear5: 27.010959799823997,
              total5Years: 101.10837038702398,
              co2PerYearTonnes: 0.035380800000000004
          },
          {
              typeFloorFitting: 'A24',
              description: '1200 x 300 Twin',
              qty: 9.0,
              powerWatts: 72.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 2830.464,
              costYear1: 622.70,
              costYear2: 728.5614336,
              costYear3: 852.416877312,
              costYear4: 997.32774645504,
              costYear5: 1166.8734633523968,
              total5Years: 4367.881600719436,
              co2PerYearTonnes: 1.52845056
          },
          {
              typeFloorFitting: 'A24E',
              description: '1200 x 300 Twin EM',
              qty: 1.0,
              powerWatts: 72.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 314.496,
              costYear1: 69.19,
              costYear2: 80.95127039999998,
              costYear3: 94.71298636799999,
              costYear4: 110.81419405055999,
              costYear5: 129.65260703915519,
              total5Years: 485.3201778577152,
              co2PerYearTonnes: 0.16982784
          },
          {
              typeFloorFitting: 'E24/A24E- Accesorry',
              description: 'Recessed 1200 x 300 kit',
              qty: 10.0,
              powerWatts: 0.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 0.0,
              costYear1: 0.0,
              costYear2: 0.0,
              costYear3: 0.0,
              costYear4: 0.0,
              costYear5: 0.0,
              total5Years: 0.0,
              co2PerYearTonnes: 0.0
          },
          {
              typeFloorFitting: 'Indirect Light in Main Office (NO REF)',
              description: 'Surface Battens',
              qty: 185.0,
              powerWatts: 58.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 46868.64,
              costYear1: 10311.10,
              costYear2: 12063.987936,
              costYear3: 14114.86588512,
              costYear4: 16514.3930855904,
              costYear5: 19321.839910140767,
              total5Years: 72326.18761685117,
              co2PerYearTonnes: 25.3090656
          },
          {
              typeFloorFitting: '6X6 Panel  (NO REF)',
              description: '600 x 600 Modules',
              qty: 5.0,
              powerWatts: 72.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 1572.48,
              costYear1: 345.95,
              costYear2: 404.75635199999994,
              costYear3: 473.56493183999993,
              costYear4: 554.0709702528,
              costYear5: 648.263035195776,
              total5Years: 2426.6008892885757,
              co2PerYearTonnes: 0.8491392000000001
          },
          {
              typeFloorFitting: '6X6 Panel/EM (NO REF)',
              description: '600 x 600 Modules em',
              qty: 2.0,
              powerWatts: 72.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 628.992,
              costYear1: 138.38,
              costYear2: 161.90254079999997,
              costYear3: 189.42597273599998,
              costYear4: 221.62838810111998,
              costYear5: 259.30521407831037,
              total5Years: 970.6403557154304,
              co2PerYearTonnes: 0.33965568
          },
          {
              typeFloorFitting: 'A4/A14',
              description: 'Surface Square',
              qty: 11.0,
              powerWatts: 28.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 1345.344,
              costYear1: 295.98,
              costYear2: 346.2915456,
              costYear3: 405.16110835200004,
              costYear4: 474.0384967718401,
              costYear5: 554.6250412230529,
              total5Years: 2076.091871946893,
              co2PerYearTonnes: 0.72648576
          },
          {
              typeFloorFitting: 'Wall Wash In Lobby (NO REF)',
              description: 'Recessed Wall Wash 1.2',
              qty: 2.0,
              powerWatts: 36.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 314.496,
              costYear1: 69.98,
              costYear2: Math.round(80.95127039999998),
              costYear3: Math.round(94.71298636799999),
              costYear4: Math.round(110.81419405055999),
              costYear5: Math.round(129.65260703915519),
              total5Years: Math.round(485.3201778577152),
              co2PerYearTonnes: 0.16982784
          },
          {
              typeFloorFitting: 'Wall Wash In Lobby (NO REF)',
              description: 'Recessed Wall Wash .6',
              qty: 1.0,
              powerWatts: 18.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 78.624,
              costYear1: 66.04415999999999,
              costYear2: 20.237817599999996,
              costYear3: 23.678246591999997,
              costYear4: 27.703548512639998,
              costYear5: 32.413151759788796,
              total5Years: 121.3300444644288,
              co2PerYearTonnes: 0.04245696
          },
          {
              typeFloorFitting: 'SWITCH',
              description: 'Wall Switch',
              qty: 15.0,
              powerWatts: 0.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 0.0,
              costYear1: 0.0,
              costYear2: 0.0,
              costYear3: 0.0,
              costYear4: 0.0,
              costYear5: 0.0,
              total5Years: 0.0,
              co2PerYearTonnes: 0.0
          },
          {
              typeFloorFitting: 'SENSOR',
              description: 'Sensor',
              qty: 30.0,
              powerWatts: 0.0,
              runHoursPerWeek: 84.0,
              kWhPerYear: 0.0,
              costYear1: 0.0,
              costYear2: 0.0,
              costYear3: 0.0,
              costYear4: 0.0,
              costYear5: 0.0,
              total5Years: 0.0,
              co2PerYearTonnes: 0.0
          },
          {
              typeFloorFitting: 'Fitting 27',
              description: '',
              qty: null,
              powerWatts: null,
              runHoursPerWeek: 84.0,
              kWhPerYear: 0.0,
              costYear1: 0.0,
              costYear2: 0.0,
              costYear3: 0.0,
              costYear4: 0.0,
              costYear5: 0.0,
              total5Years: 0.0,
              co2PerYearTonnes: 0.0
          },
          {
              typeFloorFitting: 'Fitting 28',
              description: '',
              qty: null,
              powerWatts: null,
              runHoursPerWeek: 84.0,
              kWhPerYear: 0.0,
              costYear1: 0.0,
              costYear2: 0.0,
              costYear3: 0.0,
              costYear4: 0.0,
              costYear5: 0.0,
              total5Years: 0.0,
              co2PerYearTonnes: 0.0
          },
          {
              typeFloorFitting: 'Fitting 29',
              description: 'Contingency',
              qty: 1.0,
              powerWatts: null,
              runHoursPerWeek: null,
              kWhPerYear: 0.0,
              costYear1: 0.0,
              costYear2: 0.0,
              costYear3: 0.0,
              costYear4: 0.0,
              costYear5: 0.0,
              total5Years: 0.0,
              co2PerYearTonnes: 0.0
          },
        ];
        setLightningTasks(lightingData);
      }
    }
  }, [activeFloor, lightningType])

  const handleAddNew = () => {
    const newItem: IRoomAssetCleaningTaskExtended = {
      key: assetCleaningTasksData
        ? (assetCleaningTasksData
            .sort((a, b) => sortByNumberProperty(b.key, a.key))
            .map((item) => item.key)[0] ?? 0) + 1
        : 1,
      floorId: activeFloor?.id,
      roomId: activeRoomData?.id ?? 0,
      roomAssetCleaningTaskId: 0,
      assetCleaningTaskId: 0,
      assetId: 0,
      roomTypeId: 0,
      cleaningTaskId: 0,
      assetSize: "",
      assetMaterial: "",
      isPeriodic: false,
      custom: 0,
    };
    const newItems = [...assetCleaningTasksData, newItem];
    setAssetCleaningTasksData(newItems);
  };

  const onLightningInputChange = (key: any, index: any, record: ILightningTask, e: React.ChangeEvent<HTMLInputElement>) => {
    let updated = false;
    const newGroupData = lightningTasks.map((item, idx) => {
      if (item.key !== record.key || updated)
        return {
          ...item,
        };
      updated = true;
      return {
        ...item,
        [key]: e.target.value,
      };
    });
    setLightningTasks(newGroupData);
  }

  const onManualCleaningInputChange = (key: any, index: any, record: IManualCleaningTask, e: React.ChangeEvent<HTMLInputElement>) => {
    let updated = false;
    const newGroupData = manualCleaningTasks.map((item, idx) => {
      if (item.key !== record.key || updated)
        return {
          ...item,
        };
      updated = true;
      return {
        ...item,
        [key]: e.target.value,
      };
    });
    setManualCleaningTasks(newGroupData);
  }

  const onLightningRecordDelete = (record: ILightningTask) => {
    const newGroupData = lightningTasks.filter((item) => item.typeFloorFitting !== record.typeFloorFitting);
    setLightningTasks(newGroupData);
  }

  const onManualCleaningRecordDelete = (record: IManualCleaningTask) => {
    const newGroupData = manualCleaningTasks.filter((item) => item.asset !== record.asset);
    setManualCleaningTasks(newGroupData);
  }

  const onInputChange = (
    key: any,
    index: any,
    record: IRoomAsset,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    var frequencyId = assetCleaningTasks
      ?.find(x => x.id === record.assetCleaningTaskId)?.assetCleaningTaskFrequencies
      ?.find(x => x.frequency?.name === key)?.frequency?.id;
    if (buildingId == 214) {
      if (key.toLowerCase() === "basic") {
        frequencyId = 19
      }
      if (key.toLowerCase() === "optimal") {
        frequencyId = 20
      }
      if (key.toLowerCase() === "premium") {
        frequencyId = 21
      }
    }
    var roomAssetCleaningTasks = activeRoomData?.roomAssetCleaningTasks?.filter(x => x.assetCleaningTaskId === record.assetCleaningTaskId);
    for (let i = 0; i < roomAssetCleaningTasks!.length; i++) {
      roomAssetCleaningTasks![i].roomAssetCleaningTaskFrequencies!.find(x => x.frequencyId === frequencyId)!.value = +e.target.value;
    }
    let updated = false;
    const newGroupData = assetCleaningTasksData.map((item, idx) => {
      // if (index !== idx)
      if (item.assetCleaningTaskId !== record.assetCleaningTaskId || updated)
        return {
          ...item,
        };
      updated = true;
      return {
        ...item,
        [key]: e.target.value,
      };
    });
    setAssetCleaningTasksData(newGroupData);
  };

  const onCheckboxChange = (
    propertyKey: any,
    index: any,
    record: IRoomAsset,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newGroupData = assetCleaningTasksData.map((item, idx) => {
      if (item.key !== record.key)
        return {
          ...item,
        };
      return {
        ...item,
        [propertyKey]: e.target.checked,
      };
    });
    setAssetCleaningTasksData(newGroupData);
  };

  const findFrequencyValue = (
    assetCleaningTasks: IAssetCleaningTask[],
    record: IRoomAssetCleaningTaskExtended,
    frequencyKey: string,
    frequencyKey2?: string
  ) => {
    if (frequencyKey2) {
      let roomAssetCleaningTasks = [];
      for (let i = 0; i < selectedFloor!.rooms!.length; i++) {
        for (
          let j = 0;
          j < selectedFloor!.rooms![i].roomAssetCleaningTasks!.length;
          j++
        ) {
          roomAssetCleaningTasks.push(
            selectedFloor!.rooms![i].roomAssetCleaningTasks![j]
          );
        }
      }

      const filteredRoomAssetCleaningTask = roomAssetCleaningTasks?.find(
        (x) => x.id === record.roomAssetCleaningTaskId
      );

      const roomFrequency =
        filteredRoomAssetCleaningTask?.roomAssetCleaningTaskFrequencies?.find(
          (x) => x.frequencyId?.toString() === frequencyKey2
        )?.value ?? 0;

      if (roomFrequency != 0) {
        return roomFrequency;
      }
    }
    //
    // const findFRRating = (
    //     record: IRoomAssetCleaningTaskExtended
    // ) => {
    //   if (frequencyKey2) {
    //     let roomAssetCleaningTasks = [];
    //     for (let i = 0; i < selectedFloor!.rooms!.length; i++) {
    //       for (
    //           let j = 0;
    //           j < selectedFloor!.rooms![i].roomAssetCleaningTasks!.length;
    //           j++
    //       ) {
    //         roomAssetCleaningTasks.push(
    //             selectedFloor!.rooms![i].roomAssetCleaningTasks![j]
    //         );
    //       }
    //     }
    //
    //     const filteredRoomAssetCleaningTask = roomAssetCleaningTasks?.find(
    //         (x) => x.id === record.roomAssetCleaningTaskId
    //     );
    //    
    //     return filteredRoomAssetCleaningTask.frRating;
    //   }

    const assetCleaningTaskFrequency = assetCleaningTasks
      .find(x => x.id === record.assetCleaningTaskId)?.assetCleaningTaskFrequencies?.find(
        (f) => f.frequency?.name?.toLowerCase() === frequencyKey.toLowerCase()
      );
    const roomAssetCleaningTask = activeRoomData?.roomAssetCleaningTasks
      ?.find(x => x.id === record.roomAssetCleaningTaskId)?.roomAssetCleaningTaskFrequencies?.find(
        (f) => f.frequencyId === assetCleaningTaskFrequency?.frequency?.id
    );

    const frequency =
      +(record[frequencyKey.toLowerCase()]
      ?? roomAssetCleaningTask?.value
      ?? assetCleaningTaskFrequency?.value
      ?? 5);

    // const frequency =
    //   +(record[frequencyKey.toLowerCase()]
    //     ?? roomAssetCleaningTask?.roomAssetCleaningTaskFrequencies?.find(
    //       (f) => f.frequency?.name?.toLowerCase() === frequencyKey.toLowerCase()
    //     )?.value
    //     ?? assetCleaningTask?.assetCleaningTaskFrequencies?.find(
    //       (f) => f.frequency?.name?.toLowerCase() === frequencyKey.toLowerCase()
    //     )?.value
    //     ?? 5);

    // const filteredAssetCleaningTask = assetCleaningTasks.find(
    //   (x) =>
    //     x.asset?.id === record.assetId &&
    //     x.roomType?.id === record.roomTypeId &&
    //     x.cleaningTask?.id === record.cleaningTaskId &&
    //     x.assetSize === record.assetSize &&
    //     x.assetMaterial === record.assetMaterial
    // );
    // const frequency = +(record[frequencyKey.toLowerCase()] ??
    //   filteredAssetCleaningTask?.assetCleaningTaskFrequencies?.find(
    //     (f) => f.frequency?.name?.toLowerCase() === frequencyKey.toLowerCase()
    //   )?.value ?? 5);
    return frequency;
  };

  const handleSelectChange = (
    propertyKey: any,
    index: any,
    record: IRoomAssetCleaningTaskExtended,
    value: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newGroupData = assetCleaningTasksData.map((item, idx) => {
      if (record.roomAssetCleaningTaskId) {
        if (record.assetCleaningTaskId !== item.assetCleaningTaskId)
          // Return unchanged item
          return {
            ...item,
          };
        // Update existing roomAssetCleaningTask
        let updatedItem: IRoomAssetCleaningTaskExtended =
          handleValueChangeAndDependentDropdownValues(item, propertyKey, value);

        updatedItem.assetCleaningTaskId = getAssetCleaningTaskId(updatedItem);
        return updatedItem;
      } else {
        if (record.key !== item.key) {
          // Return unchanged item
          return {
            ...item,
          };
        }
        // Update new roomAssetCleaningTask
        let updatedItem: IRoomAssetCleaningTaskExtended =
          handleValueChangeAndDependentDropdownValues(item, propertyKey, value);

        updatedItem.assetCleaningTaskId = getAssetCleaningTaskId(updatedItem);
        return updatedItem;
      }
    });
    setAssetCleaningTasksData(newGroupData);
  };

  const handleValueChangeAndDependentDropdownValues: any = (
    item: IRoomAssetCleaningTaskExtended,
    key: any,
    value: React.ChangeEvent<HTMLSelectElement>
  ) => {
    switch (key) {
      case "assetId":
        return {
          ...item,
          assetId: Number(value),
          roomTypeId: null,
          cleaningTaskId: null,
          assetSize: null,
          assetMaterial: null,
        };
      case "roomTypeId":
        return {
          ...item,
          roomTypeId: Number(value),
          cleaningTaskId: setValueIfDependent(
            item,
            assetCleaningTasks,
            key,
            value,
            "cleaningTaskId"
          ),
          assetSize: setValueIfDependent(
            item,
            assetCleaningTasks,
            key,
            value,
            "assetSize"
          ),
          assetMaterial: setValueIfDependent(
            item,
            assetCleaningTasks,
            key,
            value,
            "assetMaterial"
          ),
        };
      case "cleaningTaskId": //TODO handle filter change
        return {
          ...item,
          cleaningTaskId: Number(value),
          assetSize: setValueIfDependent(
            item,
            assetCleaningTasks,
            key,
            value,
            "assetSize"
          ),
          assetMaterial: setValueIfDependent(
            item,
            assetCleaningTasks,
            key,
            value,
            "assetMaterial"
          ),
        };
      case "assetSize":
        return {
          ...item,
          cleaningTaskId: setValueIfDependent(
            item,
            assetCleaningTasks,
            key,
            value,
            "cleaningTaskId"
          ),
          assetSize: value,
          assetMaterial: setValueIfDependent(
            item,
            assetCleaningTasks,
            key,
            value,
            "assetMaterial"
          ),
        };
      case "assetMaterial":
        return {
          ...item,
          cleaningTaskId: setValueIfDependent(
            item,
            assetCleaningTasks,
            key,
            value,
            "cleaningTaskId"
          ),
          assetSize: setValueIfDependent(
            item,
            assetCleaningTasks,
            key,
            value,
            "assetSize"
          ),
          assetMaterial: value,
        };
      default:
        return {
          ...item,
          assetId: value,
          roomTypeId: null,
          cleaningTaskId: null,
          assetSize: null,
          assetMaterial: null,
        };
    }
  };

  const getAssetCleaningTaskId = (item: IRoomAssetCleaningTaskExtended) => {
    if (
      item.cleaningTaskId
    ) {
      var newAssetCleaningTask = assetCleaningTasks?.find((assetCleaningTask) =>
          assetCleaningTask.asset?.id === item.assetId &&
          assetCleaningTask.cleaningTask?.id === item.cleaningTaskId &&
          assetCleaningTask.isDefault);

      if (!newAssetCleaningTask) {
        return 0;
      }

      activeRoomData?.roomAssetCleaningTasks?.push(
        {
          assetCleaningTaskId: newAssetCleaningTask.id,
          id: 0,
          timingInSeconds: newAssetCleaningTask.timingInSeconds,
          roomAssetCleaningTaskFrequencies: newAssetCleaningTask.assetCleaningTaskFrequencies?.map(x => {
            return {
              id: 0,
              frequencyId: x.frequency?.id,
              value: x.value,
              serviceTypeId: x.serviceType?.id
            }
          })
        });
      return newAssetCleaningTask.id;
    }
    
    // if (
    //   item.assetId &&
    //   item.roomTypeId &&
    //   item.cleaningTaskId &&
    //   item.assetSize &&
    //   item.assetMaterial
    // ) {
    //   return assetCleaningTasks?.find((assetCleaningTask) => {
    //     return (
    //       assetCleaningTask.asset?.id === item.assetId &&
    //       assetCleaningTask.roomType?.id === item.roomTypeId &&
    //       assetCleaningTask.cleaningTask?.id === item.cleaningTaskId &&
    //       assetCleaningTask.assetSize === item.assetSize &&
    //       assetCleaningTask.assetMaterial === item.assetMaterial
    //     );
    //   })?.id;
    // }
    return 0;
  };

  const handleSaveAssetCleaningTasks = () => {
    const revertedGeometry = revertTransformGeometry(
      activeRoomData?.transformedGeometry
    );
    const mappedRoomData: UpdateRoomRequest = {
      id: activeRoomData?.id ?? 0,
      name: activeRoomData?.name ?? "",
      grossArea: activeRoomData?.grossArea ?? 0,
      voidArea: activeRoomData?.voidArea ?? 0,
      fixedFurnitureArea: activeRoomData?.fixedFurnitureArea ?? 0,
      ceilingHeight: activeRoomData?.ceilingHeight ?? 0,
      floorId: activeFloor?.id ?? 0,
      geoJsonGeometry: JSON.stringify(revertedGeometry),
      geometry: revertedGeometry,
      roomAssetCleaningTasks: assetCleaningTasksData.map((item) => {
        return {
          id: item.roomAssetCleaningTaskId,
          assetCleaningTaskId: item.assetCleaningTaskId,
          isPeriodic: item.isPeriodic,
          timingInSeconds: assetCleaningTasks?.find(x => x.id === item.assetCleaningTaskId)?.timingInSeconds,
          // timingInSeconds: activeRoomData?.roomAssetCleaningTasks
          //   ?.find(x => x.id === item.roomAssetCleaningTaskId)?.timingInSeconds
          //   ?? activeRoomData?.roomAssetCleaningTasks?.find(x => x.assetCleaningTaskId === item.assetCleaningTaskId)?.task?.timingInSeconds,
          roomAssetCleaningTaskFrequencies: activeRoomData?.roomAssetCleaningTasks
            ?.find(x => x.id === item.roomAssetCleaningTaskId)?.roomAssetCleaningTaskFrequencies
            ?? activeRoomData?.roomAssetCleaningTasks?.find(x => x.assetCleaningTaskId === item.assetCleaningTaskId)?.roomAssetCleaningTaskFrequencies?.map(x => { return { ...x, id: 0 } })
        };
      })
    };




    RoomsService.update(mappedRoomData!).then((response) => {
      if (response) {
        setAssetCleaningTasksModalOpen(false);
        setAssetCleaningTasksData([]);
        setActiveRoomData(null);
      }
    });
  };

  const onEditRoomInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const inputName = event.target.name ?? "color";
    const inputValue = event.target.value;
    setActiveRoomData((currentState) => {
      if (currentState == null) return null;
      return {
        ...currentState,
        [inputName]: inputValue,
      };
    });
  };

  const handleRoomDelete = useCallback(
    (id?: number | null) => {
      if (id) {
        RoomsService.remove(id).then((response) => {
          if (response) {
            const updatedRoomsData = roomsData?.filter((item) => {
              return item.id !== id;
            });
            setRoomsData(updatedRoomsData);
            setAssetCleaningTasksData([]);
            setActiveRoomData(null);
          }
        });
      }
    },
    [roomsData]
  );

  const handleEditRoomSave = () => {
    const mappedRoomData: UpdateRoomRequest = {
      id: activeRoomData?.id ?? 0,
      name: activeRoomData?.name ?? "",
      grossArea: activeRoomData?.grossArea ?? 0,
      voidArea: activeRoomData?.voidArea ?? 0,
      fixedFurnitureArea: activeRoomData?.fixedFurnitureArea ?? 0,
      ceilingHeight: activeRoomData?.ceilingHeight ?? 0,
      floorId: activeFloor?.id ?? 0,
      geoJsonGeometry: activeRoomData?.geoJsonGeometry,
      geometry: activeRoomData?.transformedGeometry,
      roomAssetCleaningTasks: assetCleaningTasksData?.map((item) => {
        return {
          id: item.roomAssetCleaningTaskId,
          assetCleaningTaskId: item.assetCleaningTaskId,
          isPeriodic: item.isPeriodic,
        };
      }),
    };
    if (activeRoomData?.id === 0) {
      RoomsService.create(mappedRoomData).then((response) => {
        if (response) {
          updateRoomsData(response.data);
        }
      });
    } else {
      RoomsService.update(mappedRoomData!).then((response) => {
        if (response) {
          updateRoomsData();
        }
      });
    }
  };

  const updateRoomsData = useCallback(
    (newRoomId: number = 0) => {
      if (activeRoomData) {
        const updatedRoomsData = roomsData?.map((room) => {
          if (room.id === activeRoomData.id) {
            return {
              ...room,
              id: newRoomId ? newRoomId : activeRoomData.id,
              name: activeRoomData.name,
              color: activeRoomData.color,
              voidArea: activeRoomData.voidArea,
              fixedFurnitureArea: activeRoomData.fixedFurnitureArea,
              grossArea: activeRoomData.grossArea,
              ceilingHeight: activeRoomData.ceilingHeight,
              transformedGeometry: activeRoomData.transformedGeometry,
              geoJsonGeometry: activeRoomData.geoJsonGeometry,
            };
          }
          return room;
        });
        setRoomsData(updatedRoomsData);
        if (activeRoomData.id === 0)
          setActiveRoomData({
            ...activeRoomData,
            id: newRoomId,
          });
      }
      setIsEditRoomModalOpen(false);
    },
    [activeRoomData, roomsData]
  );

  const handleEditRoomCancel = () => {
    if (activeRoomData?.id === 0)
      setRoomsData(roomsData?.filter((room) => room.id !== 0));

    setIsEditRoomModalOpen(false);
  };

  const handleSetActiveFloorplan = (floorId: number) => {
    handleActiveFloorChange(floorId);
    setActiveFloor(floors?.find((floor) => floor.id === floorId));
  };

  return (
    <div
      ref={containerRef}
      className="room-mapping-container site-drawer-render-in-current-wrapper"
    >
      {/* {roomsData && (
        <Drawer
          key="roomList"
          title="Room list"
          placement={placement}
          onClose={closeRoomList}
          closeIcon={
            <div className={styles.drawerClose}>
              <CloseOutlined />
            </div>
          }
          headerStyle={{ flexDirection: "row-reverse" }}
          open={roomListOpen}
          getContainer={false}
          mask={false}
          zIndex={1001}
          className={styles.drawerContainer}
        >
          <label>Select a room to show item list</label>
          <ul className={styles.drawerList}>
            {roomsData.map((room: IRoom, index: number) => (
              <li key={index}>
                <span>{`${index + 1}.`}</span>
                <span>
                  {
                    roomTypeData?.find(
                      (type) => type.id?.toString() === room.roomType
                    )?.name
                  }
                </span>
              </li>
            ))}
          </ul>
        </Drawer>
      )} */}
      {activeFloor && (
        <div
          className={classNames(
            styles.topControls,
            roomListOpen || oZonesOpen ? styles.drawerOpen : ""
          )}
        >
          <div style={{ alignItems: "flex-start" }}>
            {config.floorSelect && floors && (
              <Select
                options={floors.map((x: IFloor) => ({
                  value: x.id,
                  label: x.name,
                }))}
                onChange={handleSetActiveFloorplan}
                value={activeFloor?.id ?? 0}
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "200px",
                }}
              ></Select>
            )}

            {/* <Button
            onClick={showRoomList}
            className={roomListOpen ? styles.activeButton : ""}
          >
            <UnorderedListOutlined />
            <label>Show list of rooms</label>
          </Button> */}
            <Button
              onClick={() => buildingId === "251" ? openListOfItems(true, "Existing") : openListOfItems(true)}
              className={roomListOpen ? styles.activeButton : ""}
            >
              <BorderHorizontalOutlined />
              {buildingId === "251" && (
                <label>Show Lightning spec</label>
              )}
              {buildingId !== "251" && (
                <label>Show Cleaning spec</label>
              )}
            </Button>
            {config.enableVirtualWalk && (
              <Button
                onClick={() => {
                  setActiveRoomData(null);
                  setVirtualWalkModalOpen(true);
                }}
              >
                <BorderHorizontalOutlined />
                <label>Virtual walk</label>
              </Button>
            )}

            {(buildingId == 73 || buildingId == 217) ?? ( // TODO: Not per building, this is applicable for healthcare
                <Button>
                  <BorderHorizontalOutlined />
                  <label style={{ marginRight: 10 }}>Show Departments only</label>
                  <Switch
                      defaultChecked={(buildingId == 73 || buildingId == 217) ? true : false}
                      checked={showDepartmentsOnly}
                      onChange={() => setShowDepartmentsOnly(!showDepartmentsOnly)}
                  />
                </Button>  
            )}
          </div>
          <div>
            {/* <Button onClick={() => setIsWalkModalOpen(true)}>
            <Icon component={WalkingSvgIcon} />
            <label>Walking time</label>
          </Button> */}
          </div>
        </div>
      )}
      {mapContainerRef &&
        roomsData &&
        activeFloor &&
        containerBounds &&
        bounds && (
          <div
            className={classNames(
              styles.wrapper,
              config.minified ? styles.minified : ""
            )}
          >
            <MapContainer
              ref={mapContainerRef}
              bounds={containerBounds}
              maxBounds={containerBounds}
              dragging={true}
              center={calculateCenterOfMap(bounds)}
              zoom={config.minZoom ?? (buildingId == 261 || buildingId == 262 ? 6 : 4)}
              maxZoom={config.minZoom ? config.minZoom + 5 : 9}
              minZoom={-5}
              scrollWheelZoom={false}
              zoomSnap={0}
              zoomControl={false}
              crs={CRS.Simple}
              className={styles.leafletContainer}
            >
              <ImageOverlay
                url={activeFloor.imageComponent}
                bounds={latLngBounds(bounds)}
                zIndex={100}
                className={styles.imageOverlay}
              />
              <Geoman
                floorId={activeFloor.level ?? 0}
                editable={config.editable}
                rooms={[...roomsData] ?? []}
                handleActiveRoom={handleActiveRoom}
                handleCreateRoom={handleCreateRoom}
                handleRoomDelete={handleRoomDelete}
                handleUpdateRoom={handleUpdateRoomGeometry}
              />
              <ZoomControl position="bottomright" />
              {roomTooltipActive && activeRoomData && <FeatureTooltip />}
            </MapContainer>
          </div>
        )}
      <Modal
        title="Edit Room"
        open={isEditRoomModalOpen}
        onOk={handleEditRoomSave}
        onCancel={handleEditRoomCancel}
      >
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Space className={styles.modalRow}>
            <Space style={{ gap: "4px", width: "100%" }} direction="vertical">
              <label>Name:</label>
              <Input
                name="name"
                placeholder="Enter name"
                value={activeRoomData?.name ?? ""}
                onChange={onEditRoomInputChange}
              />
            </Space>
            <Space style={{ gap: "4px", width: "100%" }} direction="vertical">
              <label>Gross Area:</label>
              <Input
                name="grossArea"
                placeholder="Enter gross area"
                value={activeRoomData?.grossArea ?? 0}
                onChange={onEditRoomInputChange}
                type="number"
              />
            </Space>
          </Space>
          <Space className={styles.modalRow}>
            <Space style={{ gap: "4px", width: "100%" }} direction="vertical">
              <label>Void Area:</label>
              <Input
                name="voidArea"
                placeholder="Enter void area"
                value={activeRoomData?.voidArea ?? 0}
                onChange={onEditRoomInputChange}
                type="number"
              />
            </Space>
            <Space style={{ gap: "4px", width: "100%" }} direction="vertical">
              <label>Fixed Furniture Area:</label>
              <Input
                name="fixedFurnitureArea"
                placeholder="Enter fixed furniture area"
                value={activeRoomData?.fixedFurnitureArea ?? 0}
                onChange={onEditRoomInputChange}
                type="number"
              />
            </Space>
          </Space>
          <Space className={styles.modalRow} size="middle" align="start">
            <Space style={{ gap: "4px", width: "100%" }} direction="vertical">
              <HexColorPicker
                color={activeRoomData?.color ?? "#000000"}
                onChange={(value) =>
                  setActiveRoomData((currentState) => {
                    if (currentState == null) return currentState;
                    return { ...currentState, color: value };
                  })
                }
              />
            </Space>
            <Space style={{ gap: "4px", width: "100%" }} direction="vertical">
              <label>Ceiling Height:</label>
              <Input
                name="ceilingHeight"
                placeholder="Enter ceiling height"
                value={activeRoomData?.ceilingHeight ?? 0}
                onChange={onEditRoomInputChange}
                type="number"
              />
            </Space>
          </Space>
        </Space>
      </Modal>
      {activeFloor && roomsData && (
        <Modal
          style={{
            left: roomListOpen || oZonesOpen ? "calc(50% - 155px)" : "unset",
            transform:
              roomListOpen || oZonesOpen ? "translateX(-50%)" : "unset",
          }}
          className={styles.itemsModal}
          bodyStyle={{ maxHeight: "65vh" }}
          title={
            <div className={styles.itemsModalHead}>
              <span>
                {roomsData?.find((r) => r.id === activeRoomData?.id)?.name ??
                  "Floor items"}
              </span>
              {config.enableVirtualWalk && (
                <Button
                  onClick={() => {
                    setAssetCleaningTasksModalOpen(false);
                    !activeRoomData?.name && setActiveRoomData(null);
                    setVirtualWalkModalOpen(true);
                  }}
                  style={{
                    backgroundColor: "#202511",
                    color: "#ffffff",
                    fontSize: "14px",
                    lineHeight: "1.57",
                    marginRight: "32px",
                  }}
                >
                  <BorderHorizontalOutlined />
                  <span>Virtual walk</span>
                </Button>
              )}
            </div>
          }
          centered
          open={assetCleaningTasksModalOpen}
          onCancel={() => setAssetCleaningTasksModalOpen(false)}
          footer={
            <div className={styles.modalFooterContainer}>
              <div style={{ width: "100%" }}>
                {hasUnsavedChanges(roomsData, assetCleaningTasksData) ? (
                  <Popconfirm
                    title="Please fill all the fields before saving."
                    icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                    showCancel={false}
                    okType="danger"
                  >
                    <Button
                      key="back"
                      onClick={() => setAssetCleaningTasksModalOpen(false)}
                    >
                      Cancel
                    </Button>
                  </Popconfirm>
                ) : (
                  <Button
                    key="back"
                    onClick={() => setAssetCleaningTasksModalOpen(false)}
                  >
                    Cancel
                  </Button>
                )}
                {hasEmptyFields(assetCleaningTasksData) ? (
                  <Popconfirm
                    title="Please fill all the fields before saving."
                    icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                    showCancel={false}
                    okType="danger"
                  >
                    <Button
                      key="submit"
                      type="primary"
                      style={{ boxShadow: "none!important" }}
                    >
                      Set
                    </Button>
                  </Popconfirm>
                ) : (
                  <Button
                    key="submit"
                    type="primary"
                    style={{ boxShadow: "none!important" }}
                    onClick={() => handleSaveAssetCleaningTasks()}
                  >
                    Set
                  </Button>
                )}
              </div>
            </div>
          }
        >
          <div className={styles.modalContainer}>
            <div className={styles.tableHeader}>
              <div className={styles.gridRow} style={{display: 'none'}}>
                {/* <div className={styles.gridItem}>Unit</div>
                <div className={styles.gridItem}></div> */}
                {/* <div className={styles.gridItem}>Units</div> */}
                {/* <div className={styles.gridItem}></div>
                <div className={styles.gridItem}></div>
                <div className={styles.gridItem}></div> */}
                {/* <div className={styles.gridItem}>Weekly frequency</div> */}
                {/* <div className={styles.gridItem}></div> */}
                <div className={styles.gridItem}></div>
                <div className={styles.gridItem}></div>
                {/* <div className={styles.gridItem}></div> */}
                <div className={styles.gridItem}></div>
              </div>
              <div className={styles.gridRow}>
                {/* <div className={styles.gridItem}></div>
                <div className={styles.gridItem}></div> */}
                {/* <div className={styles.gridItem}>Room Type</div> */}
                {(buildingId == 217 || buildingId == 102) && (
                    <div className={styles.gridItem} style={{ width: "3%" }}>
                      Audit
                    </div>
                )}
                {(buildingId != 73 && buildingId != 217 && buildingId != 251 && buildingId != 249) && (
                  <div className={styles.gridItem} style={{ width: "12%" }}>
                    Asset
                  </div>
                )}
                {(buildingId != 261 && buildingId != 251 && buildingId != 249) && (
                  <div
                    className={styles.gridItem}
                    style={(buildingId != 73 && buildingId != 217 && buildingId != 251 && buildingId != 249) ? { width: "36%" } : {width: "33%"}}
                  >
                    Task
                  </div>
                )}
                {(buildingId == 261) && (
                    <div
                        className={styles.gridItem}
                        style={{width: "35%"}}
                    >
                      Task
                    </div>
                )}
                {(buildingId == 251) && (
                  // Lightning DEMO
                  <>
                    <div
                      className={styles.gridItem}
                      style={{width: "7%"}}
                    >
                      Type
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "7%"}}
                    >
                      Description
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "5%"}}
                    >
                      Qty
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "5%"}}
                    >
                      Power (W)
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "7%"}}
                    >
                      Run Hours Per Week
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "5%"}}
                    >
                      KWh per Year
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "6%"}}
                    >
                      Cost Year 1
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "6%"}}
                    >
                      Cost Year 2
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "6%"}}
                    >
                      Cost Year 3
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "6%"}}
                    >
                      Cost Year 4
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "6%"}}
                    >
                      Cost Year 5
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "6%"}}
                    >
                      Total 5 Years
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "7%"}}
                    >
                      CO2 per Year tonnes
                    </div>
                  </>
                )}
                {(buildingId == 249) && (
                  // Cleaning Manual Demo
                  <>
                    <div className={styles.gridItem} style={{ width: "3%" }}>
                      Audit
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "12%"}}
                    >
                      Asset
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "12%"}}
                    >
                      Description
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "8%"}}
                    >
                      Basic
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "8%"}}
                    >
                      Optimal
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "8%"}}
                    >
                      Premium
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "8%"}}
                    >
                      Quantity
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "8%"}}
                    >
                      Total floor space
                    </div>
                    <div
                      className={styles.gridItem}
                      style={{width: "10%"}}
                    >
                      Floor surface type
                    </div>
                  </>
                )}
                {/* <div className={styles.gridItem}>Size</div> */}
                {/* <div className={styles.gridItem}>Material</div> */}
                {/* <div className={styles.gridItem}>P?</div> */}
                {(buildingId == 73 || buildingId == 217) && (
                  <>
                    <div className={styles.gridItem}>
                      {(buildingId == 73 || buildingId == 217) ? "NHS" : "Basic"}
                    </div>
                    <div className={styles.gridItem}>
                      {(buildingId == 73 || buildingId == 217) ? "2007" : "Optimal"}
                    </div>
                    <div className={styles.gridItem}>
                      {(buildingId == 73 || buildingId == 217) ? "2021" : "Premium"}
                    </div>
                  </>
                  )}
                {(buildingId == 261) && (
                    <>
                      <div className={styles.gridItem} style={{width: '12%'}}>
                        Weekly frequency
                      </div>
                      <div className={styles.gridItem} style={{width: '13%'}}>
                        FR Rating
                      </div>
                      <div className={styles.gridItem}>
                        FR Frequency
                      </div>
                    </>
                )}
                {/* <div className={styles.gridItem}>Custom</div> */}
                <div className={styles.gridItem}></div>
              </div>
            </div>
            <Table
              className={classNames(
                "items-grouped-table",
                styles.itemsGroupedTable
              )}
              title={() => (
                <>
                  <Space
                    size="small"
                    style={{
                      width: "100%",
                      justifyContent: "flex-end",
                      gap: "16px",
                    }}
                  >
                    <Button
                      type="primary"
                      size="small"
                      onClick={() => 
                        buildingId == 251 ? handleAddNewLightningTask() : 
                        buildingId == 249 ? handleAddNewManualCleaningTask() : 
                        handleAddNew()}
                    >
                      <PlusOutlined /> Add new
                    </Button>
                  </Space>
                </>
              )}
              size="small"
              columns={
                (buildingId == 73)
                  ? NHSRoomAssetCleaningTaskColumns
                  : (buildingId == 217) ? AIGNHSRoomAssetCleaningTaskColumns 
                  : (buildingId == 251) ? lightningRoomAssetCleaningTaskColumns
                  : (buildingId == 261) ? DewsburyRoomAssetCleaningTaskColumns
                  : (buildingId == 249) ? manualCleaningTaskColumns 
                  : roomAssetCleaningTaskColumns.filter((x:any) => x.visible)
              }
              // columns={buildilightningRoomAssetCleaningTaskColumns}
              // dataSource={getDistinctAssetCleaningTasks(
              //   assetCleaningTasksData
              // ).sort((a, b) => sortByNumberProperty(a.key, b.key))}
              dataSource={
                buildingId == 251 ? lightningTasks : 
                  buildingId == 249 ? manualCleaningTasks : getDistinctAssetCleaningTasks(
                  assetCleaningTasksData
                ).sort((a, b) => sortByNumberProperty(a.key, b.key))}
              showHeader={false}
              rowKey="key"
            />
            {/* {(buildingId == 251) && (
                <div style={{width: "100%", textAlign: 'end'}}>
                  <div>Total: {lightningTasks.reduce((partialSum:any, a:any) => partialSum + (a.quantity * a.costPerUnit), 0)} £</div>
                </div>
              )} */}
          </div>
        </Modal>
      )}
      {config.enableVirtualWalk && matterportLink && (
        <Modal
          title={<TitlePlaceholder />}
          open={virtualWalkModalOpen}
          width="1200px"
          centered
          onCancel={() => setVirtualWalkModalOpen(false)}
          footer={null}
          style={{ maxWidth: "90%", maxHeight: "90vh", height: "720px" }}
        >
          <div>
            <div>
              <iframe
                title="Matterport scan"
                src={matterportLink}
                allowFullScreen
                allow="xr-spatial-tracking"
                style={{ width: "100%", height: "720px", maxHeight: "80vh" }}
              ></iframe>
            </div>
          </div>
        </Modal>
      )}
      {/* <Modal
        title={<TitlePlaceholder />}
        open={isWalkModalOpen}
        width="60vw"
        bodyStyle={{
          height: "60vh",
        }}
        centered
        onCancel={() => setIsWalkModalOpen(false)}
        footer={null}
      >
        <iframe
          title="Walking time"
          src="https://scanquoplatform.blob.core.windows.net/videos/app/scanquo_walking_time_example.mp4"
          width="100%"
          height="100%"
          style={{ border: "none" }}
          allow="autoplay; fullscreen; picture-in-picture muted"
          allowFullScreen
        />
      </Modal> */}
    </div>
  );
};

const groupAssetsByDistinctProperties = (assets: IRoomAsset[]) => {
  let keyValue = 1;
  if (!assets) return [];
  const distinctAssets = assets.reduce(
    (newDistinctAssets: IRoomAsset[], current: IRoomAsset) => {
      if (
        !newDistinctAssets.some(
          (x) =>
            x.assetId === current.assetId &&
            x.roomTypeId === current.roomTypeId &&
            x.cleaningTaskId === current.cleaningTaskId &&
            x.assetSize === current.assetSize &&
            x.assetMaterial === current.assetMaterial
        )
      ) {
        current.quantity = current.quantity || 1;
        current.type = current.asset?.name ?? "-";
        current.key = keyValue++;
        current.isNew = false;
        newDistinctAssets.push(current);
      } else {
        const existingAsset = newDistinctAssets.find(
          (x) =>
            x.assetId === current.assetId &&
            x.roomTypeId === current.roomTypeId &&
            x.cleaningTaskId === current.cleaningTaskId &&
            x.assetSize === current.assetSize &&
            x.assetMaterial === current.assetMaterial
        );
        if (existingAsset)
          existingAsset.quantity = existingAsset.quantity + 1 || 1;
      }
      return newDistinctAssets;
    },
    []
  );
  return distinctAssets;
};

const calculateMapBounds = (
  coordinates: any[] | undefined,
  floorplanImgSize: ImageSize,
  activeFloor: any
) => {
  if (!coordinates || coordinates.length === 0)
    return [
      [
        (-1 * floorplanImgSize.height) / 124,
        (-1 * floorplanImgSize.width) / 124,
      ],
      [floorplanImgSize.height / 124, floorplanImgSize.width / 124],
    ];
  let minX = Infinity;
  let minY = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;
  coordinates.forEach((coords: number[]) => {
    minX = Math.min(minX, coords[0]);
    minY = Math.min(minY, coords[1]);
    maxX = Math.max(maxX, coords[0]);
    maxY = Math.max(maxY, coords[1]);
  });
  // minX = -1;
  // minY = -1;
  // maxX = 100;
  // maxY = 100;

  if (activeFloor.id == 302) {
    maxX = 17.8;
    maxY = 9.155;
    minX = -21.88125;
    minY = -14.83481;
  }
  if (activeFloor.id == 303) {
    maxX = 19.1;
    maxY = 10.155;
    minX = -21.28125;
    minY = -8.83481;
  }
  if (activeFloor.id == 304) {
    maxX = 17.89;
    maxY = 10.155;
    minX = -21.28125;
    minY = -9.153481;
  }

  return [
    [minY, minX],
    [maxY, maxX],
  ];
};

const calculateCenterOfMap: any = (bounds: any[] | undefined) => {
  if (!bounds) return [0, 0];
  const firstSet = bounds[0];
  const secondSet = bounds[1];
  const center = [
    (firstSet[0] + secondSet[0]) / 2,
    (firstSet[1] + secondSet[1]) / 2,
  ];

  return center;
};

const setValueIfDependent = (
  record: IRoomAssetCleaningTaskExtended,
  assetCleaningTasks: IAssetCleaningTask[] | null | undefined,
  editKey: string,
  value: any,
  propertyKey: string
) => {
  if (record.assetId)
    assetCleaningTasks = assetCleaningTasks?.filter(
      (x) => x.asset?.id === record.assetId
    );
  if (record.roomTypeId || editKey === "roomTypeId")
    assetCleaningTasks = assetCleaningTasks?.filter(
      editKey === "roomTypeId"
        ? (x) => x.roomType?.id === Number(value)
        : (x) => x.roomType?.id === Number(record.roomTypeId)
    );
  if (
    (record.cleaningTaskId || editKey === "cleaningTaskId") &&
    editKey !== "roomTypeId"
  )
    assetCleaningTasks = assetCleaningTasks?.filter(
      editKey === "cleaningTaskId"
        ? (x) => x.cleaningTask?.id === Number(value)
        : (x) => x.cleaningTask?.id === Number(record.cleaningTaskId)
    );
  if ((record.assetSize || editKey === "assetSize") && editKey !== "roomTypeId")
    assetCleaningTasks = assetCleaningTasks?.filter(
      editKey === "assetSize"
        ? (x) => x.assetSize === value
        : (x) => x.assetSize === record.assetSize
    );
  if (
    (record.assetMaterial || editKey === "assetMaterial") &&
    editKey !== "roomTypeId"
  )
    assetCleaningTasks = assetCleaningTasks?.filter(
      editKey === "assetMaterial"
        ? (x) => x.assetMaterial === value
        : (x) => x.assetMaterial === record.assetMaterial
    );

  if (
    assetCleaningTasks?.some((x) => {
      if (propertyKey === "cleaningTaskId") {
        const cleaningTask = x.cleaningTask;

        return cleaningTask?.id === record[propertyKey];
      } else return x[propertyKey] === record[propertyKey];
    })
  )
    return record[propertyKey];
  else return null;
};

const hasEmptyFields = (assetsData: IRoomAssetCleaningTaskExtended[]) => {
  return assetsData.some(
    (x) =>
      !x.assetCleaningTaskId
  );
};

// const hasEmptyFields = (assetsData: IRoomAssetCleaningTaskExtended[]) => {
//   return assetsData.some(
//     (x) =>
//       !x.assetId ||
//       !x.roomTypeId ||
//       !x.cleaningTaskId ||
//       !x.assetSize ||
//       !x.assetMaterial
//   );
// };

const hasUnsavedChanges = (
  originalData: IRoom[],
  editedData: IRoomAssetCleaningTask[]
) => {
  return false;
};

const getDistinctAssetCleaningTasks = (
  items: IRoomAssetCleaningTaskExtended[]
) => {
  const distinctItems = items
    .sort((a, b) => sortByNumberProperty(a.key, b.key))
    .reduce((acc: IRoomAssetCleaningTaskExtended[], item) => {
      const existingItem = acc.find(
        (x) =>
          (item.assetCleaningTaskId &&
            x.assetCleaningTaskId === item.assetCleaningTaskId) ||
          (x.assetId === item.assetId &&
            x.roomTypeId === item.roomTypeId &&
            x.cleaningTaskId === item.cleaningTaskId &&
            x.assetSize === item.assetSize &&
            x.assetMaterial === item.assetMaterial)
      );
      if (!existingItem) {
        acc.push(item);
      }
      return acc;
    }, []);

  return distinctItems;
};

const calculateAssetCleaningTaskQuantity = (
  assetCleaningTasksArray: IRoomAssetCleaningTaskExtended[],
  roomAssetCleaningTaskRecord: IRoomAssetCleaningTaskExtended
) => {
  const assetCleaningTasks = assetCleaningTasksArray.filter(
    (x) =>
      (roomAssetCleaningTaskRecord.assetCleaningTaskId &&
        x.assetCleaningTaskId ===
          roomAssetCleaningTaskRecord.assetCleaningTaskId) ||
      (x.assetId === roomAssetCleaningTaskRecord.assetId &&
        x.roomTypeId === roomAssetCleaningTaskRecord.roomTypeId &&
        x.cleaningTaskId === roomAssetCleaningTaskRecord.cleaningTaskId &&
        x.assetSize === roomAssetCleaningTaskRecord.assetSize &&
        x.assetMaterial === roomAssetCleaningTaskRecord.assetMaterial)
  );
  return assetCleaningTasks.length ?? 0;
};

const calculateLightningRecordTotalCost = (
  record: ILightningTask,
) => {
  return record.qty! * record.runHoursPerWeek!;
};

const dynamicDropdownFiltering = (
  propertyKey: string,
  record: IRoomAsset,
  assetCleaningTasks: IAssetCleaningTask[] | null | undefined
) => {
  if (record.assetId)
    assetCleaningTasks = assetCleaningTasks?.filter(
      (x) => x.asset?.id === record.assetId
    );
  if (record.roomTypeId && propertyKey !== "roomTypeId")
    assetCleaningTasks = assetCleaningTasks?.filter(
      (x) => x.roomType?.id === Number(record.roomTypeId)
    );
  if (record.cleaningTaskId && propertyKey !== "cleaningTaskId")
    assetCleaningTasks = assetCleaningTasks?.filter(
      (x) => x.cleaningTask?.id === Number(record.cleaningTaskId)
    );
  if (record.assetSize && propertyKey !== "assetSize")
    assetCleaningTasks = assetCleaningTasks?.filter(
      (x) => x.assetSize === record.assetSize
    );
  if (record.assetMaterial && propertyKey !== "assetMaterial")
    assetCleaningTasks = assetCleaningTasks?.filter(
      (x) => x.assetMaterial === record.assetMaterial
    );

  // Return distinct values for dropdown
  switch (propertyKey) {
    case "roomTypeId":
      return assetCleaningTasks?.map((item) => {
        return {
          value: item.roomType?.id?.toString(),
          label: item.roomType?.name,
        };
      });
    case "cleaningTaskId":
      return assetCleaningTasks?.map((item) => {
        return {
          value: item.cleaningTask?.id?.toString(),
          label: item.cleaningTask?.name,
        };
      });
    case "assetSize":
      return assetCleaningTasks?.map((item) => {
        return {
          value: item.assetSize,
          label: item.assetSize,
        };
      });
    case "assetMaterial":
      return assetCleaningTasks?.map((item) => {
        return {
          value: item.assetMaterial,
          label: item.assetMaterial,
        };
      });
    default:
      return [];
  }
};
