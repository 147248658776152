export const DATE_FORMAT = "DD.MM.YYYY.";
export const DATE_TIME_FORMAT_LONG = "DD.MM.YYYY. HH:mm:ss";
export const DATE_TIME_FORMAT_SHORT = "DD.MM.YYYY. HH:mm";
export const TIME_FORMAT_SHORT = "HH:mm";
export const ROLE = {
  Admin: "Admin",
  User: "User",
};
export const AUTH_KEY = "authData";
export const TOKEN_LIFETIME = 60;
export const INPUT_TYPE = {
  Checkbox: "checkbox",
  Text: "text",
  Select: "select-one",
};

export const SECTIONS = {
  general: {
    id: 0,
    name: "Home",
  },
};

export const ROUTES = {
  general: {
    main: {
      name: "Dashboard",
      route: "/",
    },
    import: {
      name: "Import",
      route: "/import",
    },
    importSuccess: {
      name: "Import Success",
      route: "/import-success",
    },
    floorMapping: {
      name: "Floor Mapping",
      route: "/floor-mapping",
    },
    adminRegions: {
      name: "Regions",
      route: "/admin/regions",
    },
    adminCountries: {
      name: "Countries",
      route: "/admin/countries",
    },
    adminBuildingTypes: {
      name: "Building Types",
      route: "/admin/building-types",
    },
    adminRoomTypes: {
      name: "Room Types",
      route: "/admin/room-types",
    },
    adminItemMasterlist: {
      name: "Item Masterlist",
      route: "/admin/item-masterlist",
    },
    managementBuildings: {
      name: "Buildings",
      route: "/buildings",
    },
    adminFrequencies: {
      name: "Frequencies",
      route: "/admin/frequencies",
    },
    adminCleaningTasks: {
      name: "Cleaning Tasks",
      route: "/admin/cleaning-tasks",
    },
    adminVerticals: {
      name: "Verticals",
      route: "/admin/verticals",
    },
    login: {
      name: "Login",
      route: "/login",
    },
  },
  special: {
    login: {
      name: "Login",
      route: "/login",
    },
  },
  benchmark: {
    // homepage: {
    //     name: "Homepage",
    //     route: "/:buildingId/home"
    // },
    welcome: {
      name: "Welcome",
      baseRoute: "/benchmark/:buildingId",
      route: "welcome",
      getRoute: (buildingId?: number | string) => {
        return `/benchmark/${buildingId}/welcome`;
      },
    },
    executiveSummary: {
      name: "Executive Summary",
      baseRoute: "/benchmark/:buildingId",
      route: "executive-summary",
      getRoute: (buildingId?: number | string) => {
        return `/benchmark/${buildingId}/executive-summary`;
      },
    },
    // scanningBenefits: {
    //     name: "Scanning Benefits",
    //     route: "/:buildingId/scanning-benefits"
    // },
    benchmarkResults: {
      name: "Benchmark Results",
      baseRoute: "/benchmark/:buildingId",
      route: "benchmark-results",
      getRoute: (buildingId?: number | string) => {
        return `/benchmark/${buildingId}/benchmark-results`;
      },
      tabs: {
        totalSummary: {
          name: "Total summary",
        },
        costPerSqFt: {
          name: "Cost per area",
        },
      },
    },
    floorMapping: {
      name: "Floor Mapping",
      baseRoute: "/benchmark/:buildingId",
      route: "floor-mapping",
      getRoute: (buildingId?: number | string) => {
        return `/benchmark/${buildingId}/floor-mapping`;
      },
    },
    eveningCleaning: {
      name: "Daily Cleaning", //TODO make dynamic
      baseRoute: "/benchmark/:buildingId",
      route: "evening-cleaning",
      getRoute: (buildingId?: number | string) => {
        return `/benchmark/${buildingId}/evening-cleaning`;
      },
    },
    daytimeJanitor: {
      name: "Daytime Janitor",
      baseRoute: "/benchmark/:buildingId",
      route: "daytime-janitor",
      getRoute: (buildingId?: number | string) => {
        return `/benchmark/${buildingId}/daytime-janitor`;
      },
    },
    operationalManagement: {
        name: "Operational Management",
      baseRoute: "/benchmark/:buildingId",
      route: "operational-management",
      getRoute: (buildingId?: number | string) => {
        return `/benchmark/${buildingId}/operational-management`;
      },
    },
    periodicCleaning: {
      name: "Periodic Cleaning",
      baseRoute: "/benchmark/:buildingId",
      route: "periodic-cleaning",
      getRoute: (buildingId?: number | string) => {
        return `/benchmark/${buildingId}/periodic-cleaning`;
      },
    },
    roboticVacuuming: {
      name: "Robotic Vacuuming",
      baseRoute: "/benchmark/:buildingId",
      route: "robotic-vacuuming",
      getRoute: (buildingId?: number | string) => {
        return `/benchmark/${buildingId}/robotic-vacuuming`;
      },
    },
  },
};

export const HTTPCODES = {
  Ok: 200,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  NotFound: 404,
  InternalServerError: 500,
  BadGateway: 502,
  ServiceUnavailable: 503,
};
