import {
  Button,
  Dropdown,
  Input,
  MenuProps,
  Modal, notification,
  Radio,
  RadioChangeEvent, Select, Spin,
  Table,
  Tabs,
} from "antd";
import { useEffect, useState } from "react";
import styles from "./BenchmarkResults.module.scss";
import {
  QuestionCircleOutlined,
  MoreOutlined,
  SearchOutlined,
  InfoCircleOutlined,
  ToolOutlined,
  FieldTimeOutlined,
  WalletOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import classNames from "classnames";
import TotalSummary from "./components/TotalSummary";
import CostPerSqFt from "./components/CostPerSqFt";
import TitlePlaceholder from "../../../platform/shared/modal/TitlePlaceholder";
import "./BenchmarkResults.css";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useParams, useSearchParams } from "react-router-dom";
import { actions, getBenchmark, getFRRatings } from "../../../../store/benchmark";
import { ROUTES } from "../../../../config/constants";
import { IDashboard } from "../../../../models/benchmark/Benchmark";
import Dashboard from "../../shared/dashboard/Dashboard";
import {
  IEveningCleaningTemplate,
  IOverheadTemplate,
  IStandardCleaningTemplate,
} from "../../../../models/buildings/UpdateBuildingRequest";
import StandardCleaningTemplatesService from "../../../../services/standardcleaningtemplates/StandardCleaningTemplates";
import OverheadTemplatesService from "../../../../services/overheadtemplates/OverheadTemplates";
import EveningCleaningTemplatesService from "../../../../services/eveningcleaningtemplates/EveningCleaningTemplates";
import { DataType } from "../../../../models/benchmark/shared/Table";
import BuildingsService from "../../../../services/buildings/BuildingsService";

enum AdjustmentKey {
  UNDEFINED = 0,
  EQUIPMENT = 1,
  SETUP_TIME = 2,
  OVERHEAD_COSTS = 3,
  PRODUCTIVITY_RATE = 4,
  WAGES = 5,
  FR_RATINGS = 6,
}

interface ISetupTime {
  id: number;
  setupTimeInMinutes: number;
  finishTimeInMinutes: number;
}

const BenchmarkResults = () => {
  const [dispatched, setDispatched] = useState<boolean>(false);

  const [activeTabKey, setActiveTabKey] = useState<string>("0");
  const [dashboardTabs, setDashboardTabs] = useState<IDashboard[]>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isOverheadModalOpen, setIsOverheadModalOpen] =
    useState<boolean>(false);
  const [isSetupTimeModalOpen, setIsSetupTimeModalOpen] =
    useState<boolean>(false);
  const [activeAdjustment, setActiveAdjustment] = useState<AdjustmentKey>(0);
  const [isWalkModalOpen, setIsWalkModalOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { benchmark, pending } = useAppSelector((state) => state.benchmark);
  
  const [building, setBuilding] = useState<any>();
  const [frRatings, setFrRatings] = useState<any>();

  const [overheadCosts, setOverheadCosts] = useState<IOverheadTemplate | null>({
    id: 0,
    tenantId: 0,
    holidayBonusPercentage: 0,
    insurancePercentage: 0,
    employerNIPercentage: 0,
    pensionPercentage: 0,
    cleaningMaterialsPercentage: 0,
    equipmentPercentage: 0,
    managementPercentage: 0,
    profitsPercentage: 0,
  });

  const [wages, setWages] = useState<IEveningCleaningTemplate | null>({
    id: 0,
    tenantId: 0,
    hourlyWage: 0,
    supervisorHourlyWage: 0,
  });

  const [setupTime, setSetupTime] = useState<IStandardCleaningTemplate | null>({
    id: 0,
    setupTimeSeconds: 0,
    finishTimeSeconds: 0,
  });

  const mockApiResponse = [
    { room: "Soba1", rating: "FR1" },
    { room: "Soba2", rating: "FR2" },
  ];
  
  const frOptions = ["FR1", "FR2", "FR3", "FR4", "FR5"];



  const handleSelectChange = (index: any, value: any) => {
    setFrRatings((prevRatings:any) => {
      const newRatings = [...prevRatings];
      newRatings[index].frRating = value;
      return newRatings;
    });
  };

  const data = [
    {
      key: 1,
      service: "NHS",
      operatives: 7,
      setupTimeMin: 10.0,
      finishTimeMin: 10.0,
      resultHrs: 1.75,
    },
    // {
    //   key: 2,
    //   service: "2007",
    //   operatives: 10,
    //   setupTimeMin: 18.0,
    //   finishTimeMin: 34.0,
    //   resultHrs: 2.5,
    // },
    // {
    //   key: 3,
    //   service: "2021",
    //   operatives: 12,
    //   setupTimeMin: 66.0,
    //   finishTimeMin: 23.0,
    //   resultHrs: 3.0,
    // },
  ];

  const columns = [
    // {
    //   title: "Service",
    //   dataIndex: "service",
    //   key: "service",
    // },
    // {
    //   title: "Operatives",
    //   dataIndex: "operatives",
    //   key: "operatives",
    // },
    {
      title: "Setup time (sec)",
      dataIndex: "setupTimeSeconds",
      key: "setupTimeSeconds",
      width: "50%",
      render: (text: any, record: any, index: number) => (
        <Input
          value={text}
          name="setupTimeSeconds"
          onChange={onSetupTimeInputChange}
        />
      ),
    },
    {
      title: "Finish time (sec)",
      dataIndex: "finishTimeSeconds",
      key: "finishTimeSeconds",
      width: "50%",
      render: (text: any, record: any, index: number) => (
        <Input
          value={text}
          name="finishTimeSeconds"
          onChange={onSetupTimeInputChange}
        />
      ),
    },
    // {
    //   title: "Result (hrs)",
    //   dataIndex: "resultHrs",
    //   key: "resultHrs",
    //   className: styles.headerCell,
    // },
  ];

  const wageColumns = [
    {
      title: "",
      dataIndex: "type",
      key: "type",
      width: "65%",
    },
    {
      title: "",
      dataIndex: "wage",
      key: "wage",
      width: "35%",
    },
  ];

  const gradeData = [
    {
      key: "1",
      type: "Grade A = £20,156 < band 2",
      wage: "£10.32",
    },
    {
      key: "2",
      type: "Grade B = £21,361   band 3",
      wage: "£10.92",
    },
    {
      key: "3",
      type: "Grade C = £23,462   band 3/4",
      wage: "£12.00",
    },
  ];

  const bandData = [
    {
      key: "1",
      type: "Band 1 £20,270*",
      wage: "£10.37",
    },
    {
      key: "2",
      type: "Band 2 £20,270-21,318",
      wage: "£10.37- £10.90",
    },
    {
      key: "3",
      type: "Band 3 £21,730-23,177",
      wage: "£11.11-£11.85",
    },
  ];

  const [tableData, setTableData] = useState<
    IStandardCleaningTemplate[] | null
  >();

  const { buildingId } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(actions.resetBenchmark());

    return () => {
      dispatch(actions.resetBenchmark());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!dispatched && buildingId && !benchmark && searchParams) {
      setDispatched(true);
      dispatch(
        getBenchmark({
          buildingId: buildingId,
          isDemo: searchParams.get("demo"),
        })
      );
      BuildingsService.get(buildingId).then((resp:any) => {
        setBuilding(resp.data);
      });
      BuildingsService.getFrRatings(buildingId).then((resp:any) => {
        setFrRatings(resp.data);
      })

    }
  }, [dispatch, dispatched, buildingId, benchmark, searchParams]);

  useEffect(() => {
    if (benchmark) {
      if (buildingId === "251") {
        let dashes = [];
        dashes?.push({
          order: 1,
          title: "Results",
          cards: [
            {
              title: "Emissions Savings",
              keypoint: {
                prefix: "",
                main: "179.45",
                sufix: ""
              },
              details: [
                ""
              ]
            },
            {
              title: "Electricity Savings",
              keypoint: {
                prefix: "",
                main: "£ 253,530.20",
                sufix: ""
              },
              details: [
                ""
              ]
            },
            {
              title: "Lamp Savings",
              keypoint: {
                prefix: "",
                main: "£ 269,068.80",
                sufix: ""
              },
              details: [
                ""
              ]
            },
            {
              title: "Maintenance Savings",
              keypoint: {
                prefix: "",
                main: "£ 50,000.00",
                sufix: ""
              },
              details: [
                ""
              ]
            },
            {
              title: "Total Savings",
              keypoint: {
                prefix: "",
                main: "£ 572,599.00",
                sufix: ""
              },
              details: [
                ""
              ]
            },
            {
              title: "Payback",
              keypoint: {
                prefix: "",
                main: "26.12",
                sufix: ""
              },
              details: [
                ""
              ]
            },
            {
              title: "Cost of no Action",
              keypoint: {
                prefix: "",
                main: "£ 34,819.10",
                sufix: ""
              },
              details: [
                "over 3 months"
              ]
            },
            {
              title: "Cost of no Action",
              keypoint: {
                prefix: "",
                main: "£ 69,638.20",
                sufix: ""
              },
              details: [
                "over 6 months"
              ]
            }
          ],
          tables: [
            {
              title: "Totals",
              headers: [
                {
                  name: "Capital Investment Expenditure",
                  isVisible: true,
                },
                {
                  name: "Saving/year based on ",
                  isVisible: true,
                },
                {
                  name: "/tonne CO2",
                  isVisible: true,
                },
              ],
              rows: [
                {
                  cells: [
                    {
                      data: {
                        value: "£ 249.299,38",
                        formattedValue: "£ 249,299.38",
                        dataType: DataType.Text,
                      },
                    },
                    {
                      data: {
                        value: "30,17",
                        formattedValue: "30.17",
                        dataType: DataType.Number,
                      },
                    },
                    {
                      data: {
                        value: "£ 430,67",
                        formattedValue: "£ 430.67",
                        dataType: DataType.Number,
                      },
                    },
                  ],
                },
              ],
            },
            {
              title: "Before (Existing Costs)",
              headers: [
                {
                  name: "CO2 Emissions Tonnes",
                  isVisible: true,
                },
                {
                  name: "Electricity Cost",
                  isVisible: true,
                },
                {
                  name: "Replacement Lamp Costs",
                  isVisible: true,
                },
                {
                  name: "Maintenance Costs",
                  isVisible: true,
                },
              ],
              rows: [
                {
                  cells: [
                    {
                      data: {
                        value: "378,53",
                        formattedValue: "378.53",
                        dataType: DataType.Text,
                      },
                    },
                    {
                      data: {
                        value: "£ 367.313,23",
                        formattedValue: "£ 367,313.23",
                        dataType: DataType.Number,
                      },
                    },
                    {
                      data: {
                        value: "£ 269.068,80",
                        formattedValue: "£ 269,068.80",
                        dataType: DataType.Number,
                      },
                    },
                    {
                      data: {
                        value: "£ 60.000,00",
                        formattedValue: "£ 60,000.00",
                        dataType: DataType.Number,
                      },
                    },
                  ],
                },
              ],
            },
            {
              title: "After (Proposed Costs)",
              headers: [
                {
                  name: "CO2 Emissions Tonnes",
                  isVisible: true,
                },
                {
                  name: "Electricity Cost",
                  isVisible: true,
                },
                {
                  name: "Replacement Lamp Costs",
                  isVisible: true,
                },
                {
                  name: "Maintenance Costs",
                  isVisible: true,
                },
              ],
              rows: [
                {
                  cells: [
                    {
                      data: {
                        value: "199,08",
                        formattedValue: "199.08",
                        dataType: DataType.Text,
                      },
                    },
                    {
                      data: {
                        value: "£ 113.783,03",
                        formattedValue: "£ 113,783.03",
                        dataType: DataType.Number,
                      },
                    },
                    {
                      data: {
                        value: "£ 0,00",
                        formattedValue: "£ 0.00",
                        dataType: DataType.Number,
                      },
                    },
                    {
                      data: {
                        value: "£ 10.000,00",
                        formattedValue: "£ 10,000.00",
                        dataType: DataType.Number,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        })
        dashes?.push({
          order: 2,
          title: "Summary",
          cards: [
            {
              title: "Existing",
              keypoint: {
                prefix: "",
                main: "£ 696,382.03",
                sufix: ""
              },
              details: [
                "cost over 5 years"
              ]
            },
            {
              title: "Proposed",
              keypoint: {
                prefix: "",
                main: "£ 123,783.03",
                sufix: ""
              },
              details: [
                "cost over 5 years"
              ]
            },
            {
              title: "Savings",
              keypoint: {
                prefix: "",
                main: "£ 572,599.00",
                sufix: ""
              },
              details: [
                "over 5 years"
              ]
            },
            {
              title: "Capital Investment Expenditure",
              keypoint: {
                prefix: "",
                main: "£ 249,299.38",
                sufix: ""
              },
              details: [
                ""
              ]
            },
            {
              title: "Payback (not incl. CRC)(months)",
              keypoint: {
                prefix: "",
                main: "26.12",
                sufix: ""
              },
              details: [
                ""
              ]
            },
            {
              title: "Payback (incl. CRC)(months)",
              keypoint: {
                prefix: "",
                main: "26.03",
                sufix: ""
              },
              details: [
                ""
              ]
            },
          ],
          tables: [
            {
              title: "Existing",
              headers: [
                {
                  name: "Existing CO2 Emissions Tonnes",
                  isVisible: true,
                },
                {
                  name: "Existing trees to offset",
                  isVisible: true,
                },
                {
                  name: "Existing Electricity Cost",
                  isVisible: true,
                },
                {
                  name: "Existing Replacement Lamp Costs",
                  isVisible: true,
                },
                {
                  name: "Existing Maintenance Costs",
                  isVisible: true,
                },
                {
                  name: "Existing Total",
                  isVisible: true,
                },
              ],
              rows: [
                {
                  cells: [
                    {
                      data: {
                        value: "378,53",
                        formattedValue: "378.53",
                        dataType: DataType.Number,
                      },
                    },
                    {
                      data: {
                        value: "13.626,99",
                        formattedValue: "13,626.99",
                        dataType: DataType.Number,
                      },
                    },
                    {
                      data: {
                        value: "£ 367.313,23",
                        formattedValue: "£ 367,313.23",
                        dataType: DataType.Text,
                      },
                    },
                    {
                      data: {
                        value: "£ 269.068,80",
                        formattedValue: "£ 269,068.80",
                        dataType: DataType.Text,
                      },
                    },
                    {
                      data: {
                        value: "£ 60.000,00",
                        formattedValue: "£ 60,000.00",
                        dataType: DataType.Number,
                      },
                    },
                    {
                      data: {
                        value: "£ 696.382,03",
                        formattedValue: "£ 696,382.03",
                        dataType: DataType.Number,
                      },
                    },
                  ],
                },
              ],
            },
            {
              title: "Proposed",
              headers: [
                {
                  name: "Proposed CO2 Emissions Tonnes",
                  isVisible: true,
                },
                {
                  name: "Proposed trees to offset",
                  isVisible: true,
                },
                {
                  name: "Proposed Electricity Cost",
                  isVisible: true,
                },
                {
                  name: "Proposed Replacement Lamp Costs",
                  isVisible: true,
                },
                {
                  name: "Proposed Maintenance Costs",
                  isVisible: true,
                },
                {
                  name: "Proposed Total",
                  isVisible: true,
                },
              ],
              rows: [
                {
                  cells: [
                    {
                      data: {
                        value: "199,08",
                        formattedValue: "199.08",
                        dataType: DataType.Number,
                      },
                    },
                    {
                      data: {
                        value: "7,166.89",
                        formattedValue: "7,166.89",
                        dataType: DataType.Number,
                      },
                    },
                    {
                      data: {
                        value: "£ 113.783,03",
                        formattedValue: "£ 367,313.23",
                        dataType: DataType.Text,
                      },
                    },
                    {
                      data: {
                        value: "£ 0,00",
                        formattedValue: "£ 0.00",
                        dataType: DataType.Text,
                      },
                    },
                    {
                      data: {
                        value: "£ 10.000,00",
                        formattedValue: "£ 10,000.00",
                        dataType: DataType.Number,
                      },
                    },
                    {
                      data: {
                        value: "£ 123.783,03",
                        formattedValue: "£ 123,783.03",
                        dataType: DataType.Number,
                      },
                    },
                  ],
                },
              ],
            },
            {
              title: "Savings",
              headers: [
                {
                  name: "Saving CO2 Emissions Tonnes",
                  isVisible: true,
                },
                {
                  name: "Saving trees to offset",
                  isVisible: true,
                },
                {
                  name: "Saving Electricity Cost",
                  isVisible: true,
                },
                {
                  name: "Saving Replacement Lamp Costs",
                  isVisible: true,
                },
                {
                  name: "Saving Maintenance Costs",
                  isVisible: true,
                },
                {
                  name: "Saving Total (5yr)",
                  isVisible: true,
                },
                {
                  name: "Saving Total (annual)",
                  isVisible: true,
                },
                {
                  name: "CRC Saving/year based on £12,00/ton",
                  isVisible: true,
                },
              ],
              rows: [
                {
                  cells: [
                    {
                      data: {
                        value: "179,45",
                        formattedValue: "179.45",
                        dataType: DataType.Number,
                      },
                    },
                    {
                      data: {
                        value: "6.460,10",
                        formattedValue: "6,460.10",
                        dataType: DataType.Number,
                      },
                    },
                    {
                      data: {
                        value: "£ 253.530,20",
                        formattedValue: "£ 253,530.20",
                        dataType: DataType.Text,
                      },
                    },
                    {
                      data: {
                        value: "£ 269.068,80",
                        formattedValue: "£ 269,068.80",
                        dataType: DataType.Text,
                      },
                    },
                    {
                      data: {
                        value: "£ 50.000,00",
                        formattedValue: "£ 50,000.00",
                        dataType: DataType.Number,
                      },
                    },
                    {
                      data: {
                        value: "£ 572.599,00",
                        formattedValue: "£ 572,599.00",
                        dataType: DataType.Number,
                      },
                    },
                    {
                      data: {
                        value: "£ 114.519,80",
                        formattedValue: "£ 114,519.80",
                        dataType: DataType.Number,
                      },
                    },
                    {
                      data: {
                        value: "£ 430,67",
                        formattedValue: "£ 430.67",
                        dataType: DataType.Number,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        })
        setDashboardTabs(dashes);
        return;
      }

      setOverheadCosts({
        ...benchmark.overheadTemplate,
      });
      setWages({
        ...benchmark.eveningCleaningTemplate,
      });
      setSetupTime({
        ...benchmark.standardCleaningTemplate,
      });

      let dashes = benchmark?.screens
        ?.find(
          (screen) => screen.title === ROUTES.benchmark.benchmarkResults.name
        )
        ?.dashboards?.filter((dashboard: IDashboard) => dashboard !== null);
      (buildingId == "73" || buildingId == "217") &&
        dashes?.push({
          order: 999,
          title: "Staff",
          tables: [
            {
              title: "",
              headers: [
                {
                  name: "Staff",
                  isVisible: true,
                },
                {
                  name: "Head Count",
                  isVisible: true,
                },
              ],
              rows: [
                {
                  cells: [
                    {
                      data: {
                        value: "Contractor",
                        formattedValue: "Contractor",
                        dataType: DataType.Text,
                      },
                    },
                    {
                      data: {
                        value: buildingId == "73" ? "108.91" : "112.00",
                        formattedValue: buildingId == "73" ? "108.91" : "112.00",
                        dataType: DataType.Number,
                      },
                    },
                  ],
                },
                {
                  cells: [
                    {
                      data: {
                        value: "Employee - Full Time",
                        formattedValue: "Employee - Full Time",
                        dataType: DataType.Text,
                      },
                    },
                    {
                      data: {
                        value: buildingId == "73" ? "67.17" : "70.00",
                        formattedValue: buildingId == "73" ? "67.17" : "70.00",
                        dataType: DataType.Number,
                      },
                    },
                  ],
                },
                {
                  cells: [
                    {
                      data: {
                        value: "Employee - Part Time",
                        formattedValue: "Employee - Part Time",
                        dataType: DataType.Text,
                      },
                    },
                    {
                      data: {
                        value: buildingId == "73" ? "121.00" : "115.00",
                        formattedValue: buildingId == "73" ? "121.00" : "115.00",
                        dataType: DataType.Number,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        });
      setDashboardTabs(dashes);
    }
  }, [benchmark]);

  const items: MenuProps["items"] = [
    // {
    //   key: "1",
    //   label: (
    //     <div
    //       className={styles.dropdownMenuItem}
    //       onClick={() => showModal(AdjustmentKey.EQUIPMENT)}
    //     >
    //       <ToolOutlined />
    //       <label>Equipment</label>
    //     </div>
    //   ),
    // },
    {
      key: "2",
      label: (
        <div
          className={styles.dropdownMenuItem}
          onClick={() => showModal(AdjustmentKey.SETUP_TIME)}
        >
          <FieldTimeOutlined />
          <label>Setup time</label>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          className={styles.dropdownMenuItem}
          onClick={() => showModal(AdjustmentKey.OVERHEAD_COSTS)}
        >
          <WalletOutlined />
          <label>Overhead costs</label>
        </div>
      ),
    },
    {
      key: "4",
      label: (
          <div
              className={styles.dropdownMenuItem}
              onClick={() => showModal(AdjustmentKey.PRODUCTIVITY_RATE)}
          >
            <LineChartOutlined/>
            <label>Productivity rate</label>
          </div>
      )
    },
    {
      key: "5",
      label: (
          <div
              className={styles.dropdownMenuItem}
              onClick={() => showModal(AdjustmentKey.WAGES)}
          >
            <LineChartOutlined/>
            <label>Wages</label>
          </div>
      ),
    },
  ];

  const menuProps = {
    items,
  };

  const BtnOperations = (
    <>
      <div className={styles.btnContainer}>
        {buildingId !== "261" && (
          <Button
            type="primary"
            ghost
            onClick={() => showModal(AdjustmentKey.SETUP_TIME)}
            icon={<FieldTimeOutlined />}
          >
            Setup time
          </Button>
        )}
        {buildingId === "261" && (
            <Button
                type="primary"
                onClick={() => showModal(AdjustmentKey.PRODUCTIVITY_RATE)}
                ghost
                icon={<LineChartOutlined />}
            >
              Productivity rate
            </Button>  
        )}
        {buildingId === "261" && (
            <Button
                type="primary"
                onClick={() => showModal(AdjustmentKey.FR_RATINGS)}
                ghost
                icon={<LineChartOutlined />}
            >
              FR Ratings
            </Button>
        )}
        <Button
          type="primary"
          onClick={() => showModal(AdjustmentKey.OVERHEAD_COSTS)}
          ghost
          icon={<LineChartOutlined />}
        >
          Overhead costs
        </Button>
        <Button
          type="primary"
          onClick={() => showModal(AdjustmentKey.WAGES)}
          ghost
          icon={<WalletOutlined />}
        >
          Wages
        </Button>
        {/* <Dropdown
          menu={{ items }}
          placement="bottomLeft"
          overlayClassName={styles.dropdownMenu}
          trigger={["click"]}
        >
          <Button type="primary" ghost icon={<MoreOutlined />}>
            Adjustments
          </Button>
        </Dropdown> */}
        {/* <Button
          style={{
            border: "1px solid #FFFFFF",
            background: "linear-gradient(to right, #313ab8 30%, #88227c  100%)",
            // background: "#130F21"
          }}
          type="primary"
          icon={<SearchOutlined />}
          onClick={() => showWalkModal()}
        >
          Walking time
        </Button> */}
      </div>
    </>
  );

  const showModal = (adjustmentKey: AdjustmentKey) => {
    setActiveAdjustment(adjustmentKey);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setActiveAdjustment(AdjustmentKey.UNDEFINED);
  };

  const showWalkModal = () => {
    setIsWalkModalOpen(true);
  };
  const handleCloseWalkModal = () => {
    setIsWalkModalOpen(false);
  };

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    setOverheadCosts((currentState) => {
      return {
        ...currentState,
        [inputName]: inputValue,
      };
    });
  };

  const onWagesInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    setWages((currentState) => {
      return {
        ...currentState,
        [inputName]: inputValue,
      };
    });
  };

  const onProductivityRateInputChange = (
      value: number
  ) => {
    setBuilding((currentState:any) => {
      return {
        ...currentState,
        productivityRate: value,
      };
    });
  };

  const onSetupTimeInputChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const inputName = event.target.name;
    const inputValue = parseInt(event.target.value);

    if (isNaN(inputValue)) return;
    setSetupTime((currentState) => {
      return {
        ...currentState,
        [inputName]: inputValue,
      };
    });
  };

  const handleSaveOverheadModal = () => {
    if (overheadCosts) {
      setDispatched(true);
      OverheadTemplatesService.put(overheadCosts).then((res) => {
        console.log("saved overhead");

        dispatch(
          getBenchmark({
            buildingId: buildingId,
            isDemo: searchParams.get("demo"),
          })
        ).then(() => {
          setIsModalOpen(false);
          setActiveAdjustment(AdjustmentKey.UNDEFINED);
          notification.success({
            message: "Success",
            description: "Overheads saved successfully.",
          });
        });
      }).catch((error) => {
        console.error("Error saving Overheads: ", error);
        notification.error({
          message: "Error",
          description: "Failed to save Overheads.",
        });
      });
    }
  };

  const handleWagesModalSave = () => {
    if (wages) {
      setDispatched(true);
      EveningCleaningTemplatesService.put(wages).then((res) => {
        console.log("saved wages");

        dispatch(
          getBenchmark({
            buildingId: buildingId,
            isDemo: searchParams.get("demo"),
          })
        ).then(() => {
          setIsModalOpen(false);
          setActiveAdjustment(AdjustmentKey.UNDEFINED);
          notification.success({
            message: "Success",
            description: "Wages saved successfully.",
          });
        });
      }).catch((error) => {
        console.error("Error saving Wages: ", error);
        notification.error({
          message: "Error",
          description: "Failed to save Wages.",
        });
      });
    }
  };

  const handleProductivityRateModalSave = () => {
    if (building) {
      setDispatched(true);
      BuildingsService.update(building).then((res) => {
        dispatch(
            getBenchmark({
              buildingId: buildingId,
              isDemo: searchParams.get("demo"),
            })
        ).then(() => {
          setIsModalOpen(false);
          setActiveAdjustment(AdjustmentKey.UNDEFINED);
          notification.success({
            message: "Success",
            description: "Productivity rate saved successfully.",
          });
        });
      }).catch((error) => {
        console.error("Error saving Productivity rate: ", error);
        notification.error({
          message: "Error",
          description: "Failed to save Productivity rate.",
        });
      });
    }
  };

  const handleFRRatingsModalSave = () => {
    if (frRatings) {
      setDispatched(true);
      BuildingsService.updateFrRoomRatings(buildingId, frRatings).then((res) => {
        console.log("saved FR Ratings");
        dispatch(
            getBenchmark({
              buildingId: buildingId,
              isDemo: searchParams.get("demo"),
            }));
        dispatch(
            getFRRatings({
              buildingId: buildingId,
            })
        ).then(() => {
          setIsModalOpen(false);
          setActiveAdjustment(AdjustmentKey.UNDEFINED);
          notification.success({
            message: "Success",
            description: "FR Ratings saved successfully.",
          });
        });
      }).catch((error) => {
        console.error("Error saving FR Ratings:", error);
        notification.error({
          message: "Error",
          description: "Failed to save FR Ratings.",
        });
      });
    }
  };
  
  

  const handleSaveSetupTimeModal = () => {
    if (setupTime) {
      setDispatched(true);
      StandardCleaningTemplatesService.put(setupTime).then((res) => {
        console.log("saved setup");

        dispatch(
          getBenchmark({
            buildingId: buildingId,
            isDemo: searchParams.get("demo"),
          })
        ).then(() => {
          setIsModalOpen(false);
          setActiveAdjustment(AdjustmentKey.UNDEFINED);
          notification.success({
            message: "Success",
            description: "Setup time saved successfully.",
          });
        });
      }).catch((error) => {
        console.error("Error saving Setup time:", error);
        notification.error({
          message: "Error",
          description: "Failed to save Setup time.",
        });
      });
    }
  };

  return (
    <>
    {!benchmark && (
        <Spin
            style={{
              transform: "translate(-50%, -50%)",
              left: "calc(50vw + 125px)",
              top: "50vh",
              position: "fixed",
            }}
        />
    )}
      {dashboardTabs && (
        <div className={styles.mobileLayout}>
          <Tabs
            className={classNames(
              "benchmark-results-mobile-tabs",
              styles.mobileTabs
            )}
            defaultActiveKey="1"
            items={dashboardTabs?.map(
              (dashboard: IDashboard, index: number) => ({
                label: dashboard.title,
                key: index.toString(),
                children: (
                  <Dashboard pending={pending} dashboardData={dashboard} />
                ),
              })
            )}
            onChange={(key: string) => setActiveTabKey(key)}
          />
        </div>
      )}
      {buildingId === "251" && dashboardTabs && (
        <div
          className={styles.desktopLayout}
          style={{
            backgroundColor: "#fff",
            margin: "10px",
            minHeight: "calc(100% - 20px)",
          }}
        >
          <Tabs
            className={styles.tabContainer}
            tabBarStyle={{ marginBottom: "0px" }}
            // tabBarExtraContent={BtnOperations}
            defaultActiveKey="1"
            type="card"
            activeKey={activeTabKey}
            onChange={(key: string) => setActiveTabKey(key)}
            items={dashboardTabs.map(
              (dashboard: IDashboard, index: number) => ({
                label: dashboard.title,
                key: index.toString(),
                children: (
                  <Dashboard
                    pending={pending}
                    dashboardData={dashboard}
                    dashboardFrRatingsData={benchmark?.screens
                      ?.find(
                        (screen) =>
                          screen.title ===
                          `${ROUTES.benchmark.benchmarkResults.name} - FR RATINGS`
                      )
                      ?.dashboards?.find(
                        (dash) => dashboard.title === dash.title
                      )}
                  />
                ),
              })
            )}
          />
        </div>
      )}
      {buildingId !== "251" && dashboardTabs && benchmark && (
        <div
          className={styles.desktopLayout}
          style={{
            backgroundColor: "#fff",
            margin: "10px",
            minHeight: "calc(100% - 20px)",
          }}
        >
          <Tabs
            className={styles.tabContainer}
            tabBarStyle={{ marginBottom: "0px" }}
            tabBarExtraContent={BtnOperations}
            defaultActiveKey="1"
            type="card"
            activeKey={activeTabKey}
            onChange={(key: string) => setActiveTabKey(key)}
            items={dashboardTabs.map(
              (dashboard: IDashboard, index: number) => ({
                label: dashboard.title,
                key: index.toString(),
                children: (
                  <Dashboard
                    pending={pending}
                    dashboardData={dashboard}
                    dashboardFrRatingsData={benchmark?.screens
                      ?.find(
                        (screen) =>
                          screen.title ===
                          `${ROUTES.benchmark.benchmarkResults.name} - FR RATINGS`
                      )
                      ?.dashboards?.find(
                        (dash) => dashboard.title === dash.title
                      )}
                  />
                ),
              })
            )}
          />
          <Modal
            title={renderModalTitle(activeAdjustment)}
            open={activeAdjustment === AdjustmentKey.OVERHEAD_COSTS}
            onOk={handleSaveOverheadModal}
            onCancel={handleCancel}
            width={"100%"}
            style={{
              padding: "16px 24px",
              maxWidth:
                activeAdjustment !== AdjustmentKey.OVERHEAD_COSTS
                  ? "700px"
                  : "400px",
            }}
            centered
            footer={[
              <div className={styles.modalFooterContainer}>
                {activeAdjustment === AdjustmentKey.PRODUCTIVITY_RATE && (
                  <div className={styles.infoContainer}>
                    <InfoCircleOutlined className={styles.icon} />
                    <span>
                      Higher productivity rate decreases the total cost of the
                      contract.
                    </span>
                  </div>
                )}
                <div
                  style={{
                    width:
                      activeAdjustment !== AdjustmentKey.PRODUCTIVITY_RATE
                        ? "100%"
                        : "",
                  }}
                >
                  <Button key="back" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    key="submit"
                    type="primary"
                    onClick={handleSaveOverheadModal}
                  >
                    Set
                  </Button>
                </div>
              </div>,
            ]}
          >
            <div
              className={classNames(
                styles.modalContainer,
                styles.columnContainer
              )}
            >
              <div className={styles.inputContainer}>
                {buildingId == "261" ? (<label>Holiday pay (%):</label>) : (<label>Holiday bonus (%):</label>)}
                <Input
                  name="holidayBonusPercentage"
                  placeholder="Enter value"
                  value={overheadCosts?.holidayBonusPercentage ?? 0}
                  onChange={onInputChange}
                />
              </div>
              <div className={styles.inputContainer}>
                <label>Insurance (%):</label>
                <Input
                  name="insurancePercentage"
                  placeholder="Enter value"
                  value={overheadCosts?.insurancePercentage ?? 0}
                  onChange={onInputChange}
                />
              </div>
              <div className={styles.inputContainer}>
                <label>Employers NI (%):</label>
                <Input
                  name="employerNIPercentage"
                  placeholder="Enter value"
                  value={overheadCosts?.employerNIPercentage ?? 0}
                  onChange={onInputChange}
                />
              </div>
              <div className={styles.inputContainer}>
                <label>Pension (%) :</label>
                <Input
                  name="pensionPercentage"
                  placeholder="Enter value"
                  value={overheadCosts?.pensionPercentage ?? 0}
                  onChange={onInputChange}
                />
              </div>
              {(buildingId != "73" && buildingId != "217" && buildingId != "261") && (
                <div className={styles.inputContainer}>
                  <label>Cleaning materials (%):</label>
                  <Input
                    name="cleaningMaterialsPercentage"
                    placeholder="Enter value"
                    value={overheadCosts?.cleaningMaterialsPercentage ?? 0}
                    onChange={onInputChange}
                  />
                </div>
              )}
              {(buildingId == "73") && (
                <div className={styles.inputContainer}>
                  <label>Consumables (£):</label>
                  <Input
                    name="consumablesPounds"
                    placeholder="Enter value"
                    value={"91,405"}
                    onChange={() => null}
                  />
                </div>
              )}
              {(buildingId == "217") && (
                  <div className={styles.inputContainer}>
                    <label>Consumables (£):</label>
                    <Input
                        name="consumablesPounds"
                        placeholder="Enter value"
                        value={"80,000"}
                        onChange={() => null}
                    />
                  </div>
              )}
              <div className={styles.inputContainer}>
                <label>
                  Equipment{" "}
                  {(buildingId == "73" || buildingId == "217" || buildingId == "261") ? "and cleaning materials (£)" : "(%)"}:
                </label>
                <Input
                  name="equipmentPercentage"
                  placeholder="Enter value"
                  value={
                    (buildingId == "73")
                      ? "208,000"
                      : (buildingId == "217") ? "215,000"
                      : (buildingId == "261") ? "24,000" : overheadCosts?.equipmentPercentage ?? 0
                  }
                  onChange={(buildingId == "73" || buildingId == "217" || buildingId == "261") ? () => null : onInputChange}
                />
              </div>
              {(buildingId != "73" && buildingId != "217") && (
                <div className={styles.inputContainer}>
                  <label>Management fee (%):</label>
                  <Input
                    name="managementPercentage"
                    placeholder="Enter value"
                    value={overheadCosts?.managementPercentage ?? 0}
                    onChange={onInputChange}
                  />
                </div>
              )}
              <div className={styles.inputContainer}>
                <label>Profit margin (%):</label>
                <Input
                  name="profitsPercentage"
                  placeholder="Enter value"
                  value={overheadCosts?.profitsPercentage ?? 0}
                  onChange={onInputChange}
                />
              </div>
            </div>
          </Modal>
          <Modal
            title={renderModalTitle(activeAdjustment)}
            open={activeAdjustment === AdjustmentKey.WAGES}
            onOk={handleWagesModalSave}
            onCancel={handleCancel}
            width={"100%"}
            style={{
              padding: "16px 24px",
              maxWidth:
                activeAdjustment !== AdjustmentKey.OVERHEAD_COSTS
                  ? "700px"
                  : "400px",
            }}
            centered
            footer={[
              <div className={styles.modalFooterContainer}>
                {activeAdjustment === AdjustmentKey.PRODUCTIVITY_RATE && (
                  <div className={styles.infoContainer}>
                    <InfoCircleOutlined className={styles.icon} />
                    <span>
                      Higher productivity rate decreases the total cost of the
                      contract.
                    </span>
                  </div>
                )}
                <div
                  style={{
                    width:
                      activeAdjustment !== AdjustmentKey.PRODUCTIVITY_RATE
                        ? "100%"
                        : "",
                  }}
                >
                  <Button key="back" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    key="submit"
                    type="primary"
                    onClick={
                      (buildingId == "73" || buildingId == "217") ? handleCancel : handleWagesModalSave
                    }
                  >
                    {(buildingId == "73" || buildingId == "217") ? "Ok" : "Set"}
                  </Button>
                </div>
              </div>,
            ]}
          >
            <div
              className={classNames(
                styles.modalContainer,
                styles.columnContainer
              )}
            >
              {(buildingId == "73" || buildingId == "217") ? (
                <div>
                  <Table
                    style={{ marginBottom: "32px" }}
                    showHeader={false}
                    pagination={false}
                    columns={wageColumns}
                    dataSource={gradeData}
                  />
                  <Table
                    showHeader={false}
                    pagination={false}
                    columns={wageColumns}
                    dataSource={bandData}
                  />
                </div>
              ) : (
                <div className={styles.inputContainer}>
                  <label>Hourly wage:</label>
                  <Input
                    name="hourlyWage"
                    placeholder="Enter value"
                    value={wages?.hourlyWage ?? 0}
                    onChange={onWagesInputChange}
                    style={{ minWidth: "200px" }}
                  />
                </div>
              )}
              {/* <div className={styles.inputContainer}>
              <label>
                Hourly supevisor wage in $ <QuestionCircleOutlined /> :
              </label>
              <Input
                name="supervisorHourlyWage"
                placeholder="Enter value"
                value={cleanersSetup?.supervisorHourlyWage ?? 0}
                onChange={onCleanersSetupInputChange}
                style={{ minWidth: "200px" }}
              />
            </div> */}
            </div>
          </Modal>
          <Modal
              title={renderModalTitle(activeAdjustment)}
              open={activeAdjustment === AdjustmentKey.FR_RATINGS}
              onOk={handleFRRatingsModalSave}
              onCancel={handleCancel}
              width={"100%"}
              style={{
                padding: "16px 24px",
                maxWidth: activeAdjustment !== AdjustmentKey.OVERHEAD_COSTS ? "700px" : "400px",
              }}
              centered
              footer={[
                <div className={styles.modalFooterContainer}>
                  {activeAdjustment === AdjustmentKey.FR_RATINGS && (
                      <div className={styles.infoContainer}>
                        <InfoCircleOutlined className={styles.icon} />
                        <span>Higher FR ratings increase the total cost of the contract.</span>
                      </div>
                  )}
                  <div
                      style={{
                        width: activeAdjustment !== AdjustmentKey.FR_RATINGS ? "100%" : "",
                      }}
                  >
                    <Button key="back" onClick={handleCancel}>
                      Close
                    </Button>
                    <Button key="submit" type="primary" onClick={handleFRRatingsModalSave}>
                      Set
                    </Button>
                  </div>
                </div>,
              ]}
          >
            <div className={classNames(styles.modalContainer, styles.columnContainer)}>
              {frRatings?.map((item:any, index:any) => (
                  <div className={styles.inputContainer} key={item.id}>
                    <label>{item.name}:</label>
                    <Select
                        value={item.frRating}
                        onChange={(value) => handleSelectChange(index, value)}
                        style={{ minWidth: "200px" }}
                        options={frOptions.map((option) => ({
                          label: option,
                          value: option,
                        }))}
                    />
                  </div>
              ))}
            </div>
          </Modal>
          <Modal
              title={renderModalTitle(activeAdjustment)}
              open={activeAdjustment === AdjustmentKey.PRODUCTIVITY_RATE}
              onOk={handleProductivityRateModalSave}
              onCancel={handleCancel}
              width={"100%"}
              style={{
                padding: "16px 24px",
                maxWidth:
                    activeAdjustment !== AdjustmentKey.OVERHEAD_COSTS
                        ? "700px"
                        : "400px",
              }}
              centered
              footer={[
                <div className={styles.modalFooterContainer}>
                  {activeAdjustment === AdjustmentKey.PRODUCTIVITY_RATE && (
                      <div className={styles.infoContainer}>
                        <InfoCircleOutlined className={styles.icon} />
                        <span>
                      Higher productivity rate decreases the total cost of the
                      contract.
                    </span>
                      </div>
                  )}
                  <div
                      style={{
                        width:
                            activeAdjustment !== AdjustmentKey.PRODUCTIVITY_RATE
                                ? "100%"
                                : "",
                      }}
                  >
                    <Button key="back" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleProductivityRateModalSave}
                    >
                      Set
                    </Button>
                  </div>
                </div>,
              ]}
          >
            <div
                className={classNames(
                    styles.modalContainer,
                    styles.columnContainer
                )}
            >
                <div className={styles.inputContainer}>
                  <label>Productivity rate:</label>
                  <Radio.Group
                      value={building?.productivityRate ?? 0}
                      onChange={(e:any) => onProductivityRateInputChange(e.target.value)}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                  >
                    <Radio
                        value={1}
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                        }}
                    >
                      1
                    </Radio>
                    <Radio
                        value={1.5}
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                        }}
                    >
                      1.5
                    </Radio>
                    <Radio
                        value={2}
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                        }}
                    >
                      2
                    </Radio>
                    <Radio
                        value={2.5}
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                        }}
                    >
                      2.5
                    </Radio>
                    <Radio
                        value={3}
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                        }}
                    >
                      3
                    </Radio>
                    <Radio
                        value={3.5}
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                        }}
                    >
                      3.5
                    </Radio>
                    <Radio
                        value={4}
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                        }}
                    >
                      4
                    </Radio>
                    <Radio
                        value={4.5}
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                        }}
                    >
                      4.5
                    </Radio>
                    <Radio
                        value={5}
                        style={{
                          display: "flex",
                          flexDirection: "column-reverse",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                        }}
                    >
                      5
                    </Radio>
                  </Radio.Group>
                </div>
            </div>
          </Modal>
          <Modal
            title={renderModalTitle(activeAdjustment)}
            open={activeAdjustment === AdjustmentKey.SETUP_TIME}
            onOk={handleSaveSetupTimeModal}
            onCancel={handleCancel}
            width={"100%"}
            style={{
              padding: "16px 24px",
              maxWidth:
                activeAdjustment !== AdjustmentKey.OVERHEAD_COSTS
                  ? "700px"
                  : "400px",
            }}
            centered
            footer={[
              <div className={styles.modalFooterContainer}>
                {activeAdjustment === AdjustmentKey.PRODUCTIVITY_RATE && (
                  <div className={styles.infoContainer}>
                    <InfoCircleOutlined className={styles.icon} />
                    <span>
                      Higher productivity rate decreases the total cost of the
                      contract.
                    </span>
                  </div>
                )}
                <div
                  style={{
                    width:
                      activeAdjustment !== AdjustmentKey.PRODUCTIVITY_RATE
                        ? "100%"
                        : "",
                  }}
                >
                  <Button key="back" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button
                    key="submit"
                    type="primary"
                    onClick={handleSaveSetupTimeModal}
                  >
                    Set
                  </Button>
                </div>
              </div>,
            ]}
          >
            <div
              className={classNames(
                styles.modalContainer,
                styles.columnContainer
              )}
            >
              <div className={styles.inputContainer}>
                <label>Setup time (sec):</label>
                <Input
                  name="setupTimeSeconds"
                  placeholder="Enter value"
                  value={setupTime?.setupTimeSeconds ?? 0}
                  onChange={onSetupTimeInputChange}
                  style={{ minWidth: "200px" }}
                />
              </div>
              <div className={styles.inputContainer}>
                <label>Finish time (sec) :</label>
                <Input
                  name="finishTimeSeconds"
                  placeholder="Enter value"
                  value={setupTime?.finishTimeSeconds ?? 0}
                  onChange={onSetupTimeInputChange}
                  style={{ minWidth: "200px" }}
                />
              </div>
            </div>
          </Modal>
          {/* <Modal
          title={renderModalTitle(activeAdjustment)}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          width={"100%"}
          style={{
            padding: "16px 24px",
            maxWidth:
              activeAdjustment !== AdjustmentKey.OVERHEAD_COSTS
                ? "700px"
                : "400px",
          }}
          centered
          footer={[
            <div className={styles.modalFooterContainer}>
              {activeAdjustment === AdjustmentKey.PRODUCTIVITY_RATE && (
                <div className={styles.infoContainer}>
                  <InfoCircleOutlined className={styles.icon} />
                  <span>
                    Higher productivity rate increases the total cost of the
                    contract.
                  </span>
                </div>
              )}
              <div
                style={{
                  width:
                    activeAdjustment !== AdjustmentKey.PRODUCTIVITY_RATE
                      ? "100%"
                      : "",
                }}
              >
                <Button key="back" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button key="submit" type="primary" onClick={handleOk}>
                  Set
                </Button>
              </div>
            </div>,
          ]}
        >
          {activeAdjustment === AdjustmentKey.EQUIPMENT && <EquipmentModal />}
          {activeAdjustment === AdjustmentKey.SETUP_TIME && <SetupTimeModal />}
          {activeAdjustment === AdjustmentKey.PRODUCTIVITY_RATE && (
            <ProductivityRateModal />
          )}
        </Modal> */}
          {/* <Modal
          title={<TitlePlaceholder />}
          open={isWalkModalOpen}
          width="60vw"
          bodyStyle={{
            height: "60vh",
          }}
          centered
          onCancel={() => setIsWalkModalOpen(false)}
          footer={null}
        >
          <iframe
            title="Walking time"
            src="https://scanquoplatform.blob.core.windows.net/videos/app/scanquo_walking_time_example.mp4"
            width="100%"
            height="100%"
            style={{ border: "none" }}
            allow="autoplay; fullscreen; picture-in-picture muted"
            allowFullScreen
          />
        </Modal> */}
        </div>
      )}
    </>
  );
};

export default BenchmarkResults;

const renderModalTitle = (adjustmentKey: AdjustmentKey) => {
  switch (adjustmentKey) {
    case AdjustmentKey.EQUIPMENT:
      return "Equipment";
    case AdjustmentKey.SETUP_TIME:
      return "Setup time";
    case AdjustmentKey.OVERHEAD_COSTS:
      return "Overhead costs";
    case AdjustmentKey.PRODUCTIVITY_RATE:
      return "Productivity rate";
    case AdjustmentKey.WAGES:
      return "Wages";
    case AdjustmentKey.FR_RATINGS:
      return "FR Ratings";
    default:
      return "Wages";
  }
};

const renderModalContent = (adjustmentKey: AdjustmentKey) => {
  switch (adjustmentKey) {
    case AdjustmentKey.EQUIPMENT:
      return <EquipmentModal />;
    case AdjustmentKey.SETUP_TIME:
      return <SetupTimeModal />;
    case AdjustmentKey.PRODUCTIVITY_RATE:
      return <ProductivityRateModal />;
    default:
      return <EquipmentModal />;
  }
};
const EquipmentModal = () => {
  return <></>;
};
const SetupTimeModal = () => {
  const [setupTime, setSetupTime] = useState<ISetupTime>({
    id: 0,
    setupTimeInMinutes: 0,
    finishTimeInMinutes: 0,
  });
  ///TODO fetch data from db
  const data = [
    {
      key: 1,
      service: "NHS",
      operatives: 7,
      setupTimeMin: 10.0,
      finishTimeMin: 10.0,
      resultHrs: 1.75,
    },
    // {
    //   key: 2,
    //   service: "2007",
    //   operatives: 10,
    //   setupTimeMin: 18.0,
    //   finishTimeMin: 34.0,
    //   resultHrs: 2.5,
    // },
    // {
    //   key: 3,
    //   service: "2021",
    //   operatives: 12,
    //   setupTimeMin: 66.0,
    //   finishTimeMin: 23.0,
    //   resultHrs: 3.0,
    // },
  ];

  const columns = [
    // {
    //   title: "Service",
    //   dataIndex: "service",
    //   key: "service",
    // },
    {
      title: "Operatives",
      dataIndex: "operatives",
      key: "operatives",
    },
    {
      title: "Setup time (min)",
      dataIndex: "setupTimeMin",
      key: "setupTimeMin",
      render: (text: any, record: any, index: number) => (
        <Input value={text} onChange={onInputChange("setupTimeMin", index)} />
      ),
    },
    {
      title: "Finish time (min)",
      dataIndex: "finishTimeMin",
      key: "finishTimeMin",
      render: (text: any, record: any, index: number) => (
        <Input value={text} onChange={onInputChange("finishTimeMin", index)} />
      ),
    },
    {
      title: "Result (hrs)",
      dataIndex: "resultHrs",
      key: "resultHrs",
      className: styles.headerCell,
    },
  ];
  const [tableData, setTableData] = useState<any[]>(data);

  const onInputChange =
    (key: any, index: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
      let newData = [...tableData];
      newData[index][key] = Number(e.target.value);
      setTableData(newData);
    };
  return (
    <div className={styles.modalContainer}>
      <Table
        size="small"
        columns={columns}
        dataSource={tableData}
        pagination={false}
      />
    </div>
  );
};

const ProductivityRateModal = () => {
  const [value, setValue] = useState(1);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  return (
    <div className={styles.modalContainer}>
      <Radio.Group
        onChange={onChange}
        value={value}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Radio
          value={1}
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          1
        </Radio>
        <Radio
          value={1.5}
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          1.5
        </Radio>
        <Radio
          value={2}
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          2
        </Radio>
        <Radio
          value={2.5}
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          2.5
        </Radio>
        <Radio
          value={3}
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          3
        </Radio>
        <Radio
          value={3.5}
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          3.5
        </Radio>
        <Radio
          value={4}
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          4
        </Radio>
        <Radio
          value={4.5}
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          4.5
        </Radio>
        <Radio
          value={5}
          style={{
            display: "flex",
            flexDirection: "column-reverse",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          5
        </Radio>
      </Radio.Group>
    </div>
  );
};
