import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  InputRef,
  Space,
  Switch,
  Table,
  Tabs,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { MoreOutlined, CloseOutlined } from "@ant-design/icons";
import type { FormInstance } from "antd/es/form";
import styles from "./DaytimeJanitor.module.scss";
import classNames from "classnames";
import { ICard } from "../../../../models/benchmark/shared/Card";
import { ITable } from "../../../../models/benchmark/shared/Table";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useParams, useSearchParams } from "react-router-dom";
import { actions, getBenchmark } from "../../../../store/benchmark";
import { ROUTES } from "../../../../config/constants";
import Card from "../../shared/card/Card";
import ScreenTable from "../../shared/table/Table";
import Dashboard from "../../shared/dashboard/Dashboard";
import { IDashboard } from "../../../../models/benchmark/Benchmark";

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface Item {
  key: string;
  timeSchedule: string;
  basic: string;
  optimal: string;
  premium: string;
}

interface EditableRowProps {
  index: number;
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  handleSave: (record: Item) => void;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      // console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  timeSchedule?: string | null;
  description?: string | null;
  basic: string | number;
  optimal: string | number;
  premium: string | number;
  custom: string | number;
}
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const DaytimeJanitor = () => {
  const [dispatched, setDispatched] = useState<boolean>(false);
  const [activeTabKey, setActiveTabKey] = useState<string>("0");

  const [compareActive, setCompareActive] = useState<boolean>(true);
  const [detailsActive, setDetailsActive] = useState<boolean>(false);
  const [activeOption, setActiveOption] = useState<string>("Premium");
  const [cardData, setCardData] = useState<ICard[]>();
  const [tablesData, setTablesData] = useState<ITable[]>();
  const [showDetailsDrawer, setShowDetailsDrawer] = useState(false);

  const dispatch = useAppDispatch();
  const { benchmark, pending } = useAppSelector((state) => state.benchmark);

  const { buildingId } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(actions.resetBenchmark());

    return () => {
      dispatch(actions.resetBenchmark());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!dispatched && buildingId && !benchmark && searchParams) {
      setDispatched(true);
      dispatch(
        getBenchmark({
          buildingId: buildingId,
          isDemo: searchParams.get("demo"),
        })
      );
    }
  }, [dispatch, dispatched, buildingId, benchmark, searchParams]);

  useEffect(() => {
    if (dispatched && benchmark) {
      const screenData = benchmark.screens?.find(
        (scr) => scr.title === ROUTES.benchmark.daytimeJanitor.name
      );
      if (screenData?.dashboards) {
        const dashboard = screenData.dashboards[0];
        dashboard?.cards && setCardData(dashboard.cards);
        dashboard?.tables && setTablesData(dashboard.tables);
      }
    }
  }, [dispatched, benchmark]);

  const [dataWorkingPlan, setDataWorkingPlan] = useState<DataType[]>([
    {
      key: 1,
      timeSchedule: "1pm - 3pm",
      basic: "120 min",
      optimal: "0 min",
      premium: "0 min",
      custom: "200 min",
    },
    {
      key: 2,
      timeSchedule: "8am - 10am + 1pm - 3pm",
      basic: "0 min",
      optimal: "240 min",
      premium: "0 min",
      custom: "200 min",
    },
    {
      key: 3,
      timeSchedule: "8am - 5pm (1hr break)",
      basic: "0 min",
      optimal: "0 min",
      premium: "480 min",
      custom: "200 min",
    },
  ]);
  const [dataTotalsSummary, setDataTotalsSummary] = useState<DataType[]>([
    {
      key: 1,
      description: "Total time (min)",
      basic: 120,
      optimal: 240,
      premium: 480,
      custom: 300,
    },
    {
      key: 2,
      description: "Total time (hours)",
      basic: 2.0,
      optimal: 4.0,
      premium: 8.0,
      custom: 3,
    },
    {
      key: 3,
      description: "All assistants (hours)",
      basic: 2.0,
      optimal: 4.0,
      premium: 8.0,
      custom: 3,
    },
    {
      key: 4,
      description: "Days per year",
      basic: 260,
      optimal: 260,
      premium: 260,
      custom: 300,
    },
    {
      key: 5,
      description: "Hours per year",
      basic: 520,
      optimal: 520,
      premium: 520,
      custom: 400,
    },
  ]);

  const defaultColumnsWorkingPlan: (ColumnTypes[number] & {
    editable?: boolean;
    dataIndex: string;
  })[] = [
    {
      title: "Time schedule",
      dataIndex: "timeSchedule",
      key: "timeSchedule",
      width: "55%",
    },
    {
      title: "Basic",
      dataIndex: "basic",
      key: "basic",
      width: "15%",
      editable: true,
      className: activeOption === "basic" ? styles.activeBasic : "",
    },
    {
      title: "Optimal",
      dataIndex: "optimal",
      key: "optimal",
      width: "15%",
      editable: true,
      className: activeOption === "optimal" ? styles.activeOptimal : "",
    },
    {
      title: "Premium",
      dataIndex: "premium",
      key: "premium",
      width: "15%",
      editable: true,
      className: activeOption === "premium" ? styles.activePremium : "",
    },
    {
      title: "Custom",
      dataIndex: "custom",
      key: "custom",
      width: "15%",
      className: activeOption === "custom" ? styles.activeCustom : "",
    },
  ];

  const columnsWorkingPlan = defaultColumnsWorkingPlan.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        key: col.key,
        handleSave,
      }),
    };
  });

  const defaultColumnsTotalsSummary = [
    {
      title: "Time Description",
      dataIndex: "description",
      key: "description",
      width: "55%",
    },
    {
      title: "Basic",
      dataIndex: "basic",
      key: "basic",
      width: "15%",
      editable: true,
      className: activeOption === "basic" ? styles.activeBasic : "",
    },
    {
      title: "Optimal",
      dataIndex: "optimal",
      key: "optimal",
      width: "15%",
      editable: true,
      className: activeOption === "optimal" ? styles.activeOptimal : "",
    },
    {
      title: "Premium",
      dataIndex: "premium",
      key: "premium",
      width: "15%",
      editable: true,
      className: activeOption === "premium" ? styles.activePremium : "",
    },
    {
      title: "Custom",
      dataIndex: "custom",
      key: "custom",
      width: "15%",
      className: activeOption === "custom" ? styles.activeCustom : "",
    },
  ];

  const columnsTotalsSummary = defaultColumnsTotalsSummary.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const handleSave = (row: DataType) => {
    const newData = [...dataWorkingPlan];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataWorkingPlan(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const onDailyAssistantsNumberChange = (e: any) => {
    // console.log(e.target.value);
  };

  const onDetailsClick = () => {
    setShowDetailsDrawer(true);
  };

  const onClose = () => {
    setShowDetailsDrawer(false);
  };

  const onCardClick = (cardId: string) => {
    setActiveOption(cardId);
    setShowDetailsDrawer(true);
  };

  return (
    <>
      <div className={styles.mobileLayout}>
        <Tabs
          className={styles.tabContainer}
          tabBarStyle={{ marginBottom: "0px" }}
          defaultActiveKey="1"
          type="card"
          activeKey={activeTabKey}
          onChange={(key: string) => setActiveTabKey(key)}
          items={benchmark?.screens
            ?.find(
              (screen) => screen.title === ROUTES.benchmark.daytimeJanitor.name || screen.title === ROUTES.benchmark.operationalManagement.name
            )
            ?.dashboards?.map((dashboard: IDashboard, index: number) => ({
              label: dashboard.title,
              key: index.toString(),
              children: (
                <Dashboard pending={pending} dashboardData={dashboard} />
              ),
            }))}
        />
      </div>
      <div
        className={styles.desktopLayout}
        style={{
          backgroundColor: "#fff",
          margin: "10px",
          minHeight: "calc(100% - 20px)",
        }}
      >
        <Tabs
          className={styles.tabContainer}
          tabBarStyle={{ marginBottom: "0px" }}
          defaultActiveKey="1"
          type="card"
          activeKey={activeTabKey}
          onChange={(key: string) => setActiveTabKey(key)}
          items={benchmark?.screens
            ?.find(
              (screen) => screen.title === ROUTES.benchmark.daytimeJanitor.name || screen.title === ROUTES.benchmark.operationalManagement.name
            )
            ?.dashboards?.map((dashboard: IDashboard, index: number) => ({
              label: dashboard.title ? dashboard.title : "Daily Cleaning", //TODO make dynamic
              key: index.toString(),
              children: (
                <Dashboard pending={pending} dashboardData={dashboard} />
              ),
            }))}
        />
      </div>
    </>
  );
};

export default DaytimeJanitor;
