import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import { ConfigProvider, Layout } from "antd";
import { Content, Footer } from "antd/es/layout/layout";
import { ROUTES } from "./config/constants";
import Foot from "./modules/platform/layout/Foot";
import Dashboard from "./modules/platform/sections/dashboard/Dashboard";
import PlatformSidebarLayout from "./modules/platform/layout/sidebar/SidebarLayout";
import Import from "./modules/platform/sections/import/Import";
import Regions from "./modules/platform/sections/admin/regions/Regions";
import Login from "./modules/auth/Login";
import { useSelector } from "react-redux";
import Countries from "./modules/platform/sections/admin/countries/Countries";
import BuildingTypes from "./modules/platform/sections/admin/buildingtypes/BuildingTypes";
import RoomTypes from "./modules/platform/sections/admin/roomtypes/RoomTypes";
import ItemMasterlist from "./modules/platform/sections/admin/itemmasterlist/ItemMasterlist";
import Buildings from "./modules/platform/sections/management/Buildings";
import Frequencies from "./modules/platform/sections/admin/frequencies/Frequencies";
import CleaningTasks from "./modules/platform/sections/admin/cleaningtasks/CleaningTasks";
import FloorMapping from "./modules/benchmark/sections/floor-mapping/FloorMapping";
import RoboticVacuuming from "./modules/benchmark/sections/robotic-vacuuming/RoboticVacuuming";
import PeriodicCleaning from "./modules/benchmark/sections/periodic-cleaning/PeriodicCleaning";
import DaytimeJanitor from "./modules/benchmark/sections/daytime-janitor/DaytimeJanitor";
import EveningCleaning from "./modules/benchmark/sections/evening-cleaning/EveningCleaning";
import BenchmarkResults from "./modules/benchmark/sections/benchmark-results/BenchmarkResults";
import ExecutiveSummaries from "./modules/benchmark/sections/executive-summary/ExecutiveSummary";
import Welcome from "./modules/benchmark/sections/welcome/Welcome";
import Verticals from "./modules/platform/sections/admin/verticals/Verticals";
import ImportSuccess from "./modules/platform/sections/import/ImportSuccess";
import "./styles/antd-override.scss";
import ProtectedRoute from "./modules/auth/ProtectedRoute";
import Benchmark from "./modules/benchmark/Benchmark";

function App() {
  const { profile } = useSelector((state: any) => ({
    profile: state.profile.profile,
  }));

  return (
    <Router>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#313ab8",
            colorPrimaryBg: "#141421",
            colorError: "#f30531",
          },
        }}
      >
        {!profile && (
          <Routes>
            <Route
              path="*"
              element={<Navigate replace to={ROUTES.general.login.route} />}
            />
            <Route path={ROUTES.general.login.route} element={<Login />} />
          </Routes>
        )}
        {profile && !window.location.pathname.includes("benchmark/") && (
          <Layout id="platform-layout" style={{ minHeight: "100vh" }}>
            <PlatformSidebarLayout />
            <Layout
              className="main-layout site-drawer-render-in-current-wrapper"
              style={{ height: "100vh" }}
            >
              <Content className="main-layout-content">
                <Routes>
                  <Route
                    path={ROUTES.general.main.route}
                    element={<Dashboard />}
                  />
                  <Route
                    path={ROUTES.general.import.route}
                    element={<Import />}
                  />
                  <Route
                    path={ROUTES.general.importSuccess.route}
                    element={<ImportSuccess />}
                  />
                  <Route
                    path={`${ROUTES.general.adminRegions.route}/:id?`}
                    element={<Regions />}
                  />
                  <Route
                    path={`${ROUTES.general.adminCountries.route}/:id?`}
                    element={<Countries />}
                  />
                  <Route
                    path={`${ROUTES.general.adminBuildingTypes.route}/:id?`}
                    element={<BuildingTypes />}
                  />
                  <Route
                    path={`${ROUTES.general.adminRoomTypes.route}/:id?`}
                    element={<RoomTypes />}
                  />
                  <Route
                    path={`${ROUTES.general.adminItemMasterlist.route}/:id?`}
                    element={<ItemMasterlist />}
                  />
                  <Route
                    path={`${ROUTES.general.managementBuildings.route}/:buildingId?`}
                    element={<Buildings />}
                  />
                  <Route
                    path={`${ROUTES.general.adminFrequencies.route}/:id?`}
                    element={<Frequencies />}
                  />
                  <Route
                    path={`${ROUTES.general.adminCleaningTasks.route}/:id?`}
                    element={<CleaningTasks />}
                  />
                  <Route
                    path={`${ROUTES.general.adminVerticals.route}/:id?`}
                    element={<Verticals />}
                  />
                  <Route
                    path={ROUTES.general.login.route}
                    element={
                      <Navigate replace to={ROUTES.general.main.route} />
                    }
                  />
                  <Route
                    path={ROUTES.benchmark.welcome.route}
                    element={<Welcome />}
                  />
                </Routes>
              </Content>
              <Footer
                style={{
                  background: "#fff",
                  height: "48px",
                  padding: "14px 20px",
                }}
                className="main-layout-footer"
              >
                <Foot />
              </Footer>
            </Layout>
          </Layout>
        )}
        <Routes>
          <Route element={<ProtectedRoute profile={profile} />}>
            <Route path="/benchmark" element={<Benchmark />}>
              {/* <Route
                    path={ROUTES.benchmark.homepage.route}
                    element={<Homepage />}
                  /> */}
              <Route
                path={`${ROUTES.benchmark.welcome.baseRoute}/${ROUTES.benchmark.welcome.route}`}
                element={<Welcome />}
              />
              <Route
                path={`${ROUTES.benchmark.executiveSummary.baseRoute}/${ROUTES.benchmark.executiveSummary.route}`}
                element={<ExecutiveSummaries />}
              />
              {/* <Route
                    path={ROUTES.benchmark.scanningBenefits.route}
                    element={<ScanningBenefits />}
                  /> */}
              <Route
                path={`${ROUTES.benchmark.benchmarkResults.baseRoute}/${ROUTES.benchmark.benchmarkResults.route}`}
                element={<BenchmarkResults />}
              />
              <Route
                path={`${ROUTES.benchmark.eveningCleaning.baseRoute}/${ROUTES.benchmark.eveningCleaning.route}`}
                element={<EveningCleaning />}
              />
              <Route
                path={`${ROUTES.benchmark.daytimeJanitor.baseRoute}/${ROUTES.benchmark.daytimeJanitor.route}`}
                element={<DaytimeJanitor />}
              />
              <Route
                  path={`${ROUTES.benchmark.operationalManagement.baseRoute}/${ROUTES.benchmark.operationalManagement.route}`}
                  element={<DaytimeJanitor />}
              />
              <Route
                path={`${ROUTES.benchmark.periodicCleaning.baseRoute}/${ROUTES.benchmark.periodicCleaning.route}`}
                element={<PeriodicCleaning />}
              />
              <Route
                path={`${ROUTES.benchmark.roboticVacuuming.baseRoute}/${ROUTES.benchmark.roboticVacuuming.route}`}
                element={<RoboticVacuuming />}
              />
              <Route
                path={`${ROUTES.benchmark.floorMapping.baseRoute}/${ROUTES.benchmark.floorMapping.route}`}
                element={<FloorMapping />}
              />
            </Route>
          </Route>
        </Routes>
      </ConfigProvider>
    </Router>
  );
}

export default App;
